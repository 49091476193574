<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useUserSession } from '/@src/stores'

const props = defineProps({
  buttonWidthClass: {
    type: String,
    default: '',
    required: false,
  },
})

// Composable
const userSession = useUserSession()
const router = useRouter()
const route = useRoute()

// Reactive data
const isFreeLimitation = ref()

// Computed
const isWidgetShown = computed(() => {
  return (
    userSession?.usage?.widgets &&
    userSession?.usage?.widgets?.consumed >= 1 &&
    userSession?.usage?.messages?.consumed &&
    userSession?.usage?.messages?.consumed >= 1 &&
    ['chatbots', 'chats-:uid'].includes(String(route?.name)) &&
    isFreeLimitation.value
  )
})

// Lifecycle hooks
onBeforeMount(() => {
  isFreeLimitation.value = userSession.getPlanLimitations('is_free')
})

// Functions
const redirect = async () => {
  await router.push({
    path: `/subscriptions`,
  })
}
</script>

<template>
  <VCard v-if="isWidgetShown" class="mb-3">
    <div class="columns is-multiline is-flex is-align-self-center has-text-centered">
      <div class="column is-12 pb-0">
        <img src="https://cdn.insertchat.com/illustrations/widgets/3.svg" alt="" width="100" />
      </div>

      <div class="column is-12 pb-0 mb-3">
        Unlock the full potential of the app by upgrading to our premium package and enjoy exclusive
        features and a wider range of benefits!
      </div>

      <div class="column is-12">
        <VButton :class="props.buttonWidthClass" color="primary" @click="redirect">
          Discover our plans 🚀
        </VButton>
      </div>
    </div>
  </VCard>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Const data
const formId = 'formResetPassword'

// Reactive data
const formData: any = ref({})
const formState: any = reactive({})
const isLoading = ref(false)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const isAffiliate = computed(() => !ChatbotHelper.allowedDomains.includes(window.location.origin))

// Composable
const router = useRouter()
const route = useRoute()
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
const queryToken = route.query.token as string
useHead({
  title: t('page.auth.reset_password.title'),
})

// Lifecycle hooks
onMounted(() => {
  // @ts-ignore
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const handlePasswordIconClick = (node: any) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { new_password, email } = fields

    if (queryToken) {
      EventHelper.push('changed_password', { queryToken })

      const { token } = await HttpHelper.post(
        `/auth/change-password/${queryToken}`,
        null,
        { new_password },
        {
          withoutApp: true,
        }
      )

      notyf.success(t('notifications.success'))

      userSession?.setToken(token)

      await router.push({
        path: '/chatbots',
      })
    } else {
      EventHelper.push('reseted_password')

      await HttpHelper.post(
        '/auth/reset-password',
        null,
        { email },
        {
          withoutApp: true,
        }
      )

      notyf.success(t('notifications.password_reset_email_success'))
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="auth-wrapper-inner columns is-gapless has-text-centered">
    <div class="column is-12">
      <div class="hero is-fullheight is-white">
        <div class="hero-heading">
          <!--
					<label
						class="dark-mode ml-auto"
						tabindex="0"
						@keydown.space.prevent="(e) => (e.target as HTMLLabelElement).click()"
					>
						<input
							type="checkbox"
							:checked="!darkmode.isDark"
							@change="darkmode.onChange"
						/>
						<span> </span>
					 </label>
          -->
          <div v-if="!isAffiliate" class="auth-logo">
            <RouterLink :to="{ path: '/auth/reset-password' }">
              <AnimatedLogo :text="true" />
            </RouterLink>
          </div>
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="auth-content">
                  <h2>{{ t('page.auth.reset_password.title') }}</h2>
                  <p></p>

                  <RouterLink
                    v-if="!isAffiliate"
                    class="is-block mt-4"
                    :to="{ path: '/auth/access' }"
                  >
                    Register an account?
                  </RouterLink>
                </div>
                <div class="auth-form-wrapper">
                  <!-- Register Form -->
                  <FormKit
                    :id="formId"
                    v-model="formData"
                    type="form"
                    :disabled="isLoading"
                    :actions="false"
                    @submit="onSubmit"
                  >
                    <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

                    <FormKit
                      v-if="queryToken"
                      id="new-password"
                      name="new_password"
                      type="password"
                      validation="required:trim"
                      autocomplete="new-password"
                      label="New password"
                      suffix-icon="eyeClosed"
                      suffix-icon-class="hover:text-blue-500"
                      @suffix-icon-click="handlePasswordIconClick"
                    />

                    <FormKit
                      v-else
                      type="email"
                      name="email"
                      validation="required:trim|email"
                      :label="t('page.auth.reset_password.form.inputs.email.label')"
                    />

                    <VButton
                      :disabled="isFormDisabled"
                      :loading="isLoading"
                      type="submit"
                      color="primary"
                      class="w-100"
                      icon="ic:round-check"
                      @click="triggerSubmit"
                    >
                      Reset password
                    </VButton>
                  </FormKit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

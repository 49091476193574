<script setup lang="ts">
import { loadStripe } from '@stripe/stripe-js/pure'
import { onMounted, ref } from 'vue'
import { EventHelper, HttpHelper } from '/@src/helpers'

const emit = defineEmits(['update:modalProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { plan, period, quantity, open } = props.modalProps

// Reactive data
const isLoading = ref(false)
const checkout = ref()

// Const data
const MODE = import.meta.env.MODE
const STRIPE_PUBLIC_KEY =
  MODE === 'production'
    ? import.meta.env.VITE_STRIPE_PRODUCTION_PUBLIC_KEY
    : import.meta.env.VITE_STRIPE_LOCAL_PUBLIC_KEY

// Lifecycle hooks
onMounted(async () => {
  try {
    if (!isLoading.value) {
      const planId = plan?.[period]?.default_plan_id

      isLoading.value = true

      const { sessionId, clientSecret } = await HttpHelper.get(
        `/subscriptions/create-checkout-session/${planId}/${quantity}`
      )

      if (!sessionId || !clientSecret) {
        throw new Error('No session ID or client secret, contact the support!')
      }

      EventHelper.push('payment_initiated')

      const stripe = await loadStripe(STRIPE_PUBLIC_KEY)
      checkout.value = await stripe?.initEmbeddedCheckout({
        clientSecret,
        onComplete: async () => {
          const { status, session } = await HttpHelper.get(
            `/subscriptions/get-session/${sessionId}`
          )

          if (status && status === 'complete') {
            try {
              EventHelper.push('payment_finalized', {
                email: session?.customer_details?.email,
                phone_number: session?.customer_details?.phone,
                address: session?.customer_details?.address,
                currency: session?.currency,
                session_id: sessionId,
                payment_finalized_session_id: sessionId,
                payment_finalized_value: session?.amount_subtotal / 100,
                value: session?.amount_subtotal / 100,
              })
            } catch (error) {
              EventHelper.push('payment_finalized')
            }
          }

          closeModal(true)
        },
      })

      checkout.value.mount('#checkout')
    }
  } catch (error) {
    console.error(error)

    try {
      checkout.value.destroy('#checkout')
      checkout.value = null
    } catch (error) {}

    closeModal()
  } finally {
    isLoading.value = false
  }
})

// Functions
const closeModal = (refresh = false) => {
  try {
    checkout.value.destroy('#checkout')
    checkout.value = null
  } catch (error) {}

  emit('update:modalProps', { open: false, sessionId: null, clientSecret: null })
  emit('close', refresh)
}
</script>

<template>
  <CustomModal
    size="stripe"
    :open="open"
    title="Don’t let limits hold your business back!"
    :override-modal="false"
    :noscroll="false"
    :noclose="false"
    :disable-footer="true"
    :narrow="true"
    @close="closeModal"
  >
    <VLoader v-if="isLoading" />

    <div id="checkout"></div>
  </CustomModal>
</template>

<style lang="scss"></style>

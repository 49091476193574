<script setup lang="ts">
const emit = defineEmits<{
  (e: 'delete', value: object): void
  (e: 'insert', value: object): void
  (e: 'refresh', value: object): void
}>()

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  isVertical: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableInsert: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableDelete: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableRefresh: {
    type: Boolean,
    required: false,
    default: true,
  },
  deleteConfirmation: {
    type: Boolean,
    required: false,
    default: true,
  },
})

// Functions
const onDelete = () => {
  if (!props.deleteConfirmation) {
    emit('delete', {})
    return
  }

  const result = confirm('Are you sure you want to delete this item?')

  if (result) {
    emit('delete', {})
  }
}

const onInsert = () => {
  emit('insert', {})
}

const onRefresh = () => {
  emit('refresh', {})
}
</script>

<template>
  <div class="is-flex h-100 w-100">
    <div
      v-if="props.isLoading"
      class="formkit-controls w-100"
      :class="{ vertical: props.isVertical }"
    >
      <div class="mx-auto loader is-loading"></div>
    </div>
    <ul
      v-else
      class="formkit-controls w-100"
      :class="{ 'vertical': props.isVertical, 'p-0': props.isVertical }"
      @click="
        !props.disableDelete && props.disableInsert && props.disableRefresh
          ? onDelete()
          : !props.disableInsert && props.disableDelete && props.disableRefresh
            ? onInsert()
            : !props.disableRefresh && props.disableDelete && props.disableInsert
              ? onRefresh()
              : null
      "
    >
      <li v-if="!props.disableDelete" class="formkit-remove" @click.stop="onDelete">
        <button class="formkit-remove-control" type="button">
          <span class="formkit-control-label"> Delete </span>
          <span class="formkit-remove-icon formkit-icon">
            <img
              src="https://cdn.insertchat.com/icons/formkit-remove.svg"
              alt=""
              :style="{
                height: '1.5em',
                width: '1.5em',
              }"
            />
          </span>
        </button>
      </li>
      <li v-if="!props.disableInsert" class="formkit-insert" @click.stop="onInsert">
        <button class="formkit-insert-control" type="button">
          <span class="formkit-control-label"> Add </span>
          <span class="formkit-add-icon formkit-icon">
            <img
              src="https://cdn.insertchat.com/icons/formkit-insert.svg"
              alt=""
              :style="{
                height: '1.5em',
                width: '1.5em',
              }"
            />
          </span>
        </button>
      </li>

      <li v-if="!props.disableRefresh" class="formkit-insert" @click.stop="onRefresh">
        <button class="formkit-insert-control" type="button">
          <span class="formkit-control-label"> Refresh </span>
          <span class="formkit-add-icon formkit-icon">
            <VIcon icon="ic:round-refresh" color="#000000" />
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.formkit-controls {
  align-self: center;
  height: 77px;
  width: 31.5px;
  border: 1px solid var(--border);
  cursor: pointer;
}

.vertical {
  display: flex;
  align-self: center;
  flex-direction: unset;
  height: 31.5px;
  width: 77px;

  .formkit-remove,
  .formkit-insert {
    margin: 0 0.5em;
  }
}
</style>

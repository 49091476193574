<script setup lang="ts">
import axios from 'axios'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { StringHelper, ViaPlaceholderHelper } from '/@src/helpers'
import { ISchema } from '/@src/interfaces'
import { useWidget } from '/@src/stores'

const props = defineProps({
  urls: {
    type: Array as () => string[],
    default: () => [],
    required: true,
  },
})

// Composable
const widgetStore = useWidget()
const isLoading = ref(false)
const schemas = ref<ISchema[]>([])

// Reactive data
const windowStylingSettings: any = ref({})
const assistantMessageStylingSettings: any = ref({})
const smartSuggestionsSettings: any = ref({})
const assistantAvatarSettings: any = ref({})

// Lifecycle hooks
onBeforeMount(() => {
  windowStylingSettings.value = widgetStore.getWindowStylingSettings()
  assistantMessageStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()
  smartSuggestionsSettings.value = widgetStore.getSmartSuggestionsAgent()
  assistantAvatarSettings.value = widgetStore.getAssistantAvatarSettings()
})

onMounted(async () => {
  try {
    if (!props.urls) {
      return
    }

    isLoading.value = true

    const responses = await Promise.all(
      props.urls.slice(0, 3).map(async (url) => {
        if (!url) {
          return null
        }

        const encodedUrl = `https://cards.insertchat.com:8050/iframely?url=${encodeURIComponent(url)}&iframe=1&omit_script=1`

        try {
          const response = await axios.get(encodedUrl)
          return response?.data || null
        } catch (error) {
          console.error(`Error fetching data for URL: ${url}`, error)
          return null
        }
      })
    ).then((results) =>
      results.filter((result) => result !== null && (result?.meta?.site || result?.meta?.brand))
    )

    schemas.value = responses.slice(0, 3)

    if (window?.iframely && typeof window?.iframely?.load === 'function') {
      window?.iframely?.load()
    }
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
})

// Computed
const computedLogo = computed(() => {
  return StringHelper.getImageUrl(assistantAvatarSettings.value?.url)
})

const computedInnerMsgCss = computed(() => {
  return {
    ...widgetStore.getDynamicStylesByObject(assistantMessageStylingSettings.value),
    ...widgetStore.getDynamicStylesByObject(windowStylingSettings.value, ['font', 'size']),
  }
})

const computedMessageWidth = computed(() => {
  return { width: assistantMessageStylingSettings.value?.width }
})
</script>

<template>
  <div
    v-if="props.urls && Object.keys(props.urls).length && schemas && Object.keys(schemas).length"
    :class="[
      'columns',
      'is-multiline',
      'is-mobile',
      'is-flex',
      'is-vcentered',
      'p-0',
      'is-flex-direction-row',
    ]"
  >
    <div v-if="assistantAvatarSettings?.status" class="column is-narrow p-0 pr-3">
      <img
        class="avatar"
        :src="computedLogo"
        draggable="false"
        alt="Avatar"
        @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '40x40')"
      />
    </div>

    <div
      :class="['column', 'msg-inner', 'assitant']"
      :style="{ ...computedInnerMsgCss, ...computedMessageWidth }"
    >
      <div class="columns is-multiline">
        <VLoader v-if="isLoading"></VLoader>

        <div
          v-for="(schema, index) of schemas"
          :key="index"
          :class="['column', 'is-4', 'is-12-mobile']"
        >
          <Iframely :schema="schema" />
        </div>
      </div>
    </div>
  </div>
</template>

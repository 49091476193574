<script setup lang="ts">
import { computed, PropType } from 'vue'
import { HttpHelper } from '/@src/helpers'
import { IPlan } from '/@src/interfaces'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['subscribe', 'cancel'])

const props = defineProps({
  plan: {
    type: Object as PropType<IPlan>,
    required: true,
  },
  period: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    required: true,
  },
})

// Const data
const bgColor = props.mode === 'business' ? '#00DA8F' : '#F82A5E'
const textColor = props.mode === 'business' ? '#0e111b' : '#fff'

// Composable
const userSession = useUserSession()

// Computed
const planByPeriod = computed(() => {
  // @ts-ignore
  return props.plan[props.period]
})

const currentPlanId = computed(() => {
  return userSession?.subscription?.plan_id
})

const computedBackgroundColor = computed(() => {
  if (planByPeriod.value?.plans.includes(currentPlanId.value)) {
    return '#f6f6f7'
  }

  return !props.plan?.limitations?.is_free ? props.plan?.background : 'var(--body-color)'
})

// Function
const onSubscribe = async (params: { plan: IPlan; period: string; quantity: number }) => {
  emit('subscribe', params)
}

const onCancel = async (params: { plan: IPlan; period: string; quantity: number }) => {
  emit('cancel', params)
}

const onManage = async () => {
  const { url } = await HttpHelper.get(`/subscriptions/create-portal-session`, {
    withoutApp: false,
  })

  url && window.open(url, '_blank')

  return
}
</script>

<template>
  <VCard
    :style="{
      'background-color': computedBackgroundColor,
    }"
    class="is-fullheight is-flex is-flex-direction-column"
  >
    <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
      <div class="columns is-multiline pricing-table">
        <!-- Title -->
        <div class="column is-12 mt-3 has-text-centered">
          <h1 class="title is-4">{{ props.plan?.title }}</h1>
        </div>

        <!-- Description -->
        <div class="column is-12 mb-3 p-0 has-text-centered">
          <h1 class="subtitle">{{ props.plan?.subtitle }}</h1>
        </div>

        <!-- Price -->
        <div class="column is-12 mb-3 has-text-centered">
          <div class="subtitle is-3 mb-3 is-narrow is-bolder">
            <span class="price">
              <span class="whole">${{ planByPeriod?.price?.split('.')?.[0] }}</span>
              <span class="decimal">{{ planByPeriod?.price?.split('.')?.[1] }}</span>
            </span>
          </div>
          <div>{{ props.period === 'monthly' ? 'Monthly' : 'Yearly' }}</div>
        </div>

        <!-- Features -->
        <div class="column is-flex is-justify-content-center is-12 mb-3">
          <ul>
            <VBlock
              v-for="(feature, index) of props.plan?.features"
              :key="index"
              class="mb-1 is-capitalize"
              :title="feature.title"
              lighter
              center
            >
              <template #icon>
                <VIcon
                  :is-iconify="true"
                  :icon="feature.icon ?? 'ic:round-check'"
                  :color="feature.color ?? bgColor"
                />
              </template>
            </VBlock>
          </ul>
        </div>
      </div>

      <div
        v-if="planByPeriod?.default_plan_id && !props.plan?.limitations?.is_free"
        class="is-12 has-text-centered mb-3"
      >
        <div v-if="planByPeriod?.plans.includes(currentPlanId)" class="columns mb-0">
          <div class="column is-6">
            <VButton class="w-100" color="success" @click="onManage">
              <small class="rem-100 is-capitalize is-weight-500"> Manage Plan </small>
            </VButton>
          </div>

          <div class="column is-6">
            <VButton
              :style="{
                backgroundColor: `${bgColor} !important`,
                color: `${textColor} !important`,
              }"
              :disabled="props.plan?.limitations?.is_free"
              class="w-100"
              color="success"
              @click="onCancel({ plan, period, quantity: 1 })"
            >
              <small class="rem-100 is-capitalize is-weight-500"> Cancel Plan </small>
            </VButton>
          </div>
        </div>

        <VButton
          v-else
          :disabled="props.plan?.limitations?.is_free"
          color="success"
          class="w-100 mb-3"
          :style="{
            backgroundColor: `${bgColor} !important`,
            color: `${textColor} !important`,
          }"
          @click="onSubscribe({ plan, period, quantity: 1 })"
        >
          <small class="rem-100 is-capitalize is-weight-500"> Subscribe plan </small>
        </VButton>

        <!--
        <VButton
          v-if="props.period === 'monthly'"
          :disabled="props.plan?.limitations?.is_free"
          color="primary"

          class="w-100 mb-3"
          @click="onSubscribe({ plan, period: 'semiyearly', quantity: 1 })"
          >
          <small class="rem-100 is-capitalize is-weight-500">
            Subscribe For 6 Months (1 month free)
          </small>
        </VButton>
        -->

        <VButton
          v-if="props.period === 'monthly'"
          :disabled="props.plan?.limitations?.is_free"
          color="primary"
          class="w-100 mb-3"
          @click="onSubscribe({ plan, period: 'yearly', quantity: 1 })"
        >
          <small class="rem-100 is-capitalize is-weight-500">
            Subscribe For 1 Year (25% OFF)
          </small>
        </VButton>

        <!-- Footer -->
        <small class="rem-95 is-capitalize is-weight-500"> Cancel anytime - No hidden fees</small>
      </div>
    </div>
  </VCard>
</template>

<style lang="scss">
.tab-content {
  padding: 20px 0 !important;
}

.pricing-table {
  .price .decimal {
    font-size: 8px;
    vertical-align: super;
  }
}
</style>

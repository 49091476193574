<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, Reactive, ref, Ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { EventHelper, HttpHelper } from '/@src/helpers'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
  question: { type: String, default: '', required: true },
  answer: { type: String, default: '', required: true },
})

const { widgetUid, trainingType, provider, question, answer } = toRefs(props)

// Const data
const formId = 'trainingQuizzesForm'

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<any> = ref([])
const isLoading = ref(false)
const training: Ref<any> = ref({
  label: '',
})
const loaderProgress = ref(-1)
const loaderText = ref('')

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true

    if (provider.value && provider.value?.uid) {
      training.value = {
        label: provider.value?.label,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit()

  if (question?.value || answer?.value) {
    training.value.label = question.value
    newMaterial.value = [{ questions: [{ question: question.value }], text: answer.value }]
  }
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const onInit = () => {
  newMaterial.value = [{ questions: [{ question: '' }], text: '' }]
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async () => {
  if (isLoading.value) {
    return
  }

  const data = newMaterial.value
    ?.filter((q: any) => q?.questions && q?.questions?.length && q?.text)
    ?.map(({ questions, text }: any) => ({
      questions: questions.map((q: any) => ({ question: q.question.trim() })),
      text: text.trim(),
    }))

  if (!data || (data && !data.length)) {
    isLoading.value = false

    return
  }

  isLoading.value = true
  loaderProgress.value = 0

  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    if (provider.value?.uid && Object.keys(newMaterial?.value)?.length) {
      await HttpHelper.batch(
        '/providers/synch',
        provider.value?.uid,
        { training: data },
        {
          batchSize: 50,
          batchKey: '',
        },
        (numBatches: number, i: number) => {
          loaderText.value = `Processing batch ${i + 1}/${numBatches}`
          loaderProgress.value = Math.round((i * 100) / numBatches)
        }
      )

      emit('synch')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Training #1"
      validation-visibility="live"
    />

    <CustomForm title="Add Knowledge" subtitle="" class="mb-4">
      <template #body>
        <FormKit
          v-slot="{ value, index: indexQA }"
          v-model="newMaterial"
          type="repeater"
          :preserve="false"
          :disabled="isLoading"
          :up-control="false"
          :down-control="true"
          :add-button="true"
          :insert-control="false"
          min="1"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <h5 class="title is-6 mt-2 mb-2">Q&A {{ Number(indexQA) + 1 }}</h5>
              <hr class="m-0" />
            </div>

            <div class="column is-12 pb-0">
              <FormKit
                v-slot="{ index: indexQ }"
                name="questions"
                type="repeater"
                :preserve="false"
                :disabled="isLoading"
                :up-control="false"
                :down-control="true"
                :add-button="false"
                :insert-control="true"
                :remove-control="true"
                min="1"
              >
                <FormKit
                  name="question"
                  type="text"
                  :label="`Question - Variance #${Number(indexQ) + 1}`"
                  validation="trim"
                  placeholder="Question variance"
                />
              </FormKit>
            </div>
            <div class="column is-12 pb-0">
              <FormKit
                name="text"
                type="textarea"
                label="Answer to all the question variances"
                validation="length:0,10000|trim"
                :help="`${value?.text ? value?.text?.length : 0} / 10000`"
                :validation-messages="{
                  length: 'Your text cannot be more than 10000 characters.',
                }"
                placeholder="Paste your text here !"
                rows="1"
              />
            </div>
          </div>
        </FormKit>

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Train
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>

import { Ref, ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useScroll = defineStore('scroll', () => {
  const isScrolledToTop = ref(false)
  const isScrolledToBottom = ref(false)
  const observer: Ref<IntersectionObserver | undefined> = ref()
  const intersectionRatio = ref(0)
  const allowBottomScroll = ref(true)

  // Setters
  function setIsScrolledToTop(data: boolean) {
    isScrolledToTop.value = data
  }

  function setIsScrolledToBottom(data: boolean) {
    isScrolledToBottom.value = data
  }

  function setAllowBottomScroll(data: boolean) {
    allowBottomScroll.value = data
  }

  function setupObserver() {
    const selector = document.querySelector('.bottom-marker')

    if (!selector) {
      return
    }

    observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          intersectionRatio.value = entry.intersectionRatio

          if (entry.intersectionRatio > 0) {
            setIsScrolledToBottom(entry.intersectionRatio >= 1)
          }
        })
      },
      {
        root: null, // Observe the intersections relative to the viewport
        threshold: 0.1,
      }
    )

    observer.value.observe(selector)
  }

  return {
    isScrolledToTop,
    isScrolledToBottom,
    observer,
    intersectionRatio,
    allowBottomScroll,
    setAllowBottomScroll,
    setIsScrolledToTop,
    setIsScrolledToBottom,
    setupObserver,
  } as const
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useScroll, import.meta.hot))
}

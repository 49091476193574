<script setup lang="ts">
import { computed } from 'vue'

export type VCardRadius = 'regular' | 'smooth' | 'rounded'
export type VCardColor = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger'
export interface VCardProps {
  title?: string
  radius?: VCardRadius
  color?: VCardColor
  elevated?: boolean
  height?: string
  minHeight?: string
  maxHeight?: string
}

const props = withDefaults(defineProps<VCardProps>(), {
  title: '',
  radius: 'smooth',
  color: undefined,
  elevated: true,
  height: 'auto',
  minHeight: '',
  maxHeight: '',
})

const cardRadius = computed(() => {
  if (props.radius === 'smooth') {
    return 's-card'
  } else if (props.radius === 'rounded') {
    return 'l-card'
  }

  return 'r-card'
})
</script>

<template>
  <div
    :style="{ height: props.height, minHeight: props.minHeight, maxHeight: props.maxHeight }"
    :class="[
      'vcard-container',
      cardRadius,
      elevated && 'is-raised',
      props.color && `is-${props.color}`,
    ]"
  >
    <h1 v-if="props.title" class="title is-5">{{ props.title }}</h1>

    <slot></slot>
  </div>
</template>

<style lang="scss">
.vcard-container {
  // min-height: 650px;
  min-width: 225px;
  display: flex;
  flex-direction: column;
}
</style>

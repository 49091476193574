<script setup lang="ts">
import { StripeHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Composable
const userSession = useUserSession()
</script>

<template>
  <div class="column p-0">
    <RouterLink :to="{ path: '/subscriptions' }">
      <VTag
        v-if="StripeHelper.isSubscriptionActive(userSession?.subscription) && userSession?.plan"
        color="info"
      >
        {{ `${userSession?.plan?.title}` }}
      </VTag>
      <VTag v-else color="danger">
        {{ 'No subscription' }}
      </VTag>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { StringHelper, ViaPlaceholderHelper } from '/@src/helpers'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Reactive data
const windowStylingSettings: any = ref({})
const assistantMessageStylingSettings: any = ref({})
const smartSuggestionsSettings: any = ref({})
const assistantAvatarSettings: any = ref({})

// Lifecycle hooks
onBeforeMount(() => {
  windowStylingSettings.value = widgetStore.getWindowStylingSettings()
  assistantMessageStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()
  smartSuggestionsSettings.value = widgetStore.getSmartSuggestionsAgent()
  assistantAvatarSettings.value = widgetStore.getAssistantAvatarSettings()
})

// Computed
const computedLogo = computed(() => {
  return StringHelper.getImageUrl(assistantAvatarSettings.value?.url)
})

const computedInnerMsgCss = computed(() => {
  return {
    ...widgetStore.getDynamicStylesByObject(assistantMessageStylingSettings.value),
    ...widgetStore.getDynamicStylesByObject(windowStylingSettings.value, ['font', 'size']),
  }
})

const computedMessageWidth = computed(() => {
  return { width: assistantMessageStylingSettings.value?.width }
})

const computedSuggestions = computed(() => {
  if (widgetStore.state.suggestions && Object.keys(widgetStore.state.suggestions).length) {
    return widgetStore.state?.suggestions
      ?.filter(
        (suggestion) =>
          (suggestion?.text || suggestion?.message) &&
          (suggestion?.text !== '' || suggestion?.message !== '')
      )
      ?.map((suggestion) => suggestion?.text || suggestion?.message)
  }

  return []
})

const onSuggestionClick = async (message: string) => {
  widgetStore.setWidgetState('overrideContent', '')

  await widgetStore.createMessage({
    role: 'user',
    input: message ?? '',
    showTypingIndicator: true,
  })
}
</script>

<template>
  <div
    v-if="computedSuggestions && Object.keys(computedSuggestions).length"
    :class="[
      'columns',
      'is-multiline',
      'is-mobile',
      'is-flex',
      'is-vcentered',
      'p-0',
      'is-flex-direction-row',
    ]"
  >
    <div v-if="assistantAvatarSettings?.status" class="column is-narrow p-0 pr-3">
      <img
        class="avatar"
        :src="computedLogo"
        draggable="false"
        alt="Avatar"
        @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '40x40')"
      />
    </div>

    <div
      :class="['column', 'msg-inner', 'assitant']"
      :style="{ ...computedInnerMsgCss, ...computedMessageWidth }"
    >
      <div class="columns is-multiline">
        <div
          class="suggestion-item"
          v-for="(message, index) of computedSuggestions"
          :key="index"
          :class="['column', 'is-4', 'is-12-mobile', 'pr-0', { 'pl-0': index === 0 }]"
        >
          <VButton
            class="w-100 h-100"
            :style="{ ...computedInnerMsgCss, border: `1px solid ${computedInnerMsgCss?.color}` }"
            @click="onSuggestionClick(message)"
          >
            <p
              :style="{
                color: computedInnerMsgCss?.color,
                fontSize: computedInnerMsgCss?.fontSize,
              }"
            >
              {{ StringHelper.capitalizeFirstLetter(message) }}
            </p>
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>

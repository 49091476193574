<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import VueTurnstile from 'vue-turnstile'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, CookiesHelper, EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Composable
const router = useRouter()
const route = useRoute()
const notyf = useNotyf()
const userSession = useUserSession()
useHead({
  title: 'Welcome to InsertChat',
})

// Const data
const formId = 'formAccess'
const TURNSTILE_SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY
const IS_PRODUCTION = import.meta.env.MODE === 'production'
const { email, password, code: appSumoCode, token, referrer_email } = route.query

// Reactive data
const formData: any = ref({})
const formState: any = reactive({})
const isLoading = ref(false)
const isConfirmed = ref(true)
const turnstileToken = ref('')

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const isAffiliate = computed(() => !ChatbotHelper.allowedDomains.includes(window.location.origin))

// Lifecycle hooks
onBeforeMount(() => {
  if (email) {
    formData.value.email = email
  }

  if (referrer_email) {
    formData.value.referrer_email = referrer_email
  }

  if (password) {
    formData.value.password = password
  }
})

onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

  const _token = token?.toString() ?? ''

  if (_token) {
    try {
      isLoading.value = true

      const response = await handleTokenAuth(_token)

      await handleAuthResponse(response)

      isLoading.value = false
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
})

// Functions
const handlePasswordIconClick = (node: any) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const {
      first_name,
      email,
      password,
      credential,
      access_token,
      fb_access_token,
      referrer_email,
    } = fields

    let response
    const utm = handleUtm()
    const fullQueryString = handleFullQueryString()

    const provider = fb_access_token ? 'facebook' : credential || access_token ? 'google' : null
    const authToken = fb_access_token || credential || access_token

    if (provider) {
      response = await handleSocialAuth(provider, authToken, referrer_email, utm, fullQueryString)
    } else {
      response = await handleEmailAuth(
        first_name,
        email,
        password,
        referrer_email,
        utm,
        fullQueryString
      )
    }

    await handleAuthResponse(response)

    isLoading.value = false
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const handleUtm = () => {
  try {
    const appUtm = CookiesHelper.get('app_utm')

    return appUtm ? JSON.parse(decodeURIComponent(appUtm)) : {}
  } catch (error) {
    console.error('Error parsing app_utm cookie:', error)

    return {}
  }
}

const handleFullQueryString = () => {
  try {
    const appFullQueryString = CookiesHelper.get('app_full_qs')

    return appFullQueryString ? JSON.parse(decodeURIComponent(appFullQueryString)) : {}
  } catch (error) {
    console.error('Error parsing app_full_qs cookie:', error)

    return {}
  }
}

const handleTokenAuth = async (token: string) => {
  return await HttpHelper.get(`/auth/login-token/${token}`, { withoutApp: true })
}

const handleSocialAuth = async (
  provider: string,
  authToken: string,
  referrer_email: string,
  utm: object,
  fullQueryString: object
) => {
  const authPath = `/auth/social-access`
  const authData = {
    provider,
    referrer_email,
    token: authToken,
    utm,
    qs: fullQueryString,
    gclid: (utm as any)?.gclid,
  }

  return await HttpHelper.post(authPath, null, authData, { withoutApp: true })
}

const handleEmailAuth = async (
  first_name: string,
  email: string,
  password: string,
  referrer_email: string,
  utm: object,
  fullQueryString: object
) => {
  if (IS_PRODUCTION && !isAffiliate && !turnstileToken.value) {
    throw new Error('Please make sure the captcha is resolved correctly!')
  }

  return await HttpHelper.post(
    '/auth/access',
    null,
    {
      first_name,
      email,
      password,
      referrer_email,
      appsumo_code: appSumoCode,
      utm,
      qs: fullQueryString,
      gclid: (utm as any)?.gclid,
    },
    { withoutApp: true }
  )
}

const handleAuthResponse = async (response: any) => {
  const { user_gtm, token, is_admin, is_login } = response

  if (!user_gtm?.is_confirmed) {
    isConfirmed.value = false

    notyf.info('Please check your email and confirm your address to activate your account.')

    return
  } else {
    EventHelper.config({ user: user_gtm })

    userSession?.setToken(token)
    is_admin && userSession?.setIsAdmin(true)

    is_login ? EventHelper.push('login') : EventHelper.push('registered')

    await router.push({
      path: '/chatbots',
    })
  }
}

/*
const facebookLogin = () => {
  FB.login((response: any) => {
    if (response?.authResponse?.accessToken) {
      onSubmit({ fb_access_token: response?.authResponse?.accessToken })
    }
  })
}
*/
</script>

<template>
  <div class="auth-wrapper-inner columns is-gapless has-text-centered">
    <div class="column is-12">
      <div class="hero is-fullheight is-white">
        <div class="hero-heading">
          <div v-if="!isAffiliate" class="auth-logo">
            <RouterLink :to="{ path: '/auth/access' }">
              <AnimatedLogo :text="true" />
            </RouterLink>
          </div>
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="auth-content">
                  <h2>Welcome{{ appSumoCode ? ' Sumo-ling!' : '!' }}</h2>
                </div>

                <div class="auth-form-wrapper">
                  <VMessage color="info" v-if="appSumoCode">
                    Remember! You cannot activate your AppSumo license with an existing account,
                    even if it's on the free plan.
                    <br /><br />
                    The AppSumo deal is only for new accounts.
                  </VMessage>

                  <!-- Register Form -->
                  <FormKit
                    :id="formId"
                    v-model="formData"
                    type="form"
                    :disabled="isLoading"
                    :actions="false"
                    @submit="onSubmit"
                  >
                    <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

                    <VMessage v-if="!isConfirmed" color="info">
                      <div class="columns is-multiline is-mobile is-vcentered is-centered">
                        <div class="column is-12">
                          Please check your email and confirm your address to activate your account.
                          Thanks!
                        </div>

                        <div class="column is-3">
                          <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank">
                            <img
                              src="https://cdn.insertchat.com/public/providers/gmail.png"
                              alt="Gmail"
                              style="height: 30px"
                            />
                          </a>
                        </div>

                        <div class="column is-3">
                          <a href="https://outlook.live.com/mail/0/" target="_blank">
                            <img
                              src="https://cdn.insertchat.com/public/providers/outlook.png"
                              alt="Outlook"
                            />
                          </a>
                        </div>

                        <div class="column is-3">
                          <a href="https://mail.proton.me/u/0/inbox" target="_blank">
                            <img
                              src="https://cdn.insertchat.com/public/providers/protonmail.png"
                              alt="ProtonMail"
                            />
                          </a>
                        </div>

                        <div class="column is-3">
                          <a href="https://www.icloud.com/mail/" target="_blank">
                            <img
                              src="https://cdn.insertchat.com/public/providers/icloud.png"
                              alt="iCloud Mail"
                              style="height: 40px"
                            />
                          </a>
                        </div>
                      </div>
                    </VMessage>

                    <FormKit
                      autocomplete="email"
                      name="email"
                      type="email"
                      validation="required:trim|email"
                      label="Work e-mail"
                      placeholder="Work e-mail"
                      :floating-label="true"
                    />

                    <FormKit
                      id="password"
                      name="password"
                      type="password"
                      validation="required:trim"
                      autocomplete="password"
                      label="Password"
                      placeholder="Password"
                      suffix-icon="eyeClosed"
                      suffix-icon-class="hover:text-blue-500"
                      :floating-label="true"
                      @suffix-icon-click="handlePasswordIconClick"
                    />

                    <FormKit
                      v-if="referrer_email"
                      name="referrer_email"
                      type="email"
                      validation="required:trim|email"
                      label="Referrer e-mail"
                      :floating-label="true"
                      :disabled="true"
                    />

                    <vue-turnstile
                      v-if="IS_PRODUCTION && !isAffiliate"
                      v-model="turnstileToken"
                      class="has-text-centered"
                      :site-key="TURNSTILE_SITE_KEY"
                    />

                    <VButton
                      :disabled="isFormDisabled"
                      :loading="isLoading"
                      type="submit"
                      color="primary"
                      class="w-100"
                      @click="triggerSubmit"
                    >
                      Continue
                    </VButton>
                  </FormKit>

                  <template v-if="!isAffiliate && !appSumoCode">
                    <hr class="w-100 mb-5" />

                    <!--
                    <VButton
                      type="submit"
                      color="primary"
                      class="w-100 mb-4"

                      icon="logos:facebook"
                      style="line-height: 0px"
                      @click="facebookLogin"
                    >
                      Access with Facebook
                    </VButton>
                    -->

                    <GoogleLogin
                      :callback="onSubmit"
                      :prompt="true"
                      :auto-login="true"
                      :cancel-on-tap-outside="false"
                      popup-type="TOKEN"
                      class="w-100"
                    >
                      <VButton
                        type="submit"
                        color="primary"
                        class="w-100 mb-4"
                        icon="logos:google-icon"
                        style="line-height: 0px"
                      >
                        Access with Google
                      </VButton>
                    </GoogleLogin>
                  </template>

                  <div class="columns mt-4">
                    <div class="column is-12">
                      <RouterLink :to="{ path: '/auth/reset-password' }" class="is-block">
                        <a> Can’t sign in? Reset your password </a>
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
export type LandingLayoutTheme = 'darker' | 'light'

const props = withDefaults(
  defineProps<{
    theme?: LandingLayoutTheme
    iframe?: boolean
  }>(),
  {
    theme: 'light',
    iframe: false,
  }
)
</script>

<template>
  <div class="minimal-wrapper" :class="[props.theme]" :style="{ minHeight: '100vh' }">
    <slot> </slot>
  </div>
</template>

<style lang="scss">
@import '/@src/scss/main.scss';

#crisp-chatbox {
  display: none !important;
}
</style>

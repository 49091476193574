import { useApi } from '/@src/composable/useApi'

export async function useTableDataResolver({ url, searchTerm, start, limit, sort }): Promise<any> {
  const api = useApi()
  let loading = false
  const controller = new AbortController()

  // searchTerm will contains the value of the wrapperState.searchInput
  // the update will be debounced to avoid to much requests
  const searchQuery = searchTerm ? `&search=${searchTerm}` : ''
  let sortQuery = ''

  // sort will be a string like "name:asc"
  if (sort && sort.includes(':')) {
    const [sortField, sortOrder] = sort.split(':')
    sortQuery = `&orderBy[0][key]=${sortField}&orderBy[0][direction]=${sortOrder}`
  }

  const fullUrl = `${url}${
    url.includes('?') ? '&' : '?'
  }start=${start}&limit=${limit}${searchQuery}${sortQuery}`

  try {
    // async fetch data to our server
    const response = await api({
      method: 'GET',
      url: fullUrl,
      // controller is an instance of AbortController,
      // this allow to abort the request when the state
      // is invalidated (before fetchData will be retriggered)
      signal: controller.signal,
    })

    if (response) {
      const { data, meta } = response.data

      return {
        data,
        meta,
        loading,
        total: meta?.total,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading = false
  }
}

<script setup lang="ts">
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalFormProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { open } = props.modalFormProps

// Const data
const formId = 'formInviteEarn'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})

// Composable
const notyf = useNotyf()
const userSession = useUserSession()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Computed
const hasEmails = computed(() => {
  return formData.value.emails.some((item: any) => item?.email && item?.item?.trim() !== '')
})

// Lifecycle hooks
onMounted(async () => {
  // @ts-ignore
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: { emails: { email: string }[] } | undefined) => {
  try {
    if (isLoading.value) {
      return
    }

    EventHelper.push('submitted_form_share_app')

    isLoading.value = true

    const emails = fields?.emails ?? []

    await HttpHelper.post('/users/invite-friends', null, {
      emails: emails.map((item) => item.email),
    })

    getNode(formId)?.reset()

    notyf.success('Thank you! We will send the emails on your behalf!')

    onClose()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const onClose = async () => {
  emit('update:modalFormProps', { open: false })
}
</script>

<template>
  <CustomModal
    size="big"
    :open="open"
    title="Invite & Earn"
    :override-modal="false"
    :noscroll="false"
    :noclose="false"
    :narrow="true"
    :remove-close-btn="false"
    cancel-label="Maybe later"
    @close="onClose"
  >
    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

      <VMessage color="success">
        <b>
          Earn a generous 30% commission for every successful subscription purchased by your
          friends,
          {{ userSession?.user?.first_name }}!
        </b>
        <br /><br />
        Here's what you stand to gain:<br /><br />
        <ul>
          <li>Basic Plan: $49 * 30% = <b>$15 for you!</b></li>
          <li>Standard Plan: $99 * 30% = <b>$30 for you!</b></li>
          <li>Agency Plan: $399 * 30% = <b>$120 for you!</b></li>
          <li>Business+ Plan: $899 * 30% = <b>$270 for you!</b></li>
        </ul>
        <br />
        Invite your friends now and start earning!
      </VMessage>

      <div class="columns is-multiline">
        <div class="column is-12">
          <FormKit
            v-slot="{ index }"
            type="repeater"
            name="emails"
            :up-control="false"
            :down-control="false"
            :add-button="true"
            :insert-control="true"
            :disabled="isLoading"
            min="1"
          >
            <FormKit
              name="email"
              type="text"
              :label="`E-mail ${index + 1}`"
              validation="trim|email"
              :placeholder="`friend${index + 1}@gmail.com`"
              :disabled="isLoading"
            />
          </FormKit>
        </div>
      </div>
    </FormKit>

    <template #action>
      <div class="form-footer">
        <div class="right">
          <VButton
            :disabled="isFormDisabled || !hasEmails"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Send Invite{{ formData.emails.length > 1 ? 's' : '' }}
          </VButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

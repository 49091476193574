<script setup lang="ts">
import { onMounted, ref, watch, watchPostEffect } from 'vue'
import { useRoute } from 'vue-router'
import type { SideblockTheme } from '/@src/components/navigation/desktop/Sideblock.vue'
import { useViewWrapper } from '/@src/stores'

const props = withDefaults(
  defineProps<{
    theme?: SideblockTheme
    defaultSideblock?: string
    closeOnChange?: boolean
    openOnMounted?: boolean
    nowrap?: boolean
  }>(),
  {
    defaultSideblock: 'chats',
    theme: 'default',
    openOnMounted: true,
  }
)

const viewWrapper = useViewWrapper()
const route = useRoute()
const isMobileSideblockOpen = ref(false)
const isDesktopSideblockOpen = ref(props.openOnMounted)

/**
 * watchPostEffect callback will be executed each time dependent reactive values has changed
 */
watchPostEffect(() => {
  viewWrapper.setPushedBlock(isDesktopSideblockOpen.value ?? false)
})

onMounted(() => {
  viewWrapper.setPushed(false)
})

watch(
  () => route.fullPath,
  () => {
    isMobileSideblockOpen.value = false

    if (props.closeOnChange && isDesktopSideblockOpen.value) {
      isDesktopSideblockOpen.value = false
    }
  }
)
</script>

<template>
  <div class="sidebar-layout">
    <div class="app-overlay"></div>

    <!-- Mobile navigation -->
    <MobileNavbar
      :is-open="isMobileSideblockOpen"
      @toggle="isMobileSideblockOpen = !isMobileSideblockOpen"
    >
      <template #brand>
        <RouterLink :to="{ path: '/chatbots' }" class="navbar-item is-brand">
          <AnimatedLogo :text="false" />
        </RouterLink>
      </template>
    </MobileNavbar>

    <Transition name="slide-x">
      <Sideblock v-if="isDesktopSideblockOpen" :theme="props.theme" class="is-hidden-touch">
        <template #header>
          <RouterLink :to="{ path: '/chatbots' }" class="sidebar-block-logo">
            <AnimatedLogo :text="false" />
          </RouterLink>
        </template>
        <template #links>
          <Menu> </Menu>
        </template>

        <template #bottom-links>
          <WidgetUpgrade button-width-class="w-100" />
        </template>
      </Sideblock>
    </Transition>

    <VViewWrapper full>
      <VPageContentWrapper>
        <template v-if="props.nowrap">
          <slot> </slot>
        </template>
        <VPageContent v-else class="is-relative">
          <div class="page-title">
            <!-- Sidebar Trigger -->
            <div
              class="vuero-hamburger nav-trigger push-resize"
              tabindex="0"
              @keydown.space.prevent="isDesktopSideblockOpen = !isDesktopSideblockOpen"
              @click="isDesktopSideblockOpen = !isDesktopSideblockOpen"
            >
              <span class="menu-toggle has-chevron">
                <span :class="[isDesktopSideblockOpen && 'active']" class="icon-box-toggle">
                  <span class="rotate">
                    <i aria-hidden="true" class="icon-line-top"> </i>
                    <i aria-hidden="true" class="icon-line-center"> </i>
                    <i aria-hidden="true" class="icon-line-bottom"> </i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <VIcon :icon="viewWrapper.pageIcon" color="#121933" class="mr-3" />

              <h1 class="title is-4">
                {{ viewWrapper.pageTitle }}
              </h1>
            </div>

            <div
              v-if="!['subscriptions-success', 'subscriptions-cancel'].includes(String(route.name))"
              class="columns ml-auto is-hidden-mobile driverjs-widgets"
            >
              <WidgetPlan />
              <WidgetMessageCredits />
            </div>

            <!-- <Toolbar class="desktop-toolbar" /> -->
          </div>

          <WidgetUpgrade />
          <slot> </slot>

          <div
            v-if="
              !['subscriptions', 'subscriptions-success', 'subscriptions-cancel'].includes(
                String(route.name)
              )
            "
          >
            <FormSuggestions />
          </div>
        </VPageContent>
      </VPageContentWrapper>
    </VViewWrapper>
  </div>
</template>

<style lang="scss">
@import '/@src/scss/main.scss';
</style>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

export type VMessageColor = 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'white'
export interface VMessageEmits {
  (e: 'close'): void
}
export interface VMessageProps {
  color?: VMessageColor
  closable?: boolean
}

const emit = defineEmits<VMessageEmits>()
const props = withDefaults(defineProps<VMessageProps>(), {
  color: undefined,
})
const isAnimating = ref(false)

onMounted(() => {
  isAnimating.value = true

  setTimeout(() => {
    isAnimating.value = false
  }, 1000)
})
</script>

<template>
  <div
    class="message"
    :class="[props.color && `is-${props.color}`, { 'is-animating': isAnimating }]"
  >
    <a
      v-if="props.closable"
      aria-label="Dismiss"
      class="delete"
      tabindex="0"
      @keydown.space.prevent="emit('close')"
      @click.prevent="emit('close')"
    ></a>
    <div class="message-body"><slot></slot></div>
  </div>
</template>

<style lang="scss">
.message {
  &.is-animating {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  position: relative;
  border: 1px solid var(--border);
  box-shadow: var(--light-box-shadow);

  &.is-primary {
    border-color: var(--primary-light-24);

    .delete {
      &::before,
      &::after {
        background-color: var(--primary);
      }
    }
  }

  &.is-info {
    border-color: var(--info-light-24);

    .delete {
      &::before,
      &::after {
        background-color: var(--info);
      }
    }
  }

  &.is-success {
    border-color: var(--success-light-24);

    .delete {
      &::before,
      &::after {
        background-color: var(--success);
      }
    }
  }

  &.is-warning {
    border-color: var(--warning-light-24);

    .delete {
      &::before,
      &::after {
        background-color: var(--warning);
      }
    }
  }

  &.is-danger {
    border-color: var(--danger-light-24);

    .delete {
      &::before,
      &::after {
        background-color: var(--danger);
      }
    }
  }

  .delete {
    position: absolute;
    background-color: transparent;
    top: 6px;
    right: 6px;

    &::before {
      height: 1px;
      background-color: var(--light-text);
    }

    &::after {
      width: 1px;
      background-color: var(--light-text);
    }
  }

  .message-body {
    border: none;
    font-family: var(--font);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

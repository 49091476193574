import ChatbotHelper from './chatbot'
import { IWidget } from '/@src/interfaces'

export default class PwaHelper {
  public static async isPWAInstalled(): Promise<boolean> {
    // Method 1: Check for standalone mode
    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone === true
    ) {
      return true
    }

    // Method 2: Check using getInstalledRelatedApps
    if ((navigator as any).getInstalledRelatedApps && window.location === window.parent.location) {
      // Ensure we're not in an iframe
      try {
        const apps = await (navigator as any).getInstalledRelatedApps()
        if (apps && apps.length > 0) {
          return true
        }
      } catch (error) {
        console.error('Error checking installation status:', error)
      }
    }

    // Method 3: Detect iOS Safari PWA
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const isStandalone = 'standalone' in window.navigator && (window.navigator as any).standalone
    if (isIOS && isStandalone) {
      // PWA is installed
      return true
    }

    // Method 4: Check for 'beforeinstallprompt' event
    if (
      window.matchMedia('(display-mode: browser)').matches &&
      (window as any).beforeinstallprompt
    ) {
      return new Promise<boolean>((resolve) => {
        const beforeInstallPromptHandler = (event: Event) => {
          // Prevent Chrome 76 and later from showing the mini-infobar
          event.preventDefault()
          // Resolve with true to indicate PWA installation is available
          resolve(true)
          // Remove the event listener as it's no longer needed
          ;(window as any).beforeinstallprompt.removeEventListener(
            'beforeinstallprompt',
            beforeInstallPromptHandler
          )
        }
        // Listen for the 'beforeinstallprompt' event
        ;(window as any).beforeinstallprompt.addEventListener(
          'beforeinstallprompt',
          beforeInstallPromptHandler
        )
      })
    }

    // Fallback: Assume PWA not installed
    return false
  }

  public static generateManifest(widget: IWidget) {
    // Const data
    const domain = ChatbotHelper.getDomain()

    const dynamicManifest = {
      name: widget?.computed_settings?.mobile_app?.name,
      short_name: widget?.computed_settings?.mobile_app?.short_name,
      description: widget?.computed_settings?.mobile_app?.description,
      id: widget?.uid,
      start_url: `${domain}/embed/${widget?.uid}`,
      scope: '/',
      display: 'standalone',
      orientation: 'portrait',
      theme_color: widget?.computed_settings?.mobile_app?.color,
      background_color: widget?.computed_settings?.mobile_app?.background,
      categories: widget?.computed_settings?.mobile_app?.categories,
      lang: widget?.writing_language ?? 'en',
      prefer_related_applications: false,
      related_applications: [],
      iarc_rating_id: '',
      dir: 'auto',
      icons: [
        {
          // src: 'https://cdn.insertchat.com/pwa-64x64.png',
          src: widget?.computed_settings?.mobile_app?.icon_512_512?.url,
          sizes: '64x64',
          type: 'image/png',
          purpose: 'any',
        },
        {
          // src: 'https://cdn.insertchat.com/pwa-144x144.png',
          src: widget?.computed_settings?.mobile_app?.icon_512_512?.url,
          sizes: '144x144',
          type: 'image/png',
          purpose: 'any',
        },
        {
          // src: 'https://cdn.insertchat.com/pwa-192x192.png',
          src: widget?.computed_settings?.mobile_app?.icon_512_512?.url,
          sizes: '192x192',
          type: 'image/png',
          purpose: 'any',
        },
        {
          src: widget?.computed_settings?.mobile_app?.icon_512_512?.url,
          sizes: '512x512',
          type: 'image/png',
          purpose: 'any',
        },
        {
          // src: 'https://cdn.insertchat.com/maskable-icon-512x512.png',
          src: widget?.computed_settings?.mobile_app?.icon_512_512?.url,
          sizes: '512x512',
          type: 'image/png',
          purpose: 'maskable',
        },
      ],
      launch_handler: {
        client_mode: ['focus-existing', 'navigate-existing', 'auto'],
      },
      screenshots: [],
      /*
      screenshots: [
        {
          src: 'https://picsum.photos/540/720',
          sizes: '540x720',
          type: 'image/png',
          form_factor: 'narrow',
        },
        {
          src: 'https://picsum.photos/1280/720',
          sizes: '1280x720',
          type: 'image/png',
          form_factor: 'wide',
          label: 'Homescreen of Awesome App',
        },
      ],
      */
    }

    const stringManifest = JSON.stringify(dynamicManifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)

    if (document && document.querySelector('#web-manifest')) {
      document.querySelector('#web-manifest')?.setAttribute('href', manifestURL)
    }
  }
}

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import VueTurnstile from 'vue-turnstile'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Const data
const formId = 'formConfirm'
const TURNSTILE_SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY

// Reactive data
const formData: any = ref({})
const formState: any = reactive({})
const isLoading = ref(false)
const turnstileToken = ref('')

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Composable
const { t } = useI18n()
const notyf = useNotyf()
const router = useRouter()
const route = useRoute()
const userSession = useUserSession()
const queryEmail = route.query.email as string
const queryConfirmToken = route.query.confirm_token as string
useHead({
  title: 'Confirm your account',
})

// Computed
const isAffiliate = computed(() => !ChatbotHelper.allowedDomains.includes(window.location.origin))

// Lifecycle hooks
onMounted(async () => {
  // @ts-ignore
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

  if (queryEmail && queryConfirmToken) {
    formData.value.email = queryEmail.toLowerCase().split(' ').join('+')
    formData.value.confirm_token = queryConfirmToken

    await onSubmit({ email: formData.value.email, confirm_token: formData.value.confirm_token })
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { email, confirm_token } = fields

    const response = await HttpHelper.post(
      '/auth/confirm-account',
      null,
      {
        email,
        confirm_token,
      },
      {
        withoutApp: true,
      }
    )

    const { user_gtm, token, is_admin } = response ?? {}

    EventHelper.config({ user: user_gtm })

    userSession?.setToken(token)
    is_admin && userSession?.setIsAdmin(true)

    notyf.success('Your account is confirmed. Thanks for joining us!')

    setTimeout(async () => {
      await router.push({
        path: '/chatbots',
      })
    }, 500)
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="auth-wrapper-inner columns is-gapless has-text-centered">
    <div class="column is-12">
      <div class="hero is-fullheight is-white">
        <div class="hero-heading">
          <div v-if="!isAffiliate" class="auth-logo">
            <RouterLink :to="{ path: '/auth/access' }">
              <AnimatedLogo :text="true" />
            </RouterLink>
          </div>
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="auth-content">
                  <h2>Confirm your account</h2>

                  <RouterLink class="is-block mt-4" :to="{ path: '/auth/access' }">
                    Register an account?
                  </RouterLink>
                </div>
                <div class="auth-form-wrapper">
                  <!-- Register Form -->
                  <VMessage color="info">
                    Please check your email and confirm your address to activate your account.
                    Thanks!
                  </VMessage>

                  <FormKit
                    :id="formId"
                    v-model="formData"
                    type="form"
                    :disabled="isLoading"
                    :actions="false"
                    @submit="onSubmit"
                  >
                    <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

                    <FormKit
                      name="email"
                      type="email"
                      validation="required:trim|email"
                      autocomplete="username"
                      :label="t('page.auth.register.form.inputs.email.label')"
                      :placeholder="t('page.auth.register.form.inputs.email.placeholder')"
                    />

                    <FormKit
                      name="confirm_token"
                      type="text"
                      label="Token"
                      placeholder="Confirmation token"
                      validation="trim"
                    />

                    <vue-turnstile
                      v-model="turnstileToken"
                      class="has-text-centered mb-4"
                      :site-key="TURNSTILE_SITE_KEY"
                    />

                    <VButton
                      :disabled="isFormDisabled"
                      :loading="isLoading"
                      type="submit"
                      color="primary"
                      class="w-100"
                      @click="triggerSubmit"
                    >
                      Confirm my account
                    </VButton>
                  </FormKit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

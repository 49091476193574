<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onBeforeMount, ref } from 'vue'
import { ITab } from '/@src/interfaces'
import Profile from '/@src/pages/settings/profile.vue'
import Team from '/@src/pages/settings/team.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Reactive data
const tabs = ref<ITab[]>([
  { label: 'Profile', value: 'profile', icon: ICONS.pages.account, component: Profile },
  { label: 'Team', value: 'team', icon: ICONS.pages.users, component: Team },
])
const processedTabs = ref<ITab[]>([])
const title = ref('Settings')
const selected = ref(tabs.value[0]?.value)

// Composable
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title: title.value })

onBeforeMount(() => {
  processedTabs.value = tabs.value.filter((tab) => {
    if (!userSession?.isClient() && ['whitelabel', 'team'].includes(tab.value)) {
      return true
    }

    if (tab.value === 'profile') {
      return true
    }

    return false
  })

  // Set the initial selected tab
  selected.value = processedTabs.value[0]?.value
})
</script>

<template>
  <VCard class="h-100 p-0">
    <VTabs
      v-model:selected="selected"
      :tabs="processedTabs"
      :vertical="true"
      :data="{}"
      :alwaysOpen="true"
    >
      <template #title>
        <VBlock>
          <h1 class="title is-5">Menu</h1>
        </VBlock>
      </template>
    </VTabs>
  </VCard>
</template>

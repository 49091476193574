import { useEventBus } from '@vueuse/core'

const globalEventBus = useEventBus<{ event: string; payload: object }>('global')

globalEventBus.on((data) => {
  // console.log('data', data)
})

export default class EventBusHelper {
  public static getGlobalEventBus() {
    return globalEventBus
  }

  public static emitEvent(event: string, payload: object) {
    return globalEventBus.emit({ event, payload })
  }
}

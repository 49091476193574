<route lang="yaml">
meta:
  requiresAuth: true
</route>

<template>
  <NavbarLayout>
    <RouterView v-bind="$attrs" />
  </NavbarLayout>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, Reactive, ref, Ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, FileHelper, HttpHelper } from '/@src/helpers'
import { IProduct } from '/@src/interfaces'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
})

const { widgetUid, trainingType, provider } = toRefs(props)

// Composable
const userSession = useUserSession()
const notyf = useNotyf()

// Const data
const formId = 'trainingProductsForm'
const { imageExtensions } = FileHelper

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<IProduct[]> = ref([])
const isLoading = ref(false)
const training: Ref<any> = ref({
  label: '',
})
const loaderProgress = ref(-1)
const loaderText = ref('')
const maxFileSizeLimitation = ref()
const fileInputKey = ref(0)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true
    maxFileSizeLimitation.value = userSession.getPlanLimitations('max_file_size')

    if (provider.value && provider.value?.uid) {
      training.value = {
        label: provider.value?.label,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit()
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const onInit = () => {
  newMaterial.value = [
    {
      name: '',
      category: '',
      sku: '',
      size: '',
      color: '',
      price: '',
      url: '',
      tags: [],
      images: [],
      description: '',
    },
  ]

  fileInputKey.value++
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onFileUpload = (event: any) => {
  const invalidFiles = Array.from(event.target.files as FileList)
    .filter((file: File) => file.size / 1024 > maxFileSizeLimitation.value)
    .map((file: File) => file.name)

  if (invalidFiles.length) {
    notyf.error(
      `Invalid file size for ${invalidFiles.join(
        ', '
      )}. Maximum size allowed for your current plan is ${maxFileSizeLimitation.value / 1024}MB.`
    )
  }
}

const onSubmit = async () => {
  if (isLoading.value) {
    return
  }

  if (!newMaterial.value || (newMaterial.value && !newMaterial.value.length)) {
    isLoading.value = false

    return
  }

  isLoading.value = true
  loaderProgress.value = 0

  for (const product of Object.values(newMaterial.value)) {
    const images =
      Array.isArray(product?.images) && product?.images.length > 0 ? product?.images : []

    if (images && images.length) {
      for (const [index, image] of Object.entries(product?.images)) {
        if (image?.file) {
          const reader = new FileReader()

          reader.onload = (e) => {
            const base64Image = e?.target?.result
            if (typeof base64Image === 'string') {
              // @ts-ignore
              product.images[Number(index)] = base64Image
            }
          }

          reader.readAsDataURL(image.file)
        }
      }
    }
  }

  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    if (provider.value?.uid && Object.keys(newMaterial?.value)?.length) {
      await HttpHelper.batch(
        '/providers/synch',
        provider.value?.uid,
        { training: newMaterial.value },
        {
          batchSize: 25,
          batchKey: '',
        },
        (numBatches: number, i: number) => {
          loaderText.value = `Processing batch ${i + 1}/${numBatches}`
          loaderProgress.value = Math.round((i * 100) / numBatches)
        }
      )

      emit('synch')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Training #1"
      validation-visibility="live"
    />

    <CustomForm title="Add Knowledge" subtitle="" class="mb-4">
      <template #body>
        <FormKit
          v-slot="{ index: indexProduct }"
          v-model="newMaterial"
          name="products"
          type="repeater"
          :preserve="false"
          :disabled="isLoading"
          :up-control="false"
          :down-control="true"
          :add-button="true"
          :insert-control="false"
          min="1"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <h5 class="title is-6 mt-2 mb-2">Item #{{ Number(indexProduct) + 1 }}</h5>
              <hr class="m-0" />
            </div>

            <div class="column is-4">
              <FormKit
                name="name"
                type="text"
                label="Name"
                validation="required:trim"
                placeholder="Hoodie #1"
              />
            </div>

            <div class="column is-4">
              <FormKit
                name="category"
                type="text"
                label="Category"
                validation="trim"
                placeholder="Hoodie"
              />
            </div>

            <div class="column is-4">
              <FormKit
                name="sku"
                type="text"
                label="SKU or ID"
                validation="trim"
                placeholder="hoodie-happy-1"
              />
            </div>

            <div class="column is-4">
              <FormKit
                name="size"
                type="text"
                label="Sizes"
                validation="trim"
                placeholder="S, M, L"
              />
            </div>

            <div class="column is-4">
              <FormKit
                name="color"
                type="text"
                label="Colors"
                validation="trim"
                placeholder="Black, Blue, Red"
              />
            </div>

            <div class="column is-4">
              <FormKit name="price" type="text" label="Price" validation="trim" placeholder="$99" />
            </div>

            <div class="column is-6">
              <FormKit
                name="url"
                type="url"
                label="URL"
                validation="trim"
                placeholder="https://..."
              />
            </div>

            <div class="column is-6">
              <FormKit
                :allow-new-values="true"
                name="tags"
                type="taglist"
                label="Tags"
                validation="trim"
                placeholder="hoodie, black"
                popover
                :options="['Product', 'Service']"
              />
            </div>

            <div class="column is-12">
              <FormKit
                :key="fileInputKey"
                name="images"
                label="Images (Multiple)"
                type="file"
                :accept="imageExtensions.join(',')"
                multiple
                @change="onFileUpload"
              />
            </div>

            <div class="column is-12">
              <FormKit
                name="description"
                type="textarea"
                label="Description"
                validation="trim"
                placeholder="Premium quality hoodie."
                rows="1"
              />
            </div>
          </div>
        </FormKit>

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Train
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, Ref, ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { plan, open } = props.modalProps

// Composable
const userSession = useUserSession()

// Const data
const formId = 'formCancelModal'
const reasons = {
  cost: 'It costs too much',
  pause: 'I need to pause my account (Your data and config will be securely preserved!)',
  usage: 'I don’t know how to use it very well',
  issues: 'I experienced technical issues',
  features: 'I need specific features that are not available',
  useless: 'I didn’t use it enough',
  alternative: 'I found an alternative that suits my needs',
  other: 'Other',
}

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const title = ref(`I’m sorry to see you go ${userSession?.user?.first_name}!`)
const step = ref('infos')
const pauseMonths: Ref<1 | 2 | 3> = ref(1)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const closeModal = (refresh = false) => {
  emit('update:modalProps', { open: false })
  emit('close', refresh)
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async () => {
  try {
    title.value = 'No worries, I can help you'
    step.value = 'offers'
  } catch (error: any) {
    console.error(error)
  }
}

const onRecovery = async (params: { type: string; pauseMonths?: number }) => {
  try {
    const { type, pauseMonths } = params
    const { reason, feedback } = formData

    if (!isLoading.value) {
      isLoading.value = true
      title.value = 'No worries, I can help you'

      await Promise.all([
        HttpHelper.post('/subscriptions/recovery', null, {
          type,
          pause_months: pauseMonths,
        }),
        HttpHelper.post('/suggestions', null, {
          type: 'recover_subscription',
          suggestion: `Reason: ${reason ?? ''}\nFeedback: ${feedback ?? ''}`,
        }),
      ])

      step.value = 'recovered'
    }
  } catch (error: any) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const onCancel = async () => {
  try {
    const { reason, feedback } = formData

    EventHelper.push('churned')

    await Promise.all([
      HttpHelper.post(`/subscriptions/cancel-plan`, null, {
        plan_id: plan?.monthly?.default_plan_id,
        reason,
        feedback,
      }),
      HttpHelper.post('/suggestions', null, {
        type: 'cancel_subscription',
        suggestion: `Reason: ${reason ?? ''}\nFeedback: ${feedback ?? ''}`,
      }),
    ])

    // getNode(formId)?.reset()

    step.value = 'canceled'
  } catch (error: any) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <CustomModal
    size="medium"
    :open="open"
    :title="title"
    :override-modal="false"
    :noscroll="false"
    :noclose="false"
    :disable-footer="true"
    :disable-header="false"
    :narrow="true"
    @close="closeModal"
  >
    <!-- Screen 1: Questions -->
    <FormKit
      v-if="step === 'infos'"
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <VMessage color="warning">
        Hi, I’m Zak, your personal support agent. Is there something I can do to change your mind?
        I’d love to hear from you. Please
        <a href="mailto:zak@insertchat.com?subject=Cancel my account" target="_top">e-mail</a> or
        chat with me using the live chatbot 👍
      </VMessage>

      <div class="columns is-multiline">
        <div class="column is-12">
          <b>
            Your account will remain active until the end of your current billing cycle.<br />
            Your account will be locked after this date and your data will be permanently deleted
            after 3 days!
          </b>
        </div>

        <div class="column is-12">
          <hr class="mt-0" />
          <b>Can I help you?</b>
          <p>
            I can troubleshoot any issues by
            <a href="mailto:zak@insertchat.com?subject=Troubleshoot issues" target="_top">
              e-mail
            </a>
            or live chat, just reach out to me.
          </p>
          <hr />
          <b>Why are you leaving? (Pick the main reason)</b>
          <p>I’m grateful for your quick feedback.</p>
          <br />
          <FormKit name="reason" type="radio" :options="reasons" validation="required:trim" />

          <div v-if="formData.reason" class="mt-5">
            <FormKit
              name="feedback"
              type="textarea"
              label="Can you share more details?"
              help="Just let us know what’s going on!"
              rows="1"
              validation="length:10|required:trim"
            />
          </div>
        </div>
      </div>

      <div class="form-footer">
        <div class="left">
          <VButton
            color="light"
            :disabled="isFormDisabled"
            :loading="isLoading"
            @click="triggerSubmit"
          >
            <small class="rem-100"> Submit </small>
          </VButton>
        </div>

        <div class="right">
          <VButton color="success">
            <small class="rem-100 is-capitalize is-weight-500" @click="closeModal(false)">
              Nevermind, I’ll stay
            </small>
          </VButton>
        </div>
      </div>
    </FormKit>

    <!-- Screen 2: Offers -->
    <div v-else-if="step === 'offers'">
      <!-- 25% discount -->
      <div v-if="['cost', 'useless', 'alternative', 'other'].includes(formData.reason)">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12 title is-5">I totally understand.</div>
          <div class="column is-12 subtitle">
            How about <b>25% off for life?</b> Would that change your mind?
          </div>
          <div class="column is-12">
            <img
              width="200"
              src="https://cdn.insertchat.com/illustrations/subscriptions/discount_25.svg"
              alt=""
            />
          </div>
          <div class="column is-12">
            Just say yes. I’ll take care of the details and make sure you keep getting uninterrupted
            access to the best AI chatbots.
          </div>
          <div class="column is-12">What do you think?</div>
          <div class="column is-12">
            <VButton
              color="success"
              class="w-100"
              :loading="isLoading"
              @click="onRecovery({ type: 'cost' })"
            >
              <small class="rem-100 is-capitalize is-weight-500"> SAVE 25% FOR LIFE </small>
            </VButton>
          </div>
          <div class="column is-12">
            <a @click="onCancel">
              <small class="rem-100"> I’ll pass. Cancel my account </small>
            </a>
          </div>
        </div>
      </div>

      <!-- Pause -->
      <div v-if="['pause'].includes(formData.reason)">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12 title is-5">Got it! We can do that.</div>
          <div class="column is-12">
            Tell me how long you want to put it on hold, and
            <b>we’ll keep your data saved and secure</b> while you’re gone.
          </div>
          <div class="column is-12 is-flex is-justify-content-center">
            <FormKit
              v-model="pauseMonths"
              type="radio"
              :options="{
                1: '1 month',
                2: '2 months',
                3: '3 months',
              }"
            />
          </div>
          <div class="column is-12">
            <VButton
              color="success"
              class="w-100"
              :loading="isLoading"
              @click="onRecovery({ type: 'pause', pauseMonths })"
            >
              <small class="rem-100 is-capitalize is-weight-500">
                PAUSE MY ACCOUNT. I’LL BE BACK.
              </small>
            </VButton>
          </div>
        </div>
      </div>

      <!-- Usage -->
      <div v-if="['usage', 'issues', 'features'].includes(formData.reason)">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12 title is-5">I think I can clear up some of the confusion.</div>
          <div class="column is-12">
            I’m sorry to hear that. The last thing we want is for you to feel like you’re not
            getting all that you can out of your chatbots. (Seriously, there’s some sweet stuff
            there!)
          </div>
          <div class="column is-12">
            I’d like to offer you a personal "100% FREE", one-on-one setup session to make sure that
            - by the end - your AI chatbot is perfectly setup. If you don't like what you get, tell
            me and I will cancel your account then.
          </div>
          <div class="column is-12">Sound good?</div>
          <div class="column is-12">
            <VButton
              color="success"
              class="w-100"
              :loading="isLoading"
              @click="onRecovery({ type: 'usage' })"
            >
              <small class="rem-100 is-capitalize is-weight-500"> SURE, SET IT UP </small>
            </VButton>
          </div>
          <div class="column is-12">
            <a @click="onCancel">
              <small class="rem-100"> I’ll pass. Cancel my account </small>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Screen 3-1: Recovered -->
    <div v-else-if="step === 'recovered'">
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12 title is-5">We’re happy to see you stay.</div>

        <div v-if="formData.reason === 'cost'" class="column is-12 subtitle">
          Your huge discount is applied!
        </div>
        <div v-if="formData.reason === 'pause'" class="column is-12 subtitle">
          Your account is paused for {{ pauseMonths }} month{{ pauseMonths > 1 ? 's' : '' }}, see
          you soon!
        </div>
        <div v-if="formData.reason === 'usage'" class="column is-12 subtitle">
          An e-mail was sent to schedule our One-on-One Setup Session, check your inbox and spam!
        </div>
        <div class="column is-12">
          <img
            width="200"
            src="https://cdn.insertchat.com/illustrations/subscriptions/happy.svg"
            alt="Happy face"
          />
        </div>
        <div class="column is-12">
          <VButton color="success" class="w-100" @click="closeModal(true)">
            <small class="rem-100 is-capitalize is-weight-500"> I’m happy! </small>
          </VButton>
        </div>
      </div>
    </div>

    <!-- Screen 3-2: Canceled -->
    <div v-else-if="step === 'canceled'">
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12 title is-5">We're sorry to see you go.</div>
        <div class="column is-12 subtitle is-6">
          Your account has been cancelled and you will not be charged again.
        </div>
        <div class="column is-12">
          <img
            width="200"
            src="https://cdn.insertchat.com/illustrations/subscriptions/sad.svg"
            alt="Sad face"
          />
        </div>
        <div class="column is-12">
          If you change your mind just drop us a note, and we can get your account reactivated in a
          snap.
        </div>
        <div class="column is-12">
          <VButton color="success" class="w-100" @click="closeModal(true)">
            <small class="rem-100 is-capitalize is-weight-500"> OK! </small>
          </VButton>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import { computed, Ref, ref, watch, watchEffect } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: undefined,
  },
  status: {
    type: String,
    required: true,
    default: '',
  },
})

// Reactive data
const pincode: Ref<string> = ref('')
const pincodeError = ref(false)
const pincodeSuccess = ref(false)

// Const data
const digits = 6

const pincodeLength = computed(() => {
  return pincode.value.length
})

const buttonDisabled = computed(() => {
  return pincodeError.value || pincodeSuccess.value
})

// Set initial value of pincode if modelValue prop is defined
watchEffect(() => {
  if (typeof props.modelValue !== 'undefined') {
    pincode.value = props.modelValue
  }
})

// Watch for changes to pincode
watch(pincode, () => {
  if (pincodeLength.value === digits) {
    emit('update:modelValue', pincode.value)
  }
})

watch(
  () => props.status,
  () => {
    if (props.status === 'success') {
      triggerSuccess()
    }

    if (props.status === 'failure') {
      triggerFailure()
    }
  }
)

// Functions
const clickPinButton = (pressedNumber: number) => {
  if (pincodeLength.value < digits) {
    pincode.value = pincode.value + pressedNumber
  }
}

const resetPincode = () => {
  pincode.value = ''
  pincodeError.value = false
  pincodeSuccess.value = false
}

const triggerFailure = () => {
  pincodeError.value = true
  setTimeout(() => resetPincode(), 800)
}

const triggerSuccess = () => {
  pincodeSuccess.value = true
  setTimeout(() => resetPincode(), 2500)
}
</script>

<template>
  <div
    class="vue-pincode pt-1 is-justify-content-center"
    :class="pincodeSuccess ? 'vue-pincode--success' : ''"
  >
    <div class="vue-pincode__fields" :class="pincodeError ? 'vue-pincode__fields--miss' : ''">
      <span
        v-for="digit in digits"
        :key="digit"
        :class="pincode.length >= digit ? 'active' : ''"
      ></span>
    </div>
    <div class="vue-pincode__numbers">
      <button
        v-for="(number, idx) in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
        :key="idx"
        class="shadow"
        :disabled="buttonDisabled"
        @click="clickPinButton(number)"
      >
        <span>{{ number }}</span>
      </button>
      <button :disabled="buttonDisabled" @click="clickPinButton(0)">
        <span>0</span>
      </button>
      <button class="vue-pincode__undo" :disabled="buttonDisabled" @click="resetPincode">
        <span>
          <svg
            height="512pt"
            viewBox="0 0 512 512"
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m154.667969 213.332031h-138.667969c-8.832031 0-16-7.167969-16-16v-138.664062c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v122.664062h122.667969c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m256 512c-68.351562 0-132.628906-26.644531-180.96875-75.03125-6.253906-6.25-6.253906-16.382812 0-22.632812 6.269531-6.273438 16.402344-6.230469 22.632812 0 42.304688 42.347656 98.515626 65.664062 158.335938 65.664062 123.519531 0 224-100.480469 224-224s-100.480469-224-224-224c-105.855469 0-200.257812 71.148438-224.449219 169.171875-2.132812 8.597656-10.75 13.824219-19.371093 11.714844-8.574219-2.132813-13.800782-10.796875-11.710938-19.371094 27.691406-112.148437 135.148438-193.515625 255.53125-193.515625 141.164062 0 256 114.835938 256 256s-114.835938 256-256 256zm0 0"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss">
$primary-color: red;

.vue-pincode {
  min-width: 300px;
  margin: auto;
  padding: 0 15px;

  &__fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
    padding: 0 20px;
    margin: 20px auto 40px;
    column-gap: 8px;

    &--miss {
      animation: miss 0.8s ease-out 1;
    }

    span {
      height: 14px;
      width: 14px;
      box-shadow: inset 0 0 0 2px $primary-color;
      background-color: transparent;
      border-radius: 100%;
      position: relative;
      display: inline-block;
      text-align: center;
      transition: box-shadow 0.2s linear;

      &.active {
        box-shadow: inset 0 0 0 7px $primary-color;
      }
    }
  }

  &__numbers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      color: $primary-color;
      background: #ffffff;
      user-select: none;
      font-size: 24px;
      outline: none;
      cursor: pointer;
      border: 1px solid var(--chat-border-color);
      transition: all 0.2s linear;

      span {
        opacity: 1;
        transition: all 0.2s linear;
      }
    }
  }

  &--success {
    .vue-pincode__numbers {
      button {
        box-shadow:
          0 0 0 #bbcfda,
          0 0 0 #ffffff,
          inset 0 0 0 rgba(209, 217, 230, 0.35),
          inset 0 0 0 rgba(255, 255, 255, 0.3);
        transform: translateY(2px);
        color: $primary-color 52;

        span {
          opacity: 0.4;
        }
      }
    }

    .vue-pincode__fields {
      span {
        box-shadow: inset 0 0 0 7px #41b883;
      }
    }

    .vue-pincode__undo {
      svg {
        fill: $primary-color 52;
      }
    }
  }

  &__undo {
    span {
      transform: translateY(3px);
    }

    svg {
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
      transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1);
      fill: $primary-color;
    }

    &:hover {
      svg {
        transform: rotate(-135deg);
      }
    }
  }
}

@keyframes miss {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-25px, 0);
  }
  20% {
    transform: translate(25px, 0);
  }
  30% {
    transform: translate(-20px, 0);
  }
  40% {
    transform: translate(20px, 0);
  }
  50% {
    transform: translate(-10px, 0);
  }
  60% {
    transform: translate(10px, 0);
  }
  70% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>

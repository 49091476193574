import { IPostMessageData } from '/@src/interfaces'

export default class WindowHelper {
  public static postMessage = (action: string, payload: IPostMessageData) => {
    try {
      const data = JSON.stringify({
        action,
        timestamp: Math.floor(new Date().getTime() / 1000),
        ...(payload || {}),
      })

      if (!action) {
        return
      }

      try {
        window?.postMessage(
          {
            action,
            data,
          },
          '*'
        )
      } catch (error) {}

      try {
        window?.parent?.postMessage(
          {
            action,
            data,
          },
          '*'
        )
      } catch (error) {}
    } catch (error) {}
  }
}

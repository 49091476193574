<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { onMounted, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DateHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'close'])
const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
})

// Composable
const userSession = useUserSession()
const route = useRoute()

// Const data
const columns = {
  designation: {
    label: 'Designation',
    media: true,
    sortable: false,
    searchable: false,
    grow: false,
  },
  coordinate: {
    label: 'Coordinate',
    sortable: false,
    searchable: false,
    grow: false,
  },
  last_activity_at: {
    label: 'Last activity date',
    sortable: false,
    searchable: false,
    grow: false,
  },
  available_monthly_credits: {
    label: 'Available credits for the month',
    sortable: false,
    searchable: false,
    grow: true,
  },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const
const url = '/accounts'
const eventBus = useEventBus(url)
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid

// Reactive data
const isLoading = ref(false)
const modalFormProps: any = ref({ open: false, refresh: false })
const accounts = ref([])
const accountsAgentStatus = ref(false)
const exportsLimitation = ref()

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true
    exportsLimitation.value = userSession?.getPlanLimitations('exports')

    const [accountsResponse, widgetResponse] = await Promise.all([
      HttpHelper.get(url),
      HttpHelper.get(`/widgets/${widgetUid}`),
    ])

    accounts.value = accountsResponse
    accountsAgentStatus.value = widgetResponse?.computed_agents?.agents?.status
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  }

  if (widgetUid) {
    modalFormProps.value.widgetUid = widgetUid
  }

  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}

const onDownload = (selectedContact?: any) => {
  try {
    if (!exportsLimitation.value) {
      alert(
        `Your current plan does not include the option to export accounts. To access this feature, consider upgrading your plan.`
      )

      return
    }

    isLoading.value = true

    const element = document.createElement('a')
    element.href = URL.createObjectURL(
      new Blob([JSON.stringify(selectedContact ?? accounts.value)], {
        type: 'text/plain',
      })
    )
    element.download = selectedContact?.uid
      ? `account-${selectedContact?.uid}.json`
      : 'accounts.json'
    // Required for this to work in FireFox
    document.body.appendChild(element)
    element.click()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const refreshTable = () => {
  eventBus.emit(url)
}
</script>

<template>
  <FormWidgetAccount
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
    @close="closeModal"
  />

  <VMessage v-if="!accountsAgentStatus" color="info" class="mb-4">
    The Accounts AI agent is not enabled. Please go to the Automations tab and enable the AI agent.
    Private accounts allow your users to create accounts within the chatbot to save their chat
    history and limit their credit usage.
  </VMessage>

  <CustomTable
    v-else
    v-model:refresh="modalFormProps.refresh"
    v-model:override-loading="isLoading"
    :title="props.title"
    :columns="columns"
    :filters="props.filters"
    :url="url"
    @row-click="openFormModal"
  >
    <template #buttons>
      <VButtons align="right">
        <VButton color="primary" icon="ic:round-plus" @click.capture="openFormModal">
          Add account
        </VButton>

        <VButton
          :loading="isLoading"
          type="submit"
          color="primary"
          :icon="ICONS.actions.download"
          @click="onDownload(null)"
        >
          Export all
        </VButton>
      </VButtons>
    </template>

    <template #row="{ row }">
      <VFlexTableCell :column="columns.designation">
        <VAvatar :initials="row?.initials" size="small" class="mr-2" />

        <VTextEllipsis width="200px" class="item-name dark-text">
          {{ row?.designation }}
          <div class="light-text">
            {{ row?.company ?? row?.website ?? row?.job_title ?? row?.job_role }}
          </div>
        </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.coordinate">
        <span> {{ row?.email ?? row?.phone }}</span>
      </VFlexTableCell>

      <!--
      <VFlexTableCell :column="columns.created_at">
        <span> {{ DateHelper.formatDate(row?.created_at, 'MM-dd-yyyy') }}</span>
      </VFlexTableCell>
      -->

      <VFlexTableCell :column="columns.last_activity_at">
        <span> {{ DateHelper.formatDate(row?.last_activity_at, 'MM-dd-yyyy hh:mm') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.available_monthly_credits">
        <span> {{ row?.available_monthly_credits }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton :icon="ICONS.tables.open" class="pr-3 pl-3 mr-2"> Open </VButton>

        <CustomDropdown
          v-model:refresh="modalFormProps.refresh"
          v-model:isLoading="isLoading"
          title="Actions"
          :actions="['edit', 'delete']"
          :reload-on-delete="false"
          :uid="row?.uid"
          :url="url"
          @edit="openFormModal"
          @refresh="refreshTable"
        >
          <VButton
            class="dropdown-item"
            :icon="ICONS.actions.download"
            @click.prevent="
              () => {
                onDownload(row)
              }
            "
          >
            Export
          </VButton>
        </CustomDropdown>
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  subtitle: {
    type: String,
    default: '',
    required: false,
  },
})

const { y } = useWindowScroll()

const isScrolling = computed(() => {
  return y.value > 30
})
</script>

<template>
  <div class="custom-box is-form">
    <!--<div class="form-head stuck-header" :class="[isScrolling && 'is-stuck']">-->
    <div class="form-head" v-if="props.title">
      <div class="form-head-inner">
        <div class="left">
          <div class="title is-6">{{ props.title }}</div>
          <p v-if="props.subtitle" class="subtitle">{{ props.subtitle }}</p>
        </div>

        <div v-if="$slots['buttons']" class="right">
          <VButtons>
            <slot name="buttons"></slot>
          </VButtons>
        </div>
      </div>
    </div>

    <div class="form-body">
      <div class="columns">
        <div class="column is-12 p-3">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.column.is-12:has(> .columns) {
  padding: 0;
}
</style>

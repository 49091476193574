<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
	modelValue: {
		type: [String, Object],
		required: true,
		default: undefined,
	},
	context: {
		type: Object,
		default: () => {},
		required: true,
	},
	placeholder: {
		type: String,
		required: false,
		default: '',
	},
})

const emit = defineEmits(['update:modelValue'])

// Computed
const stringValue = computed(() => {
	const value = props?.context?.value ?? null

	return value?.formatted_address ?? ''
})

// Functions
const handleInput = (value: any) => {
	const _value = value?.target?.value ?? value

	const address = {
		formatted_address: null,
		street_number: null,
		route: null,
		locality: null,
		administrative_area_level_2: null,
		administrative_area_level_1: null,
		country: null,
		postal_code: null,
	}

	address.formatted_address = _value?.formatted_address ?? _value

	if (_value?.address_components) {
		address.street_number = _value?.address_components.find(
			(component: any) => component.types[0] === 'street_number'
		)?.long_name

		address.route = _value?.address_components.find(
			(component: any) => component.types[0] === 'route'
		)?.long_name

		address.locality = _value?.address_components.find(
			(component: any) => component.types[0] === 'locality'
		)?.long_name

		address.administrative_area_level_2 = _value?.address_components.find(
			(component: any) => component.types[0] === 'administrative_area_level_2'
		)?.long_name

		address.administrative_area_level_1 = _value?.address_components.find(
			(component: any) => component.types[0] === 'administrative_area_level_1'
		)?.long_name

		address.country = _value?.address_components.find(
			(component: any) => component.types[0] === 'country'
		)?.long_name

		address.postal_code = _value?.address_components.find(
			(component: any) => component.types[0] === 'postal_code'
		)?.long_name
	}

	emit('update:modelValue', address)
	props.context.node.input(address)
}
</script>

<template>
	<GMapAutocomplete
		:value="stringValue"
		type="search"
		class="formkit-input"
		:placeholder="placeholder"
		:select-first-on-enter="true"
		:options="{}"
		@place_changed="
			(e) => {
				handleInput(e)
			}
		"
		@input="
			(e) => {
				handleInput(e)
			}
		"
	/>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Const data
const title = 'Subscription'

// Reactive data
const isLoading = ref(true)

// Composable
const router = useRouter()
const route = useRoute()
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    const planId: any = route.params?.plan_id ?? null
    const quantity: any = route.params?.quantity ?? 1

    if (planId) {
      const { url } = await HttpHelper.get(
        `/subscriptions/create-checkout-session/${planId}/${quantity}`
      )

      window.location.href = url
    } else {
      await router.push({
        path: '/chatbots',
      })
    }
  } catch (error) {
    console.error(error)
  } finally {
    // isLoading.value = false
  }
})
</script>

<template>
  <SideblockLayout>
    <VCardAdvanced>
      <template #header-left>
        <h1 class="title is-4 is-narrow">Redirection en cours vers Stripe</h1>
      </template>
      <template #header-right>
        <div class="is-flex is-justify-content-flex-end"></div>
      </template>
      <template #content>
        <VLoader v-if="isLoading" />
      </template>
    </VCardAdvanced>
  </SideblockLayout>
</template>

<template>
  <RouterView v-slot="{ Component }" :key="$route.fullPath">
    <template v-if="Component">
      <Transition name="fade-slow" mode="out-in">
        <KeepAlive>
          <Suspense>
            <component :is="Component"></component>
          </Suspense>
        </KeepAlive>
      </Transition>
    </template>
  </RouterView>
</template>

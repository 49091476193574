<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'
import { DateHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Reactive data
const isLoading = ref(true)
const userMessageStylingSettings: any = ref({})

// Lifecycle hooks
onBeforeMount(() => {
  userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
})

onMounted(async () => {
  try {
    isLoading.value = true
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const selectConversation = async (uid: string) => {
  await widgetStore.loadChat(uid)
}

const onDelete = async (uid: string, index: number) => {
  try {
    const result = confirm('Are you sure you want to delete this item?')

    if (result) {
      await widgetStore.deleteChat(uid, index)

      if (!Object.keys(widgetStore.getChat()).length) {
        if (widgetStore.getWidget()?.uid) {
          await widgetStore.createChat()
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const addConversation = async () => {
  try {
    isLoading.value = true

    await widgetStore.createChat()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="chat-sidebar chat-bg-color">
    <div class="inner chat-bg-color">
      <div class="is-new-conversation">
        <VButton
          :disabled="isLoading"
          class="w-100"
          color="primary"
          @click.prevent.stop="addConversation"
        >
          <VIcon
            icon="ic:round-add"
            class="chat-icons-color chat-radius-rounded"
            :style="{
              'vertical-align': 'middle',
              'background': userMessageStylingSettings?.background + '!important',
              'color': userMessageStylingSettings?.color + '!important',
            }"
          />
        </VButton>
      </div>

      <VLoader v-if="isLoading" class="h-100 chat-bg-color" :translucent="true" :opaque="true" />
      <ul id="conversations-list" class="animated preFadeInUp fadeInUp">
        <li
          v-for="(chat, index) in widgetStore?.state?.chats"
          :key="chat.uid"
          :class="[widgetStore.getChat()?.uid === chat?.uid && 'is-active']"
          tabindex="0"
          role="button"
          @click.prevent.stop="selectConversation(chat.uid)"
        >
          <div class="recent-user">
            <!--
              <div class="user-container">
                <VIcon :is-clickable="true" :icon="ICONS.pages.chats" class="chat-icons-color" />
              </div>
            -->

            <div class="recipient-meta w-100">
              <span>{{ chat.label }}</span>
              <span>{{
                DateHelper.formatDate(chat.updated_at ?? chat.created_at, 'MM-dd-yyyy hh:mm')
              }}</span>
              <!--
              <span style="font-size: 7px">
                <b>{{ chat.uid }}</b>
              </span>
              -->
            </div>

            <div v-if="widgetStore.getChat()?.uid === chat?.uid" class="mr-2">
              <VIcon
                :icon="ICONS.actions.delete"
                color="#e62864"
                :style="{
                  height: '1rem',
                  width: '1rem',
                }"
                @click.prevent.stop="onDelete(chat?.uid, index)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn, useResizeObserver } from '@vueuse/core'
import { computed, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ChatbotHelper, CookiesHelper, PageHelper, PwaHelper, UrlHelper } from '/@src/helpers'
import { IQueryParameters, ISettings } from '/@src/interfaces'
import fonts from '/@src/resources/files/fonts.json'
import { useWidget } from '/@src/stores'

const props = defineProps({
  widgetUid: {
    type: String,
    default: '',
    required: false,
  },
  height: {
    type: String,
    default: '',
    required: false,
  },
  chatUid: {
    type: String,
    default: '',
    required: false,
  },
  brandingPreview: {
    type: Object as PropType<ISettings>,
    default: undefined,
  },
  isInbox: {
    type: Boolean,
    required: false,
    default: false,
  },
})

// Composable
const route = useRoute()
const widgetStore = useWidget()

// Const data
const VITE_EMBED_URL = import.meta.env.VITE_EMBED_URL
const VITE_WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL
const { share, botBgColor, debug }: IQueryParameters = UrlHelper.getWidgetQueryParams()

// Reactive data
const isLoading = ref<boolean>(true)
const welcomeScreenSettings: any = ref({})
const windowStylingSettings = ref<Record<string, any>>({})
const userMessageStylingSettings = ref<Record<string, any>>({})
const assistantMessageStylingSettings = ref<Record<string, any>>({})
const domainLimitation = ref<string | undefined>(undefined)
const mobileAppLimitation = ref<string | undefined>(undefined)
const agentsLimitation = ref<Array<string>>([])
const refMyChatbot = ref(null)
const refChatBody = ref(null)
const refChatBottom = ref(null)
const dynamicBodyHeight = ref(280)

// Computed
const computedWidgetUid = computed(() =>
  Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid || props.widgetUid
)

const computedChatUid = computed(() => {
  if (props?.chatUid) {
    return props.chatUid
  }

  if (share) {
    return share
  }

  const cookieChatUid = CookiesHelper.get(`chat_${computedWidgetUid.value}`)

  if (cookieChatUid) {
    return cookieChatUid
  }

  return ''
})

const isAccountsAgentEnabled = computed(
  () => agentsLimitation.value?.includes('accounts') && widgetStore.state.isWidgetLoginRequired
)

// Lifecycle hooks
onMounted(async () => {
  debouncedUpdateDynamicMessagesHeight()

  useResizeObserver(refMyChatbot, debouncedUpdateDynamicMessagesHeight)
  useResizeObserver(refChatBody, debouncedUpdateDynamicMessagesHeight)
  useResizeObserver(refChatBottom, debouncedUpdateDynamicMessagesHeight)

  window.addEventListener('message', handleIframeMessage)

  try {
    if (botBgColor) {
      document.body.style.backgroundColor = botBgColor
    }

    isLoading.value = true

    widgetStore.setWidgetState('isWidgetDisabled', true)

    await Promise.all([
      widgetStore.loadWidget(computedWidgetUid.value),
      computedChatUid.value
        ? widgetStore.loadChat(computedChatUid.value)
        : widgetStore.createChat(computedWidgetUid.value),
    ])

    domainLimitation.value = widgetStore.getPlanLimitations('domain')
    agentsLimitation.value = widgetStore.getPlanLimitations('agents')
    mobileAppLimitation.value = widgetStore.getPlanLimitations('mobile_app')

    welcomeScreenSettings.value = widgetStore.getWelcomeSettings()
    windowStylingSettings.value = widgetStore.getWindowStylingSettings()
    userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
    assistantMessageStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()

    // Login account
    if (
      agentsLimitation.value?.includes('accounts') &&
      widgetStore.getAccountsAgent()?.status &&
      widgetStore.getAccountsAgent()?.is_open
    ) {
      widgetStore.setWidgetState('isWidgetLoginRequired', true)
      await widgetStore.loginAccount(null)
    }

    checkDomainForAbuse()
    setupIframeAttributes()
    setupPage()
    setupBranding()

    await widgetStore.loadSavedChats()
    await widgetStore.autoStartAgents()
  } catch (error: any) {
    /*
    if (error?.status === 400) {
      window.location.href = VITE_WEBSITE_URL
    }
    */

    console.error(error)
  } finally {
    isLoading.value = false
  }
})

onUnmounted(() => {
  window.removeEventListener('message', handleIframeMessage)
  window.removeEventListener('resize', debouncedUpdateDynamicMessagesHeight)
})

// Functions
const updateDynamicMessagesHeight = async () => {
  try {
    const myChatbot = document.querySelector('.my-chatbot') as HTMLElement

    if (!myChatbot) {
      return
    }

    const fullHeight = myChatbot?.clientHeight || 0
    const chatNavbarHeight = document.querySelector('.chat-navbar')?.clientHeight || 0
    const chatBottomHeight = document.querySelector('.chat-bottom')?.clientHeight || 0
    const newHeight = fullHeight - (chatBottomHeight + chatNavbarHeight)

    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)

    if (newHeight && dynamicBodyHeight.value !== newHeight) {
      dynamicBodyHeight.value = newHeight

      // Force reflow
      myChatbot.style.setProperty('--dynamic-body-height', `${dynamicBodyHeight.value}px`)

      // Trigger reflow
      void myChatbot.offsetHeight
    }
  } catch (error) {
    console.error(error)
  }
}

const debouncedUpdateDynamicMessagesHeight = useDebounceFn(updateDynamicMessagesHeight, 100)

const checkDomainForAbuse = () => {
  const domain = ChatbotHelper.getDomain()
  const allowedDomains = ChatbotHelper.allowedDomains

  if (!domainLimitation.value && !allowedDomains.includes(window.location.origin)) {
    window.location.href = window.location.href.replace(domain, VITE_EMBED_URL)
  }
}

const setupPage = () => {
  // PWA
  if (widgetStore.getWidget() && mobileAppLimitation.value) {
    PwaHelper.generateManifest(widgetStore.getWidget())
  }

  // Start onboarding
  /*
  if (userSession?.user && !userSession?.user?.finished_onboarding_widget) {
    ProductTourHelper.chatbotEmbed()
  }
  */
}

const setupBranding = () => {
  const myChatbot = document.querySelector('.my-chatbot') as HTMLElement

  // Set colors
  myChatbot?.style?.setProperty('--chat-bg-color', windowStylingSettings.value?.background)
  myChatbot?.style?.setProperty('--chat-text-color', windowStylingSettings.value?.color)
  myChatbot?.style?.setProperty('--chat-icons-color', windowStylingSettings.value?.icons)
  myChatbot?.style?.setProperty('--chat-border-color', windowStylingSettings.value?.border)
  myChatbot?.style?.setProperty(
    '--chat-other-msg-bg-color',
    assistantMessageStylingSettings.value?.background
  )
  myChatbot?.style?.setProperty(
    '--chat-other-msg-text-color',
    assistantMessageStylingSettings.value?.color
  )
  myChatbot?.style?.setProperty(
    '--chat-self-msg-bg-color',
    userMessageStylingSettings.value?.background
  )
  myChatbot?.style?.setProperty(
    '--chat-self-msg-text-color',
    userMessageStylingSettings.value?.color
  )

  // Set fonts
  if (windowStylingSettings.value?.font) {
    const foundFont = fonts.find((font) => font.value === windowStylingSettings.value?.font)

    if (foundFont && foundFont?.label && foundFont?.family) {
      loadFont(foundFont?.label)
      myChatbot?.style?.setProperty('--chat-font-family', foundFont?.family)
    }
  }

  /*
  if (widgetStore.getWidget()?.custom_css) {
    PageHelper.injectContent(
      `<style type="text/css">${widgetStore.getWidget()?.custom_css}</style>`,
      'head'
    )
  }
  */

  if (widgetStore.getWidget()?.code_head) {
    PageHelper.injectContent(widgetStore.getWidget()?.code_head, 'head')
  }

  if (widgetStore.getWidget()?.code_body) {
    PageHelper.injectContent(widgetStore.getWidget()?.code_body, 'body')
  }
}

const loadFont = (fontName: string) => {
  const link = document.createElement('link')
  link.href = `https://fonts.googleapis.com/css2?family=${fontName}&display=swap`
  link.rel = 'stylesheet'
  document.head.appendChild(link)
}

const handleIframeMessage = ({
  data: { action, text },
}: {
  data: { action: string; text?: string; data?: any }
}) => {
  if (!action) {
    return
  }

  const actionsMap = {
    // send_dynamic_user: () => widgetStore.setDynamicUser(data),
    send_dynamic_context: () => text && widgetStore.setWidgetState('dynamicContext', text),
    send_dynamic_question: () => text && widgetStore.setWidgetState('dynamicQuestion', text),
    send_dynamic_system_behavior: () =>
      text && widgetStore.setWidgetState('dynamicSystemBehavior', text),
  }

  actionsMap[action as keyof typeof actionsMap]?.()
}

const setupIframeAttributes = () => {
  const parentElement = window.frameElement

  if (!parentElement) {
    return
  }

  const attributes = {
    allow:
      'accelerometer;autoplay;camera;display-capture;encrypted-media;fullscreen;gamepad;geolocation;gyroscope;hid;identity-credentials-get;idle-detection;local-fonts;magnetometer;microphone;midi;otp-credentials;payment;picture-in-picture;publickey-credentials-get;screen-wake-lock;serial;storage-access;usb;window-management;xr-spatial-tracking',
    sandbox:
      'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation',
    allow_list: '*',
  }

  Object.entries(attributes).forEach(([key, value]) => parentElement.setAttribute(key, value))
}

// Watchers
watch(
  () => props.brandingPreview,
  () => {
    if (props.brandingPreview && Object.keys(props.brandingPreview).length) {
      widgetStore.setWidgetState('brandingPreview', {
        ...widgetStore.getWidget()?.computed_settings,
        ...props.brandingPreview,
      })
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => isLoading.value,
  (newVal) => {
    document.body.classList.toggle('is-loading', newVal)
  },
  { immediate: true }
)

watch(
  () => widgetStore.state.dynamicUser,
  () => {
    if (Object.keys(widgetStore.state.dynamicUser).length && widgetStore.getChat()?.uid) {
      widgetStore.appendMetadata({
        model: 'chats',
        uid: widgetStore.getChat()?.uid,
        metadata: { user: widgetStore.state.dynamicUser },
      })
    }
  },
  { immediate: true }
)

watch(
  [refMyChatbot, refChatBody, refChatBottom],
  () => {
    debouncedUpdateDynamicMessagesHeight()
  },
  { immediate: true, deep: true }
)
</script>

<template>
  <VLoader
    v-if="isLoading"
    :style="{
      height: props.height ? props.height : '100%',
      minHeight: props.height ? props.height : '100%',
      maxHeight: props.height ? props.height : '100%',
      backgroundColor: botBgColor || '',
    }"
    v-bind="$attrs"
  />

  <Transition v-show="!isLoading" name="fade-scale" mode="out-in">
    <div
      ref="refMyChatbot"
      v-bind="$attrs"
      class="my-chatbot chat-bg-color"
      :style="{
        height: props.height ? props.height : '100%',
      }"
    >
      <template v-if="!isLoading">
        <div id="teleport-modals"></div>

        <!-- Navbar -->
        <ChatNavbar />

        <!-- Sidebar -->
        <ChatSidebar v-if="widgetStore.state.sidebarStatus" />

        <!-- Body -->
        <div ref="refChatBody" class="chat-body" :style="{ height: `${dynamicBodyHeight}px` }">
          <!-- Password -->
          <ChatPassword
            v-if="!props.isInbox && widgetStore.state.isWidgetLocked"
            class="no-messages"
          />

          <!-- Account Form -->
          <ChatAccountForm
            v-else-if="!props.isInbox && isAccountsAgentEnabled"
            class="no-messages"
          />

          <!-- Loader -->
          <VLoader
            v-else-if="!Object.keys(widgetStore.getChat()).length"
            :style="{
              height: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              backgroundColor: botBgColor || '',
            }"
            v-bind="$attrs"
          />

          <!-- Welcome Screen -->
          <ChatWelcome
            class="no-messages"
            v-else-if="
              welcomeScreenSettings?.status && !Object.keys(widgetStore.state.messages).length
            "
          />

          <!-- Messages -->
          <div
            v-else
            v-chat-scroll="{
              enabled: true,
              always: true,
              smooth: true,
              scrollonremoved: true,
              smoothonremoved: true,
            }"
            class="messages"
          >
            <ChatMessage
              v-for="[index, message] of Object.entries(widgetStore.state.messages)"
              :key="message?.uid"
              :index="Number(index)"
              :message="message"
              :is-last-message="
                Number(index) === Object.keys(widgetStore.state.messages).length - 1
              "
            />
          </div>
        </div>

        <div
          v-if="!widgetStore.state.isWidgetLocked && !widgetStore.state.isWidgetLoginRequired"
          ref="refChatBottom"
          class="chat-bottom"
        >
          <div class="separator-horizontal"></div>

          <ChatAgents />
          <ChatTextarea />
          <!--<MobileMenu />-->
          <ChatCopyright />
        </div>
      </template>
    </div>
  </Transition>
</template>

<style lang="scss">
html,
body,
.app-wrapper {
  height: 100%;
  min-height: 100%;
  margin: 0;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .my-chatbot {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}

.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: all 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade-scale-enter-from,
.fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

@import '/@src/scss/chatbot';
</style>

<script setup lang="ts">
export interface VTypingProps {
  color?: string
}

const props = withDefaults(defineProps<VTypingProps>(), {
  color: '#fff',
})
</script>

<template>
  <div class="typing-indicator">
    <span
      :style="{
        background: props?.color,
      }"
    ></span>
    <span
      :style="{
        background: props?.color,
      }"
    ></span>
    <span
      :style="{
        background: props?.color,
      }"
    ></span>
  </div>
</template>

<style lang="scss">
.typing-indicator {
  display: inline-block;

  span {
    height: 15px;
    width: 15px;
    float: left;
    margin: 0 1px;
    background-color: #fff;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * 0.3333s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
</style>

<script setup lang="ts">
import { PropType, ref, Ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ICONS } from '/@src/resources/files/constant'

interface IProps {
  open: boolean
  title: string
  message: string
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: IProps): void
}>()

const props = defineProps({
  modelValue: {
    type: Object as PropType<IProps>,
    required: true,
  },
})

// Composable
const { t } = useI18n()
const router = useRouter()

// Reactive data
const open: Ref<boolean> = ref(false)

// Watch
watch(open, () => {
  emit('update:modelValue', {
    ...props.modelValue,
    open: open.value,
  })
})

// Function
const close = () => {
  emit('update:modelValue', {
    ...props.modelValue,
    open: false,
  })
}

const redirect = async () => {
  await router.push({
    path: `/subscriptions`,
  })
}
</script>

<template>
  <CustomModal
    size="large"
    :open="modelValue.open"
    :title="modelValue.title"
    :override-modal="false"
    :narrow="true"
    :noscroll="false"
    :noclose="false"
    :disable-footer="true"
    @close="close"
  >
    <div class="mb-5">{{ modelValue.message }}</div>

    <VButton
      class="w-100 p-0"
      color="primary"
      :icon="ICONS.pages.subscriptions"
      @click.capture="redirect"
    >
      Manage subscriptions
    </VButton>
  </CustomModal>
</template>

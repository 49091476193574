<script setup lang="ts">
import { computed } from 'vue'

export type VProgressSize = 'tiny' | 'smaller' | 'small'
export type VProgressColor = 'primary' | 'success' | 'info' | 'warning' | 'danger'
export interface VProgressProps {
  value?: number
  max?: number
  size?: VProgressSize
  color?: VProgressColor
}

const props = withDefaults(defineProps<VProgressProps>(), {
  value: undefined,
  max: 100,
  size: undefined,
  color: 'primary',
})

const progressPercentage = computed(() => {
  if (props.value === undefined) {
    return 0
  }

  return `${Math.round((props.value / props.max) * 100)}%`
})
</script>

<template>
  <div class="progress-wrapper">
    <progress
      :class="[props.size && `is-${props.size}`, props.color && `is-${props.color}`]"
      class="progress"
      :value="props.value"
      :max="props.max"
    >
      {{ progressPercentage }}
    </progress>
    <span class="progress-text">{{ progressPercentage }}</span>
  </div>
</template>

<style lang="scss">
.progress-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 0;

  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--dark-text);
    font-size: 0.75rem;
    font-weight: bold;
  }

  .progress {
    widows: 100%;
    margin-bottom: 0;

    &::-webkit-progress-value {
      border-radius: 50px;
    }

    &::-moz-progress-bar {
      border-radius: 50px;
    }

    &::-ms-fill {
      border-radius: 50px;
    }

    &.is-smaller {
      height: 0.5rem !important;
    }

    &.is-tiny {
      height: 0.35rem !important;
    }
  }
}
</style>

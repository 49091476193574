<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, Ref, ref } from 'vue'
import { IAddon, IPlan, ITab } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useApp, useViewWrapper } from '/@src/stores'

// Composable
const viewWrapper = useViewWrapper()
const app = useApp()

// Const data
const title = 'Subscriptions'
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })

// Reactive data
const tabs = ref<ITab[]>([
  { label: 'Yearly', value: 'yearly', icon: 'mage:trophy-star' },
  {
    label: 'Monthly',
    value: 'monthly',
    icon: 'streamline:interface-calendar-date-month-thirty-thirty-calendar-date-week-day-month',
  },
])
const isLoading = ref(true)
const selected: Ref<string> = ref(tabs.value[0]?.value)
const modalCheckoutProps = ref({ clientSecret: null, open: false, refresh: false })
const modalCancelProps = ref({ open: false, refresh: false })
const plans = ref<IPlan[] | undefined>()
const addons = ref<{ credits: IAddon; branding: IAddon; domain: IAddon } | undefined>()

// Computed
const computedPlans = computed(() => {
  if (!plans.value) {
    return []
  }

  const _plans = Object.values(plans.value)

  return _plans
})

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    const plansResponse = await app.loadPlans('all')

    plans.value = plansResponse?.plans
    addons.value = plansResponse?.addons
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const openSubscribeModal = (params: { plan: IPlan; period: string; quantity: number }) => {
  modalCheckoutProps.value = { ...params, open: true }
}

const openCancelModal = (params: { plan: IPlan; period: string; quantity: number }) => {
  modalCancelProps.value = { ...params, open: true }
}

const redirect = () => {
  window.location.href = '/settings?page=subscriptions'
}

const closeModal = (refresh: boolean) => {
  if (refresh) {
    modalCheckoutProps.value.open = false
    modalCancelProps.value.open = false
    redirect()
  }
}
</script>

<template>
  <CheckoutModal
    v-if="modalCheckoutProps?.open"
    v-model:modalProps="modalCheckoutProps"
    @close="closeModal"
  />

  <CancelModal
    v-if="modalCancelProps?.open"
    v-model:modalProps="modalCancelProps"
    @close="closeModal"
  />

  <VLoader v-if="isLoading" card="smooth" />

  <template v-else>
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title is-5">{{ title }}</h1>
      </div>
      <div class="column has-text-right"><WidgetPlan /></div>
    </div>

    <VTabs
      v-model:selected="selected"
      :tabs="tabs"
      :vertical="true"
      :data="{ plans, addons }"
      :alwaysOpen="true"
      class="p-0"
    >
      <template #shared-top>
        <VMessage v-if="selected === 'monthly'" class="has-text-centered" color="warning">
          <b>Save up to 25% with a yearly subscription!</b>
        </VMessage>

        <VMessage class="has-text-centered" color="info">
          Check out the full pricing table at
          <b>
            <a href="https://insertchat.com/pricing/" target="_blank">
              https://insertchat.com/pricing
            </a>
          </b>
        </VMessage>
      </template>

      <template #tab>
        <h1 class="title is-3 mt-5 has-text-centered">Subscriptions</h1>
        <div class="columns">
          <div
            v-for="(plan, index) of computedPlans"
            :key="index"
            class="column"
            :style="{ 'min-height': '100%' }"
          >
            <PricingCard
              mode="business"
              :plan="plan"
              :period="selected"
              @subscribe="openSubscribeModal"
              @cancel="openCancelModal"
            />
          </div>
        </div>

        <h1 class="title is-3 mt-5 has-text-centered">Add-ons</h1>
        <div class="columns is-multiline mt-5 is-centered">
          <div
            v-for="[name, addon] of Object.entries(addons)"
            :key="name"
            class="column is-3"
            :style="{ 'min-height': '100%' }"
          >
            <AddonCard
              mode="business"
              :name="name"
              :addon="addon"
              :period="selected"
              @subscribe="openSubscribeModal"
              @cancel="openCancelModal"
            />
          </div>
        </div>
      </template>
    </VTabs>
  </template>
</template>

<route lang="yaml">
meta:
  requiresAuth: false
</route>

<script setup lang="ts"></script>

<template>
  <LandingLayout :iframe="true">
    <RouterView v-bind="$attrs" />
  </LandingLayout>
</template>

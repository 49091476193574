<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { ref } from 'vue'
import { DateHelper, StringHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Composable
const viewWrapper = useViewWrapper()

// Reactive data
const isLoading = ref(false)

// Const data
const title = 'Active Subscriptions'
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })
const columns = {
  label: {
    label: 'Label',
    sortable: false,
    searchable: false,
    grow: false,
  },
  interval: {
    label: 'Interval',
    sortable: false,
    searchable: false,
    grow: false,
  },
  amount: {
    label: 'Amount',
    sortable: false,
    searchable: false,
    grow: false,
  },
  period: { label: 'Period', sortable: false, searchable: false, grow: false },
} as const
const url = '/subscriptions/get-subscriptions'
</script>

<template>
  <CustomTable
    v-model:override-loading="isLoading"
    :refresh="false"
    :columns="columns"
    :url="url"
    :enable-wrapper-class="true"
  >
    <template #row="{ row }">
      <VFlexTableCell :column="columns.label">
        <div>
          <VTextEllipsis width="200px" class="item-name dark-text">
            {{ row?.app_plan?.title ?? '' }}
          </VTextEllipsis>

          <VTextEllipsis class="light-text">
            {{ row?.quantity > 1 ? row?.quantity : '' }}
          </VTextEllipsis>
        </div>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.interval">
        <VTextEllipsis width="200px">
          {{ StringHelper.capitalizeFirstLetter(row?.plan?.interval) ?? '' }}
        </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.amount">
        <VTextEllipsis width="200px"> ${{ row?.plan?.amount / 100 ?? '' }} </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.period">
        {{
          DateHelper.formatDate(DateHelper.timestampToDate(row?.current_period_start), 'MM-dd-yyyy')
        }}
        -
        {{
          DateHelper.formatDate(DateHelper.timestampToDate(row?.current_period_end), 'MM-dd-yyyy')
        }}
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>

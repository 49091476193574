import { definePlugin } from '/@src/app'
import VueGoogleMaps from '@fawmi/vue-google-maps'

export default definePlugin(({ app }) => {
  app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_API,
      libraries: 'places',
    },
  })
})

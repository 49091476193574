<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { ChatbotHelper } from '/@src/helpers'
import Embed from '/@src/pages/embed/:uid.vue'
import { ICONS } from '/@src/resources/files/constant'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Const data
const domain = ChatbotHelper.getDomain()
const widget = ref(props.widget)

// Computed
const embedUrl = computed(() => {
  return `${domain}/embed/${props?.widget?.uid}`
})

// Lifecycle hooks
onMounted(() => {
  if (!widget.value?.uid) {
    return
  }
})

// Function
const onClick = () => {
  window.open(embedUrl.value, '_blank')
}
</script>

<template>
  <div class="columns is-vcentered">
    <div class="column is-6"></div>
    <div class="column is-6">
      <VButtons align="right">
        <VButton type="submit" color="primary" :icon="ICONS.tables.open" @click="onClick">
          Open full page
        </VButton>
      </VButtons>
    </div>
  </div>

  <VMessage v-if="widget?.hide_widget" color="info">
    Your chatbot is hidden, make it visible to see the preview.
  </VMessage>
  <Embed v-else class="radius-large" :uid="widget?.uid" height="600px" />
</template>

export default class ClarityHelper {
  public static config(user: any) {
    try {
      const isApiAvailable = typeof window?.clarity === 'function'

      if (!isApiAvailable) {
        console.log('Clarity not available')

        return
      }

      window?.clarity('consent')
      window?.clarity('identify', user?.uid)
      window?.clarity('set', 'user_id', user?.uid)
    } catch (error) {
      console.error(error)
    }
  }

  public static push(event: string) {
    try {
      const isApiAvailable = typeof window?.clarity === 'function'

      if (!isApiAvailable) {
        console.log('Clarity not available')

        return
      }

      window?.clarity('consent')
      window?.clarity('event', event)
    } catch (error) {
      console.error(error)
    }
  }
}

import { definePlugin } from '/@src/app'
import { DateHelper } from '/@src/helpers'

export default definePlugin(({ app }) => {
  app.config.globalProperties.$filters = {
    formatDate(value: any) {
      return DateHelper.formatDate(value)
    },
  }
})

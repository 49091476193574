<script setup lang="ts">
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import { computed, nextTick, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const ICreateTracePayload = [
  {
    key: 'trigger',
    type: 'chatbot | agent',
  },
  {
    key: 'action',
    type: 'string',
  },
  { key: 'app_uid', type: 'string' },
  { key: 'user_uid', type: 'string | null' },
  { key: 'widget_uid', type: 'string | null' },
  { key: 'chat_uid', type: 'string | null' },
  { key: 'message_uid', type: 'string | null' },
  { key: 'contact_uid', type: 'string | null' },
  { key: 'feedback_uid', type: 'string | null' },
  { key: 'provider_uid', type: 'string | null' },
  { key: 'account_uid', type: 'string | null' },
  { key: 'label', type: 'string | null' },
  { key: 'description', type: 'string | null' },
  { key: 'status', type: 'string | null' },
  { key: 'credits', type: 'number' },
  { key: 'request_method', type: 'object | null' },
  { key: 'request_url', type: 'object | null' },
  { key: 'request_params', type: 'object | null' },
  { key: 'request_qs', type: 'object | null' },
  { key: 'request_body', type: 'object | null' },
  { key: 'ip', type: 'string | null' },
  { key: 'metadata', type: 'object | null' },
  { key: 'location', type: 'object | null' },
]

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  plans: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const webhooksLimitation = ref()

// Const data
const formId = 'formWebhooks'
const events = [
  {
    trigger: 'chatbot',
    action: 'chatbot_load',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_open',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_chat_session_create',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_chat_session_load',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_chat_session_delete',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_message_create',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_message_listen',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_message_transcribe',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_answer_create',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_account_register',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_account_login',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_file_create',
    description: '',
  },
  {
    trigger: 'chatbot',
    action: 'chatbot_feedback_create',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_start',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_end',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_mark_as_resolved',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_request_human',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_perplexity_search',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_capture_lead',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_transcribe',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_book_calendar_meeting',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_custom_http',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_check_order_status',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_track_shipment',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_view_product_details',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_list_available_products',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_check_refund_status',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_view_store_policies',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_update_customer_profile',
    description: '',
  },
  {
    trigger: 'agent',
    action: 'agent_success_shopify_request_return',
    description: '',
  },
]

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true
    webhooksLimitation.value = userSession.getPlanLimitations('webhooks')

    const widgetResponse = await HttpHelper.get(`/widgets/${props?.widget?.uid}`)

    formData.value = {
      ...widgetResponse,
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { webhook_endpoint } = fields

    await HttpHelper.post('/widgets', props?.widget?.uid, {
      webhook_endpoint,
    })

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const formatPayload = (payload: object) => {
  const formatted = JSON.stringify(payload, null, 2)

  nextTick(() => {
    Prism.highlightAll()
  })

  return formatted?.trim()
}
</script>

<template>
  <FormKit
    :id="formId"
    v-model="formData"
    type="form"
    :config="{ validationVisibility: 'live' }"
    :disabled="isLoading"
    :actions="false"
    :classes="{
      form: 'h-100 mb-4',
    }"
    :preserve="true"
    :preserve-errors="true"
    @submit="onSubmit"
  >
    <CustomForm title="Webhooks" subtitle="">
      <template #buttons>
        <VButton
          :disabled="isFormDisabled"
          :loading="isLoading"
          type="submit"
          color="primary"
          icon="ic:round-check"
          @click="triggerSubmit"
        >
          Save Changes
        </VButton>
      </template>

      <template #body>
        <MarketingMessage
          v-if="!webhooksLimitation"
          :plan="props.plans.business"
          cta="Purchase Now"
        >
          Upgrade your subscription to unlock this feature!
        </MarketingMessage>

        <FormKit
          type="url"
          name="webhook_endpoint"
          label="Server Endpoint"
          validation="trim|url"
          :disabled="isLoading"
          placeholder="We will send POST requests to this endpoint."
        />
      </template>
    </CustomForm>
  </FormKit>

  <CustomCollapse with-chevron>
    <template #collapse-item-summary> Webhook Events </template>
    <template #collapse-item-content>
      <div class="table-container">
        <table class="table w-100">
          <thead>
            <tr>
              <th>Method</th>
              <th>Trigger</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="event.action">
              <td>POST</td>
              <td>{{ event.trigger }}</td>
              <td>{{ event.action }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </CustomCollapse>

  <CustomCollapse with-chevron>
    <template #collapse-item-summary> Payload Interface </template>
    <template #collapse-item-content>
      <pre
        class="code-block"
      ><code class="language-typescript">{{ formatPayload(ICreateTracePayload)?.trim() }}</code></pre>
    </template>
  </CustomCollapse>
</template>

<style>
.code-block {
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  overflow-x: auto;
  font-family: 'Fira Code', monospace;
}
</style>

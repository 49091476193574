<script setup lang="ts">
import { onMounted, PropType, ref, watch } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useRoute } from 'vue-router'
import { useNotyf } from '/@src/composable/useNotyf'
import {
  ChatbotHelper,
  CookiesHelper,
  DateHelper,
  HttpHelper,
  PageHelper,
  ResponsiveHelper,
} from '/@src/helpers'
import { IChat, IFilter } from '/@src/interfaces'
import Embed from '/@src/pages/embed/:uid.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'close'])

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
})

// Composable
const userSession = useUserSession()
const { toClipboard } = useClipboard()
const notyf = useNotyf()
const route = useRoute()

// Const data
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid
const fixedHeight = '750px !important'
const filtersOptions = [
  { label: '🎉 Is Resolved', value: { key: 'is_resolve', operator: '=', value: true } },
  { label: '🚫 Not Resolved', value: { key: 'is_resolve', operator: '=', value: false } },
  { label: '💌 Emailed', value: { key: 'is_email', operator: '=', value: true } },
  { label: '🚫 Not Emailed', value: { key: 'is_email', operator: '=', value: false } },
  { label: '💡 Has Feedback', value: { key: 'has_feedback', operator: '=', value: true } },
  { label: '🚫 No Feedback', value: { key: 'has_feedback', operator: '=', value: false } },
  { label: '🆔 Has User Tags', value: { key: 'has_user_tagging', operator: '=', value: true } },
  { label: '🚫 Has No User Tags', value: { key: 'has_user_tagging', operator: '=', value: false } },
  { label: '📞 Has Lead', value: { key: 'has_contact', operator: '=', value: true } },
  { label: '🚫 Has No Lead', value: { key: 'has_contact', operator: '=', value: false } },
]
const domain = ChatbotHelper.getDomain()

// Reactive data
const isLoading = ref(false)
const searchValue = ref('')
const filtersValue = ref([])
const chats: any = ref([])
const selectedChat: any = ref({})
const modalFormProps: any = ref({ open: false, refresh: false })
const historyLimitation = ref()
const exportsLimitation = ref()
const currentPage = ref(0)
const limit = 25
const allChatsLoaded = ref(false)

// Lifecycle hooks
onMounted(async () => {
  await init(currentPage.value)
})

// Functions
const init = async (page: number) => {
  try {
    isLoading.value = true

    historyLimitation.value = userSession.getPlanLimitations('history')
    exportsLimitation.value = userSession.getPlanLimitations('exports')

    if (historyLimitation.value && widgetUid) {
      selectedChat.value = null

      // const limit = historyLimitation.value > 500 ? 500 : historyLimitation.value
      const { data, meta } = await HttpHelper.get(`/chats/history/${widgetUid}`, {
        search: searchValue.value,
        filters: filtersValue.value,
        start: page * limit,
        limit,
        returnMetadata: true,
      })

      if (data) {
        chats.value = page === 0 ? [...data] : [...chats.value, ...data]

        if (page + 1 >= meta?.last_page) {
          allChatsLoaded.value = true
        }

        if (!selectedChat.value) {
          onChatSelect(chats.value[0])
        }
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const loadMore = async () => {
  if (!allChatsLoaded.value && !isLoading.value) {
    currentPage.value++

    await init(currentPage.value)

    PageHelper.scrollIfRequired()
  }
}

const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  }

  modalFormProps.value.widgetUid = widgetUid
  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}

const onChatSelect = (chat: IChat) => {
  if (!chat?.uid) {
    return
  }

  selectedChat.value = chat
}

const onShare = async (selectedChat: IChat) => {
  try {
    const text = `${domain}/embed/${widgetUid}?share=${selectedChat?.uid}`

    await toClipboard(text)

    notyf.success('Copied')
  } catch (error) {
    console.error(error)
  }
}

const onDownload = (selectedChat?: IChat | null) => {
  try {
    if (!exportsLimitation.value) {
      alert(
        `Your current plan does not include the option to export conversations. To access this feature, consider upgrading your plan.`
      )
      return
    }

    const blob = new Blob([JSON.stringify(selectedChat ?? chats.value, null, 2)], {
      type: 'application/json',
    })

    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download =
      selectedChat && selectedChat?.uid ? `chat-${selectedChat?.uid}.json` : 'chats.json'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  } catch (error) {
    console.error('Error in onDownload:', error)
  } finally {
    isLoading.value = false
  }
}

const onDelete = async (chat: any, index: number) => {
  try {
    const result = confirm('Are you sure you want to delete this item?')

    if (result) {
      chat.loading = true

      await HttpHelper.destroy('/chats', chat?.uid)

      CookiesHelper.remove(`chat_${widgetUid}`)
      CookiesHelper.remove(`contact_${widgetUid}`)

      chats.value.splice(index, 1)

      selectedChat.value = chats.value[0]
    }
  } catch (error) {
    console.error(error)
  } finally {
    chat.loading = false
  }
}

// Watchers
watch([searchValue, filtersValue], async () => {
  if (!isLoading.value) {
    chats.value = []
    currentPage.value = 0
    await init(currentPage.value)
  }
})
</script>

<template>
  <VMessage v-if="!historyLimitation" color="warning" class="w-100">
    Upgrade your subscription to unlock the “Chat History”.
  </VMessage>

  <VMessage v-else-if="props?.widget?.hide_inbox" color="warning" class="w-100">
    The Inbox & History feature is currently disabled. You can enable it in the chatbot settings.
  </VMessage>

  <template v-else>
    <FormContact
      v-if="modalFormProps?.open && modalFormProps.open === true"
      v-model:modalFormProps="modalFormProps"
      @close="closeModal"
    />

    <div class="columns is-vcentered">
      <div class="column is-6"></div>
      <div class="column is-6">
        <VButtons align="right">
          <VButton
            :loading="isLoading"
            type="submit"
            color="primary"
            :icon="ICONS.actions.download"
            @click="onDownload(null)"
          >
            Export all
          </VButton>
        </VButtons>
      </div>
    </div>

    <!-- Search toolbar -->
    <div class="search-menu">
      <div class="columns">
        <div class="column is-6">
          <FormKit
            v-model="searchValue"
            type="search"
            label="Search"
            :delay="150"
            placeholder="Search all the messages"
            :classes="{
              outer: 'm-0',
            }"
          />
        </div>
        <div class="column is-6">
          <FormKit
            v-model="filtersValue"
            type="taglist"
            label="Filters"
            placeholder="Select filters"
            :options="filtersOptions"
            :close-on-select="false"
            :open-on-click="true"
            :classes="{
              outer: 'm-0',
            }"
          />
        </div>
      </div>
    </div>

    <div class="chat-app-wrapper" :style="{ height: fixedHeight }">
      <div v-if="chats && !chats.length">
        <VMessage color="info" class="h-100 w-100 border-radius-none">
          No conversation available at the moment.
        </VMessage>
      </div>

      <div v-else class="wrapper">
        <div
          v-if="
            (ResponsiveHelper.isMobileScreen && !selectedChat) ||
            ResponsiveHelper.isLargeScreen ||
            ResponsiveHelper.isMediumScreen
          "
          class="conversation-area"
        >
          <!-- Conversations -->
          <div
            v-for="chat in chats"
            :key="chat?.uid"
            class="conversation"
            :class="[selectedChat && selectedChat.uid === chat?.uid && 'active']"
            tabindex="0"
            @keydown.space.prevent="onChatSelect(chat)"
            @click="onChatSelect(chat)"
          >
            <VAvatar :initials="chat?.has_contact ? chat?.computed_contact?.initials : '?'" />

            <div class="conversation-detail">
              <div class="conversation-username">
                {{ chat?.label }}
              </div>

              <div class="conversation-content">
                <span class="conversation-message">
                  <span v-if="chat?.metadata?.location?.country_emoji">
                    {{ chat?.metadata?.location?.country_emoji }}
                  </span>
                  <span v-if="chat?.computed_has_user_tagging">🆔 </span>
                  <span v-if="chat?.has_contact">📞 </span>
                  <span v-if="chat?.is_resolve">🎉 </span>
                  <span v-if="chat?.is_email">💌 </span>
                  <span v-if="chat?.has_feedback">💡 </span>
                </span>
                <div class="conversation-date">{{ DateHelper.formatDate(chat?.updated_at) }}</div>
              </div>
            </div>
          </div>

          <VLoader v-if="isLoading" />
          <VButtons v-if="!allChatsLoaded" align="centered" class="mt-5 mb-5">
            <VButton class="is-flex is-centered w-75" color="light" @click="loadMore">
              Load more...
            </VButton>
          </VButtons>
        </div>

        <!-- Conversation -->
        <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

        <div
          v-else-if="selectedChat && Object.keys(selectedChat).length"
          class="chat-area is-active"
        >
          <VMessage v-if="widget?.hide_widget" color="info" class="m-3">
            Your chatbot is hidden, make it visible to see the preview.
          </VMessage>
          <Embed
            v-else
            :key="selectedChat?.uid"
            :widget-uid="widget?.uid"
            :chat-uid="selectedChat?.uid"
            :is-inbox="true"
          />
        </div>

        <!-- Details -->
        <!-- Conversation Details -->
        <div v-if="selectedChat && Object.keys(selectedChat).length" class="detail-area">
          <div class="chat-side-content is-single">
            <VAvatar
              :initials="selectedChat?.has_contact ? selectedChat?.computed_contact?.initials : '?'"
            />

            <h4 v-if="selectedChat?.has_contact" id="user-details-name" class="user-name">
              {{ selectedChat?.computed_contact?.designation ?? '' }}
            </h4>
            <p v-if="selectedChat?.has_contact" id="user-details-title" class="user-job-title">
              {{ selectedChat?.computed_contact?.email ?? '' }}
            </p>

            <!-- ID -->
            <div>
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">ℹ️ Information</div>
                </summary>
                <div class="mt-3">
                  <ul class="w-100 mx-auto has-text-centered">
                    <li><b>ID:</b> {{ selectedChat?.uid }}</li>
                    <li v-if="selectedChat?.model">
                      <b>Model:</b> {{ selectedChat?.model?.label }}
                    </li>
                    <li v-if="selectedChat?.model">
                      <b>Filter:</b>
                      {{
                        selectedChat?.model?.is_unfiltered ? 'Unfiltered (NSFW)' : 'Filtered (SFW)'
                      }}
                    </li>
                    <li v-if="selectedChat?.model">
                      <b>Internet:</b>
                      {{ selectedChat?.model?.is_connected === true ? 'Yes' : 'No' }}
                    </li>
                  </ul>
                </div>
              </details>
            </div>

            <!-- Location -->
            <div v-if="selectedChat?.metadata?.location">
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">📍 Location</div>
                </summary>
                <div class="mt-3">
                  <ul class="w-100 mx-auto has-text-centered">
                    <li v-if="selectedChat?.metadata?.location?.continent">
                      <b>Continent:</b>
                      {{ selectedChat?.metadata?.location?.continent }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.country">
                      <b>Country:</b>
                      {{ selectedChat?.metadata?.location?.country }}
                      {{ selectedChat?.metadata?.location?.country_emoji }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.region">
                      <b>Region:</b>
                      {{ selectedChat?.metadata?.location?.region }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.city">
                      <b>City:</b>
                      {{ selectedChat?.metadata?.location?.city }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.latitude">
                      <b>Latitude:</b>
                      {{ selectedChat?.metadata?.location?.latitude }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.longitude">
                      <b>Longitude:</b>
                      {{ selectedChat?.metadata?.location?.longitude }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.timezone">
                      <b>Timezone:</b>
                      {{ selectedChat?.metadata?.location?.timezone }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.platform">
                      <b>Platform:</b>
                      {{ selectedChat?.metadata?.location?.platform }}
                    </li>
                    <li v-if="selectedChat?.metadata?.location?.user_agent">
                      <b>User Agent:</b>
                      {{ selectedChat?.metadata?.location?.user_agent }}
                    </li>
                  </ul>
                </div>
              </details>
            </div>

            <!-- Tags -->
            <div
              v-if="
                selectedChat?.has_contact ||
                selectedChat?.is_resolve ||
                selectedChat?.is_email ||
                selectedChat?.has_feedback
              "
            >
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">🏷️ Tags</div>
                </summary>
                <div class="mt-3">
                  <ul class="w-100 mx-auto has-text-center">
                    <li v-if="selectedChat?.computed_has_user_tagging">🆔 User Tagging</li>
                    <li v-if="selectedChat?.has_contact">📞 Lead</li>
                    <li v-if="selectedChat?.is_resolve">🎉 Resolved</li>
                    <li v-if="selectedChat?.is_email">💌 Emailed</li>
                    <li v-if="selectedChat?.has_feedback">💡 Feedback</li>
                  </ul>
                </div>
              </details>
            </div>

            <!-- User Tagging -->
            <div v-if="selectedChat?.computed_has_user_tagging">
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">🆔 User Tagging</div>
                </summary>
                <div class="mt-3">
                  <pre class="has-text-left custom-pre">{{ selectedChat?.metadata?.user }}</pre>
                </div>
              </details>
            </div>

            <!-- Lead -->
            <div v-if="selectedChat?.has_contact">
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">📞 Lead</div>
                </summary>
                <div class="mt-3">
                  <pre class="has-text-left custom-pre">{{ selectedChat?.computed_contact }}</pre>
                </div>
              </details>
            </div>

            <!-- Actions -->
            <div>
              <hr />

              <details>
                <summary>
                  <div class="is-inline title is-5 is-heavy">⚙️ Actions</div>
                </summary>
                <div class="mt-3">
                  <div class="columns is-multiline mt-3">
                    <!-- Lead -->
                    <div v-if="selectedChat?.has_contact" class="column is-12">
                      <VButton
                        :is-loading="selectedChat?.loading"
                        size="medium"
                        @click="openFormModal(selectedChat?.computed_contact)"
                      >
                        Open lead
                      </VButton>
                    </div>

                    <!-- Share -->
                    <div class="column is-12">
                      <VButton size="medium" :fullwidth="true" @click="onShare(selectedChat)">
                        Share
                      </VButton>
                    </div>

                    <!-- Export -->
                    <div class="column is-12">
                      <VButton size="medium" :fullwidth="true" @click="onDownload(selectedChat)">
                        Export
                      </VButton>
                    </div>

                    <!-- Delete -->
                    <div class="column is-12">
                      <VButton
                        :is-loading="selectedChat?.loading"
                        size="medium"
                        color="danger"
                        style="color: white"
                        :fullwidth="true"
                        @click="onDelete(selectedChat, 0)"
                      >
                        Delete
                      </VButton>
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss">
.search-menu {
  width: 100%;
  padding: 15px;
  margin: 0 0 20px 0;
  border: 1px solid var(--border);
  border-radius: var(--radius-large);
}

.checkmark-wrap {
  position: relative;
  height: 32px;
  width: 32px;

  .shadow-circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    border-radius: var(--radius-rounded);
    border: 1px solid var(--border);
    z-index: 0;
    opacity: 1;
    transition: all 0.2s;

    &.is-opaque {
      opacity: 0;
    }
  }

  .checkmark-circle {
    height: 32px;
    width: 32px;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    color: var(--primary);
    stroke: var(--primary);
  }

  // Checkmark
  .checkmark {
    width: 32px;
    height: 32px;
    border-radius: var(--radius-rounded);
    display: block;
    stroke-width: 2;
    color: var(--placeholder);
    stroke: var(--placeholder);
    stroke-miterlimit: 10;
    margin: 0 auto;
    box-shadow: inset 0 0 0 var(--primary);
  }

  // Check symbol
  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    color: var(--primary);
    stroke: var(--primary);
  }
}

p {
  color: black;
}

/* ==========================================================================
1. Variables / Wrapper / Header
========================================================================== */

// TODO
$theme-bg-color: var(--white);
$input-bg: #f8f8fa;
$input-chat-color: #a2a2a2;
$border-color: #eef2f4;
$body-color: #273346;
$chat-text-bg: #f1f2f6;
$msg-date: #c0c7d2;
$msg-hover-bg: rgb(238 242 244 / 40%);
$active-conversation-bg: linear-gradient(
  to right,
  rgb(238 242 244 / 40%) 0%,
  rgb(238 242 244 / 0%) 100%
);
$overlay-bg: linear-gradient(
  to bottom,
  rgb(255 255 255 / 0%) 0%,
  rgb(255 255 255 / 100%) 65%,
  rgb(255 255 255 / 100%) 100%
);
$overlay-bg-dark: linear-gradient(
  to bottom,
  rgb(255 255 255 / 0%) 0%,
  hsl(var(--dark-sidebar-h) var(--dark-sidebar-s) var(--dark-sidebar-l) / 100%) 65%,
  hsl(var(--dark-sidebar-h) var(--dark-sidebar-s) var(--dark-sidebar-l) / 100%) 100%
);

.chat-app-wrapper {
  display: flex;
  flex-direction: column;
  background-color: $theme-bg-color;
  // max-width: 1600px;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  .chat-app-header {
    height: 80px;
    min-height: 80px;
    width: 100%;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .logo {
      width: 38px;
      flex-shrink: 0;
    }

    .search-bar {
      height: 80px;
      flex-grow: 2;
      z-index: 3;
      position: relative;
      margin-left: 280px;

      .field {
        height: 100%;
        width: 100%;

        .control {
          height: 100%;
          width: 100%;

          .form-icon {
            height: 80px;
            width: 80px;

            svg {
              height: 20px;
              width: 20px;
            }
          }

          .input {
            height: 100%;
            width: 100%;
            display: block;
            background-color: transparent;
            border: none;
            color: var(--body-color);
            font-family: var(--font);
            font-weight: 500;
            font-size: 15px;
            padding-left: 80px;
            padding-right: 80px;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    .user-settings {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      flex-shrink: 0;

      & > * + * {
        margin-left: 14px;
      }

      .user-dropdown {
        .is-trigger {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;

          .profile-avatar {
            position: relative;

            .avatar {
              display: block;
              width: 38px;
              height: 38px;
              border-radius: var(--radius-rounded);
            }

            .badge {
              position: absolute;
              right: -8px;
              bottom: 0;
              width: 20px;
              height: 20px;
              border: 2px solid var(--white);
              border-radius: var(--radius-rounded);
            }
          }

          svg {
            margin-left: 3px;
            width: 18px;
            height: 18px;
            color: var(--light-text);
            transition: all 0.3s; // transition-all test
          }
        }

        .dropdown-menu {
          top: 52px;
          border: 1px solid var(--border);
          box-shadow: var(--light-box-shadow);
          border-radius: 8px;
          padding-top: 0;
          width: 180px;
          overflow: hidden;

          .dropdown-item {
            display: flex;
            align-items: center;
            font-family: var(--font);
            font-size: 0.9rem;
            padding: 8px 12px;
            color: var(--light-text);

            svg {
              margin-right: 8px;
              height: 16px;
              width: 16px;
              color: var(--light-text);
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
3. Chat Conversations
========================================================================== */

.chat-app-wrapper {
  .wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    .conversation-area {
      border-right: 1px solid $border-color;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 220px;
      flex-shrink: 0;

      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-shrink: 0;
        background: $overlay-bg;
        height: 80px;
      }

      .conversation {
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s;
        position: relative;

        &:hover,
        &:focus {
          background-color: $msg-hover-bg;
        }

        &.active {
          background: $active-conversation-bg;
          border-left: 4px solid var(--primary);

          .conversation-detail {
            .conversation-username {
              font-weight: 600;
            }
          }
        }

        .v-avatar {
          margin-right: 12px;
        }

        .conversation-detail {
          overflow: hidden;
          font-family: var(--font);

          .conversation-username {
            font-family: var(--font-alt);
            color: var(--dark-text);
            font-weight: 500;
            font-size: 0.95rem;
          }

          .conversation-id {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--light-text);
            max-width: 250px;
            margin-top: 10px;
            font-size: 0.75rem;
          }

          .conversation-content {
            font-weight: 500;
            font-size: 0.9rem;
            display: flex;

            .conversation-message {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: var(--light-text);
              max-width: 170px;
            }

            .conversation-date {
              font-size: 0.9rem;
              color: var(--light-text);
              margin-left: 4px;

              &::before {
                content: '•';
                margin-right: 2px;
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
5. Chat messages
========================================================================== */

.chat-app-wrapper {
  .wrapper {
    .chat-area {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden !important;
      flex-grow: 1;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        flex-shrink: 0;
        background: $overlay-bg;
        height: 80px;
      }

      .chat-area-content {
        display: none;
        // padding-bottom: 80px;
        animation: fadeInLeft 0.5s;

        &.is-active {
          display: block;
        }
      }

      .chat-area-header {
        display: flex;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        align-items: center;
        padding: 20px;
        background: var(--white);

        .trigger {
          padding: 8px;
          margin-right: 6px;
          border: none;
          background: none;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        .chat-area-group {
          flex-shrink: 0;
          display: flex;
          margin-left: auto;

          * {
            border: 2px solid var(--white);
          }

          * + * {
            margin-left: -5px;
          }

          > span {
            width: 32px;
            height: 32px;
            background-color: var(--primary-light-48);
            color: var(--primary);
            border-radius: var(--radius-rounded);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
            font-weight: 500;

            span {
              position: relative;
              top: -1px;
              left: -1px;
              line-height: 1;
              display: block;
              text-align: center;
              margin: 0;
              border: none;
            }
          }
        }

        .chat-area-profile {
          width: 32px;
          border-radius: var(--radius-rounded);
          object-fit: cover;
        }

        .chat-area-title {
          font-size: 1.1rem;
          font-family: var(--font-alt);
          color: var(--dark-text);
          font-weight: 700;
        }
      }

      .chat-area-main {
        flex-grow: 1;

        .chat-msg-img {
          height: 40px;
          width: 40px;
          border-radius: var(--radius-rounded);
          object-fit: cover;
        }

        .chat-msg-profile {
          flex-shrink: 0;
          margin-top: auto;
          margin-bottom: -20px;
          position: relative;
        }

        .chat-msg-date {
          position: absolute;
          left: calc(100% + 12px);
          bottom: 0;
          font-size: 12px;
          font-family: var(--font);
          font-weight: 500;
          color: $msg-date;
          white-space: nowrap;
        }

        .chat-msg {
          display: flex;
          padding: 0 20px 45px;

          .chat-msg-content {
            margin-left: 12px;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .chat-msg-text {
            background-color: $chat-text-bg;
            padding: 15px;
            border-radius: 20px 20px 20px 0;
            line-height: 1.5;
            font-family: var(--font);
            font-size: 0.95rem;
            font-weight: 500;

            + .chat-msg-text {
              margin-top: 10px;
            }
          }
        }

        .chat-msg-text {
          color: var(--chat-text-color);
        }

        .owner {
          flex-direction: row-reverse;

          .chat-msg-content {
            margin-left: 0;
            margin-right: 12px;
            align-items: flex-end;
          }

          .chat-msg-text {
            background-color: var(--primary);
            color: var(--white);
            border-radius: 20px 20px 0;
          }

          .chat-msg-date {
            left: auto;
            right: calc(100% + 12px);
          }
        }

        .chat-msg-text img {
          max-width: 300px;
          border-radius: 10px;
          width: 100%;
        }
      }

      .chat-area-footer {
        display: flex;
        border-top: 1px solid $border-color;
        padding: 10px 20px;
        align-items: center;
        background-color: $theme-bg-color;
        position: fixed;
        right: 0;
        margin: 0 auto;
        width: calc(100% - 680px);
        overflow-x: hidden;
        bottom: 0;
        left: 0;

        input {
          border: 1px solid transparent;
          color: var(--body-color);
          background-color: $input-bg;
          padding: 12px;
          border-radius: var(--radius-large);
          font-size: 15px;
          margin: 0 12px;
          width: 100%;
          transition: all 0.3s; // transition-all test

          &::placeholder {
            color: $input-chat-color;
          }

          &:focus {
            background: var(--white);
            border-color: var(--dark-sidebar-light-12);
            box-shadow: var(--light-box-shadow);
          }
        }

        .add-content {
          &:hover {
            > div {
              .button {
                background: var(--fade-grey);

                svg {
                  color: var(--primary);
                }
              }
            }
          }

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
          }

          .button {
            width: 28px;
            height: 28px;
            border-radius: var(--radius-rounded);
            border: none;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s; // transition-all test

            svg {
              color: var(--placeholder);
              stroke-width: 2px;
              height: 18px;
              width: 18px;
              transition:
                color 0.3s,
                background-color 0.3s,
                border-color 0.3s,
                height 0.3s,
                width 0.3s;
            }
          }

          .dropdown-content {
            a {
              display: flex;
              align-items: center;

              svg {
                height: 18px;
                width: 18px;
                color: var(--muted-grey);
              }

              .meta {
                margin-left: 12px;

                span {
                  display: block;

                  &:first-child {
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: var(--dark-text);
                  }

                  &:nth-child(2) {
                    font-size: 0.9rem;
                    color: var(--muted-grey);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
7. Chat Details
========================================================================== */

.chat-app-wrapper {
  .wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    .detail-area {
      width: 220px;
      flex-shrink: 0;
      border-left: 1px solid $border-color;
      margin-left: auto;
      // padding: 30px 30px 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow: auto;
      overflow-x: hidden;

      .chat-side-content {
        text-align: center;
        transition: all 0.3s; // transition-all test
        padding: 20px 10px;

        .user-pic {
          position: relative;
          max-width: 68px;
          margin: 0 auto;
        }

        .user-name {
          margin-top: 10px;
          font-family: var(--font-alt);
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--dark-text);
        }

        .user-job-title {
          font-family: var(--font);
          color: var(--light-text);
        }

        .side-actions {
          padding: 12px 0;

          .button {
            color: var(--light-text);

            &:hover,
            &:focus {
              box-shadow: var(--light-box-shadow);
            }
          }
        }

        .detail-photos {
          margin-top: 30px;
          text-align: center;
          padding: 0 12px;

          .detail-photo-title {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: 20px;
            color: var(--light-text);

            svg {
              width: 16px;
              margin-right: 8px;
            }
          }

          .detail-photo-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 6px;
            grid-row-gap: 6px;
            grid-template-rows: repeat(3, 60px);

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 8px;
              object-position: center;
            }
          }

          .view-more {
            display: block;
            color: var(--primary);
            font-weight: 600;
            font-size: 15px;
            margin: 25px 0;
          }
        }
      }
    }
  }
}

/* ==========================================================================
9. Media Queries
========================================================================== */

@media (max-width: 767px) {
  .chat-app-wrapper {
    .chat-app-header {
      .search-bar {
        display: none;
      }
    }

    .wrapper {
      /*
      .conversation-area {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: var(--white);
        z-index: 10;
        transform: translateX(-100%);
        transition: all 0.3s; // transition-all test

        &.is-active {
          transform: translateX(0);
        }
      }
      */

      .chat-area {
        .chat-area-main {
          .chat-msg {
            .chat-msg-content {
              max-width: 100%;
            }
          }
        }

        .chat-area-footer {
          width: 100%;
          padding: 10px;
        }
      }

      .detail-area {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .chat-app-wrapper {
    .chat-app-header {
      .search-bar {
        display: none;
      }
    }

    .wrapper {
      /*
      .conversation-area {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: var(--white);
        z-index: 10;
        transform: translateX(-100%);
        transition: all 0.3s; // transition-all test

        &.is-active {
          transform: translateX(0);
        }
      }
      */

      .chat-area {
        .chat-area-footer {
          position: fixed;
          width: 100%;
        }
      }

      .detail-area {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .chat-app-wrapper {
    .wrapper {
      .detail-area {
        display: none !important;
      }

      .chat-area {
        .chat-area-footer {
          width: calc(100% - 340px);
          margin-left: 340px;
        }
      }
    }
  }
}
</style>

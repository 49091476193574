import he from 'he'
import { nextTick } from 'vue'
import { useScroll } from '/@src/stores'

let isScrolling = false

export default class PageHelper {
  public static scrollToBottom = async () => {
    const chatBody = document.querySelector('.chat-body')

    if (chatBody) {
      chatBody?.scroll({ top: 99999999, behavior: 'smooth' })
    }
  }

  public static scrollIfRequired = () => {
    const scrollStore = useScroll()

    if (scrollStore?.allowBottomScroll && !isScrolling) {
      isScrolling = true

      nextTick(() => {
        PageHelper.scrollToBottom()
      })

      isScrolling = false
    }
  }

  public static injectContent(rawHTML: string, targetElement: 'body' | 'head') {
    const isString = rawHTML && typeof rawHTML === 'string'
    const isTargetValid = targetElement === 'head' || targetElement === 'body'

    if (isString && isTargetValid) {
      const element = document[targetElement]

      if (!element) {
        return
      }

      if (typeof DOMParser === 'undefined') {
        console.error('DOMParser is not supported in this browser.')
        return
      }

      const decodedHTML = he.decode(rawHTML)

      if (!decodedHTML) {
        console.error('Failed to decode HTML')
        return
      }

      try {
        const doc = new DOMParser().parseFromString(
          `<template>${decodedHTML}</template>`,
          'text/html'
        )

        if (!doc) {
          console.error('Failed to parse HTML')
          return
        }

        const isTemplateSupported = 'content' in document.createElement('template')

        if (!isTemplateSupported) {
          console.error('Template not supported')
          return
        }

        const content = doc?.querySelector('template')?.content

        content?.childNodes?.forEach((node) => {
          element.appendChild(node)
        })
      } catch (error) {
        console.error('Error parsing HTML', error)
      }
    }
  }

  public static changeFavicon = (url: string) => {
    const faviconList = document.querySelectorAll('link[rel="icon"]')

    if (faviconList) {
      faviconList.forEach((favicon: any) => {
        favicon.href = url
      })
    }
  }
}

<script setup lang="ts">
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { FileHelper, HttpHelper } from '/@src/helpers'
import { MOBILE_APP_CATEGORIES } from '/@src/resources/files/constant'
import locales from '/@src/resources/files/locales.json'
import { useUserSession } from '/@src/stores'

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  addons: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()

// Const data
const formId = 'formMobileApp'
const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.ico', '.webp']
const allowedExtensionsString = allowedExtensions.join(', ')
const maxFileSize = 200

// Reactive data
const isLoading = ref(true)
const formData: any = ref({})
const formState: any = reactive({})

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true

    const widgetResponse = await HttpHelper.get(`/widgets/${props.widget?.uid}`)

    formData.value = { ...(widgetResponse?.mobile_app || {}) }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { mobile_app } = fields

    await HttpHelper.post('/widgets', props.widget?.uid, {
      mobile_app,
    })

    const new_mobile_app_icon_512_512 =
      fields?.mobile_app?.icon_512_512?.new_mobile_app_icon_512_512

    if (new_mobile_app_icon_512_512) {
      const data = new FormData()
      data.append('new_mobile_app_icon_512_512', new_mobile_app_icon_512_512?.[0]?.file)

      if (new_mobile_app_icon_512_512?.[0]?.file) {
        const dimensions = await FileHelper.getImageDimensions(
          new_mobile_app_icon_512_512?.[0]?.file
        )

        if (dimensions?.width < 512 || dimensions?.height < 512) {
          formData.value.icon_512_512.new_mobile_app_icon_512_512 = ''

          alert('Your mobile app icon need have a width and height over 512 pixels.')

          return
        }
      }

      const response = await HttpHelper.post('/widgets/images', props.widget?.uid, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      formData.value = { ...response?.computed_settings }
    }

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit
    :id="formId"
    v-model="formData"
    type="form"
    :config="{ validationVisibility: 'live' }"
    :disabled="isLoading"
    :actions="false"
    :classes="{
      form: 'h-100',
    }"
    :preserve="true"
    :preserve-errors="true"
    @submit="onSubmit"
  >
    <CustomForm title="Mobile" subtitle="">
      <template #buttons>
        <VButton
          :disabled="isFormDisabled"
          :loading="isLoading"
          type="submit"
          color="primary"
          icon="ic:round-check"
          @click="triggerSubmit"
        >
          Save Changes
        </VButton>
      </template>

      <template #body>
        <div class="columns is-multiline">
          <div class="column is-12">
            <MarketingMessage
              v-if="!userSession.getPlanLimitations('mobile_app')"
              :plan="addons.mobile_app"
              cta="Purchase Now"
            >
              Purchase the “Mobile App” addon to unlock this feature!
            </MarketingMessage>
          </div>

          <div class="column is-12">
            <FormKit
              label=""
              name="status"
              type="toggle"
              :off-value="false"
              :on-value="true"
              label-position="secondary"
              value-label-display="inner"
              off-value-label="OFF"
              on-value-label="ON"
              validation="required:trim"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
              :classes="{ outer: 'm-0 mr-4' }"
            />
          </div>

          <div class="column is-4">
            <FormKit
              name="name"
              type="text"
              label="App name"
              validation="required:trim"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
            />
          </div>

          <div class="column is-4">
            <FormKit
              name="short_name"
              type="text"
              label="Short name"
              validation="required:trim|length:3"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
            />
          </div>

          <div class="column is-4">
            <FormKit
              name="text"
              type="text"
              label="Install button"
              validation="required:trim"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
            />
          </div>

          <div class="column is-12">
            <FormKit
              name="description"
              type="text"
              label="Description"
              validation="required:trim"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
            />
          </div>

          <div class="column is-6">
            <FormKit
              name="hl"
              type="dropdown"
              label="Locale"
              :options="locales"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
              placeholder="Default"
              :close-on-select="true"
              value="en"
            >
              <template #option="{ option }">
                <div class="formkit-option">
                  <div class="text-container">
                    {{ option.label }}
                  </div>
                </div>
              </template>
            </FormKit>
          </div>

          <div class="column is-6">
            <FormKit
              type="taglist"
              name="categories"
              label="Categories"
              :options="MOBILE_APP_CATEGORIES"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
              :allow-new-values="false"
              :open-on-click="true"
              min="1"
            />
          </div>

          <div class="column">
            <FormKit
              type="colorpicker"
              name="background"
              label="Background Color"
              validation="required:trim"
              eye-dropper="true"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
              :classes="{ inner: 'w-100' }"
            />
          </div>

          <div class="column">
            <FormKit
              type="colorpicker"
              name="color"
              label="Text Color"
              validation="required:trim"
              eye-dropper="true"
              :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
              :classes="{ inner: 'w-100' }"
            />
          </div>

          <!-- Icons -->
          <div class="column is-12">
            <CustomForm title="Icons">
              <template #body>
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <!-- Icon (512x512) -->
                    <FormKit
                      name="icon_512_512"
                      type="group"
                      :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
                    >
                      <VMessage color="info">
                        The optimal size for your app icon is 512x512 pixels. We will utilize this
                        logo dimension to create various versions in different sizes. If your logo
                        deviates from the recommended size, the generated logos may appear
                        pixelated.
                      </VMessage>

                      <!-- App icon (512x512) -->
                      <div v-if="formData.icon_512_512.url" class="columns is-vcentered">
                        <div class="column is-align-self-center is-flex">
                          <img :src="formData.icon_512_512.url" alt="Icon" style="height: 40px" />
                        </div>

                        <div class="column is-narrow is-flex">
                          <FormKitControls
                            :is-loading="false"
                            :is-vertical="true"
                            :disable-insert="true"
                            :disable-refresh="true"
                            @delete="formData.images.logo.url = ''"
                          />
                        </div>
                      </div>
                      <FormKit
                        v-else
                        type="file"
                        name="new_mobile_app_icon_512_512"
                        label="Your mobile app icon (512x512)"
                        :accept="allowedExtensionsString"
                        multiple="false"
                        :disabled="isLoading || !userSession.getPlanLimitations('mobile_app')"
                        :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB - Required dimensions: 512x512`"
                      />
                    </FormKit>
                  </div>
                </div>
              </template>
            </CustomForm>
          </div>
        </div>
      </template>
    </CustomForm>
  </FormKit>
</template>

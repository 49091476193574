import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import qs from 'qs'
import { useNotyf } from '/@src/composable/useNotyf'
import translation from '/@src/locales/en.json'
import { useUserSession } from '/@src/stores'

axiosRetry(axios, { retries: 3 })

const apiWithApp: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

const apiWithoutApp: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

apiWithApp.interceptors.request.use((config) => configInterceptor(config, true))
apiWithoutApp.interceptors.request.use((config) => configInterceptor(config, false))

apiWithApp.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
)
apiWithoutApp.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
)

export function configInterceptor(config: any, isWithApp: boolean) {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const userSession = useUserSession()
  const token = userSession?.isLoggedIn ? userSession?.token : urlSearchParams.get('token')
  const appUid = userSession?.app?.uid ?? urlSearchParams.get('app_uid')

  if (token) {
    userSession?.setToken(token)
  }

  if (isWithApp && appUid) {
    config.baseURL = `${import.meta.env.VITE_API_URL}/${appUid}`
  }

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  config.paramsSerializer = (p: any) => {
    return qs.stringify(p, { arrayFormat: 'indices' })
  }

  return config
}

export async function errorInterceptor(error: any) {
  const notyf = useNotyf()
  const message = error?.response?.data?.message ?? error?.message
  const status = error?.response?.data?.status ?? error?.status
  const code1 = error?.response?.data?.code
  const code2 = error?.code
  const userSession = useUserSession()

  if (status === 401) {
    console.info('Status 401, redirect...')

    await userSession?.logoutUser()
    return
  }

  if (code1 === 'E_INVALID_API_TOKEN' || code2 === 'E_INVALID_API_TOKEN') {
    return
  }

  let errorMsg = ''

  if (message) {
    errorMsg = message
  } else if (code1) {
    // @ts-ignore
    const translationMsg = translation?.errors[code1]

    errorMsg =
      translationMsg?.b?.s ??
      translationMsg?.loc?.source ??
      'An error occurred, please try again or contact support!'
  } else if (code2) {
    errorMsg = code2
  } else {
    errorMsg = 'An error occurred, please try again or contact support!'
  }

  if (status === 426) {
    notyf.info(errorMsg)
  } else {
    notyf.error(errorMsg ? `${errorMsg}` : `${message.replace(`${code1 ?? code2}:`, '')}`)
  }

  return Promise.reject({ code1, code2, errorMsg, message, status })
}

export function useApi(isWithApp: boolean = true) {
  return isWithApp ? apiWithApp : apiWithoutApp
}

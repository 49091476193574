import { createHead } from '@vueuse/head'
import { Crisp } from 'crisp-sdk-web'
import { Settings } from 'luxon'
import { createPinia } from 'pinia'
import Rollbar from 'rollbar'
import 'viewerjs/dist/viewer.css'
import { createApp as createClientApp } from 'vue'
import vue3GoogleLogin from 'vue3-google-login'
import { createRouter } from './router'
import './styles'
import VueroApp from './VueroApp.vue'

Settings.defaultLocale = 'en-US'
Settings.defaultZone = 'America/New_York'

Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID, {
  sessionMerge: true,
  autoload: false,
})

const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  addErrorContext: true,
  captureDeviceInfo: true,
  autoInstrument: true,
  captureIp: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  environment: import.meta.env.MODE,
  ignoreDuplicateErrors: true,
  includeItemsInTelemetry: true,
  nodeSourceMaps: true,
  verbose: false,
  hostSafeList: [
    'insertchat.com',
    'app.insertchat.com',
    'embed.insertchat.com',
    'bot.insertchat.com',
    'api.insertchat.com',
    'api-fallback.insertchat.com',
    'insertchatgpt.com',
    'app.insertchatgpt.com',
    'embed.insertchatgpt.com',
    'bot.insertchatgpt.com',
    'api.insertchatgpt.com',
    'api-fallback.insertchatgpt.com',
  ],
  checkIgnore: function (isUncaught, args, payload) {
    const arg1 = args[0] || ''

    let errorString

    if (typeof arg1 === 'string') {
      errorString = arg1
    } else if (arg1 instanceof Error) {
      errorString = arg1.message
    } else {
      try {
        errorString = JSON.stringify(arg1)
      } catch (e) {
        errorString = 'Unknown error'
      }
    }

    const errorsToIgnore = ['Qr.sessionId']

    if (
      errorsToIgnore.some((error) =>
        errorString?.toLocaleLowerCase()?.includes(error?.toLocaleLowerCase())
      )
    ) {
      return true
    }

    return false
  },
})

const plugins = import.meta.glob('./plugins/*.ts')

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (vuero: VueroAppContext) => void | Promise<void>
export function definePlugin(plugin: VueroPlugin) {
  return plugin
}

export async function createApp() {
  try {
    const app = createClientApp(VueroApp)
    const router = createRouter()
    const head = createHead()
    const pinia = createPinia()

    app.use(head)
    app.use(pinia)
    app.use(vue3GoogleLogin, { clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID })

    const vuero = {
      app,
      router,
      head,
      pinia,
    }

    app.provide('vuero', vuero)

    if (import.meta.env.MODE === 'production') {
      app.provide('rollbar', rollbar)

      app.config.errorHandler = (error, vm, info) => {
        rollbar.error(error as Error, { vueComponent: vm, info })
        console.error(error)
      }
    }

    try {
      const pluginPromises = Object.entries(plugins).map(async ([path, loadPlugin]) => {
        const pluginModule = await loadPlugin()
        const plugin = (pluginModule as { default?: VueroPlugin }).default

        if (plugin) {
          await plugin(vuero)
        } else {
          throw new Error(`Plugin "${path}" does not export a default module.`)
        }
      })

      await Promise.all(pluginPromises)
    } catch (error) {
      console.error('Failed to initialize plugins:', error)
    }

    app.use(vuero.router)

    return vuero
  } catch (error) {
    console.error('App error:', error)
  }
}

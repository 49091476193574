<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (event: 'setTrainingType', type: string): void
}>()

// Const data
const selectedTrainingOption = ref('')
const trainingOptions = [
  {
    type: 'urls',
    label: 'URLs',
    description: 'Crawl & Scrape',
    icon: 'ic:round-add-link',
  },
  {
    type: 'files',
    label: 'Files',
    description: 'PDFs, words, ...',
    icon: 'ic:round-file-present',
  },
  {
    type: 'quizzes',
    label: 'Q&A',
    description: 'Q&A pairs',
    icon: 'ic:round-question-answer',
  },
  {
    type: 'texts',
    label: 'Raw text',
    description: 'Paste your text',
    icon: 'ic:round-text-snippet',
  },
  {
    type: 'products',
    label: 'Catalogs',
    description: 'Products & Services',
    icon: 'ic:round-shopping-cart',
  },
  {
    type: 'audios',
    label: 'Audios',
    description: 'MP3, M4A, ...',
    icon: 'ic:round-audio-file',
  },
  {
    type: 'youtube',
    label: 'Youtube',
    description: '▶️',
    icon: 'ic:round-video-chat',
  },
  {
    type: 'cloud_storage',
    label: 'Cloud Storage (Business)',
    description: 'AWS S3, R2, ...',
    icon: 'ic:round-cloud-sync',
  },
]

// Composable
const userSession = useUserSession()

// Reactive data
const cloudStorageTrainingLimitation = ref(false)

// Lifecycle hooks
onBeforeMount(() => {
  cloudStorageTrainingLimitation.value = userSession.getPlanLimitations('cloud_storage_training')
})
</script>

<template>
  <div class="columns is-multiline mb-0">
    <div
      v-for="trainingOption in trainingOptions"
      :key="trainingOption.type"
      class="column is-4"
      :class="{
        'is-disabled': !cloudStorageTrainingLimitation && trainingOption.type === 'cloud_storage',
        'is-active': selectedTrainingOption === trainingOption.type,
      }"
      @click="
        () => {
          if (!cloudStorageTrainingLimitation && trainingOption.type === 'cloud_storage') {
            return
          }

          selectedTrainingOption = trainingOption.type
          emit('setTrainingType', trainingOption.type)
        }
      "
    >
      <div class="training-box">
        <div class="icon-wrap">
          <VIcon :icon="trainingOption.icon" width="2.25rem" height="2.25rem" />
        </div>
        <span class="mb-3">{{ trainingOption.label }}</span>
        <h3>{{ trainingOption.description }}</h3>
      </div>
    </div>
  </div>
</template>

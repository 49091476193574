export default class FileHelper {
  public static trainingExtensions = [
    // csv
    '.csv',
    // E-mail
    '.eml',
    '.msg',
    '.p7s',
    // EPUB
    '.epub',
    // Excel
    '.xls',
    '.xlsx',
    // Image
    '.bmp',
    '.heic',
    '.jpeg',
    '.png',
    '.tiff',
    '.jpg',
    // Markdown
    '.md',
    // Org Mode
    '.org',
    // Open Office
    '.odt',
    // PDF
    '.pdf',
    // Plain text
    '.txt',
    // PowerPoint
    '.ppt',
    '.pptx',
    // reStructured Text
    '.rst',
    // Rich Text
    '.rtf',
    // TSV
    '.tsv',
    // Word
    '.doc',
    '.docx',
    // XML
    '.xml',
    // Audio
    '.mp3',
    '.mp4',
    '.mpeg',
    '.mpga',
    '.m4a',
    '.wav',
    '.webm',
    // Other
    '.html',
    '.json',
  ]
  public static imageExtensions = ['.bmp', '.heic', '.jpeg', '.png', '.tiff', '.jpg']
  public static audioExtensions = ['.mp3', '.mp4', '.mpeg', '.mpga', '.m4a', '.wav', '.webm']

  public static download = (fileUrl: string, fileName: string, fileExtension: string) => {
    if (!fileUrl || !fileName || !fileExtension) {
      throw new Error('URL, name, extension is missing.')
    }

    return new Promise((resolve, reject) => {
      fetch(fileUrl, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = fileName + '.' + fileExtension
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          resolve(url)
        })
        .catch((error) => reject(error))
    })
  }

  public static getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (e) {
        if (e.target) {
          const img = new Image()
          img.src = e.target.result as string

          img.onload = function () {
            resolve({ width: img.width, height: img.height })
          }

          img.onerror = function (err) {
            reject(err)
          }
        }
      }

      reader.onerror = function (err) {
        reject(err)
      }

      reader.readAsDataURL(file)
    })
  }
}

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ChatbotHelper, HttpHelper } from '/@src/helpers'
import { IFilter, IPlan, ITab, IWidget } from '/@src/interfaces'
import Accounts from '/@src/pages/chatbots/accounts.vue'
import Agents from '/@src/pages/chatbots/agents.vue'
import Analytics from '/@src/pages/chatbots/analytics.vue'
import Contacts from '/@src/pages/chatbots/contacts.vue'
import Design from '/@src/pages/chatbots/design.vue'
import Inbox from '/@src/pages/chatbots/inbox.vue'
import Install from '/@src/pages/chatbots/install.vue'
import Integrations from '/@src/pages/chatbots/integrations.vue'
import Preview from '/@src/pages/chatbots/preview.vue'
import Settings from '/@src/pages/chatbots/settings.vue'
import Training from '/@src/pages/chatbots/training.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useApp, useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const uid: any = useRoute().params?.uid ?? undefined
const baseUrl = `/chatbots/${uid}`
const filters: IFilter[] = [
  {
    key: 'widget_uid',
    operator: '=',
    value: uid,
  },
]
const url = `/widgets/${uid}?expand[0]=model`

// Reactive data
const tabs = [
  {
    category: 'Setup',
    items: [
      {
        label: 'Preview',
        value: 'preview',
        icon: ICONS.pages.preview,
        component: Preview,
      },
      {
        label: 'Install',
        value: 'install',
        icon: ICONS.pages.install,
        component: Install,
      },
    ],
  },
  {
    category: 'Config',
    items: [
      {
        label: 'Settings',
        value: 'settings',
        icon: ICONS.pages.settings,
        component: Settings,
        class: 'p-0',
      },
      {
        label: 'Design',
        value: 'design',
        icon: ICONS.pages.design,
        component: Design,
        class: 'p-0',
      },
    ],
  },
  {
    category: 'AI',
    items: [
      {
        label: 'Knowledge',
        value: 'knowledge',
        icon: ICONS.pages.training,
        component: Training,
      },
      {
        label: 'Agents',
        value: 'agents',
        icon: ICONS.pages.agents,
        component: Agents,
        class: 'p-0',
      },
    ],
  },
  {
    category: 'Monitor',
    items: [
      {
        label: 'Analytics',
        value: 'analytics',
        icon: ICONS.pages.analytics,
        component: Analytics,
      },
      {
        label: 'Inbox',
        value: 'inbox',
        icon: ICONS.pages.inbox,
        component: Inbox,
      },
    ],
  },
  {
    category: 'Collect',
    items: [
      {
        label: 'Contacts',
        value: 'contacts',
        icon: ICONS.pages.contacts,
        component: Contacts,
      },
      {
        label: 'Accounts',
        value: 'accounts',
        icon: ICONS.pages.accounts,
        component: Accounts,
      },
    ],
  },
  {
    category: 'Connect',
    items: [
      {
        label: 'Integrations',
        value: 'integrations',
        icon: ICONS.pages.integrations,
        component: Integrations,
        class: 'p-0',
      },
    ],
  },
]

const title = ref('')
const isLoading = ref(true)
const selected = ref(tabs[0].items[0].value)
const widget = ref<IWidget>({} as IWidget)
const plans = ref<IPlan[]>()
const addons = ref({})

// Composable
const router = useRouter()
const app = useApp()
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title: title.value })
const userSession = useUserSession()

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    const [plansResponse, widgetResponse] = await Promise.all([
      app.loadPlans('all'),
      HttpHelper.get(url),
    ])

    widget.value = { ...widgetResponse }
    plans.value = plansResponse?.plans
    addons.value = plansResponse?.addons
    title.value = widgetResponse?.label

    viewWrapper.setPageTitle(title.value)
    viewWrapper.setPageIcon(ICONS.pages.chats)

    useHead({ title: title.value })

    ChatbotHelper.loadChatbot(widget.value.uid)
  } catch (error) {
    console.error(error)

    await router.push({
      path: `/chatbots`,
    })
  } finally {
    isLoading.value = false
  }
})

// Functions
const goToStep = () => {
  window.location.href = `${baseUrl}?tab=settings&subtab=persona`

  return
}
</script>

<template>
  <VLoader v-if="isLoading" card="smooth" />

  <template v-else>
    <FormOnboarding :widget="widget" />

    <VMessage
      color="danger"
      v-if="
        userSession?.plan?.limitations?.is_ltd &&
        userSession?.plan?.limitations?.byok &&
        widget?.has_byok === false
      "
    >
      <div class="columns is-flex is-vcentered">
        <div class="column p-0">
          We’re excited to announce that <b>BYOK (Bring Your Own Keys)</b> is now available as part
          of your plan! This feature provides you with greater flexibility and control over your AI
          chatbots by allowing you to use your own API keys, ensuring uninterrupted access and
          enabling you to go beyond the standard credit limits of your plan.<br /><br />
          To access to the full app, go to your chatbot Settings > AI Persona > Bring Your Own Key
          (BYOK) to add your OpenAI key.
        </div>

        <div class="column is-narrow p-0">
          <VButton
            type="button"
            color="primary"
            @keyup.enter="goToStep()"
            @click="goToStep()"
            :disabled="isLoading"
          >
            Settings
          </VButton>
        </div>
      </div>
    </VMessage>

    <VCard style="min-height: 650px" class="h-100 p-0">
      <VTabs
        v-model:selected="selected"
        :tabs="tabs"
        :vertical="true"
        :alwaysOpen="true"
        :data="{
          widget,
          filters,
          plans,
          addons,
        }"
      >
      </VTabs>
    </VCard>
  </template>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, Ref } from 'vue'
import { ViaPlaceholderHelper } from '/@src/helpers'
import { ISchema } from '/@src/interfaces/index'
import { useWidget } from '/@src/stores'

const props = defineProps({
  schema: {
    type: Object as () => ISchema,
    default: () => ({}),
    required: true,
  },
})

// Composable
const widgetStore = useWidget()

// Reactive data
const assistantMessageStylingSettings: any = ref({})
const currentSlide = ref(0)
const isLoading = ref(false)
const schema: Ref<ISchema | undefined> = ref()

// Lifecycle hooks
onBeforeMount(() => {
  assistantMessageStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()
})

onMounted(async () => {
  try {
    if (!props.schema) {
      return
    }

    schema.value = props.schema
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
})

// Computed
const brand = computed(() => {
  return schema.value?.meta?.brand || schema.value?.meta?.site
})

const truncatedBrand = computed(() => {
  const maxLength = 90
  const rawBrand = brand.value || ''

  return rawBrand.length > maxLength ? rawBrand.slice(0, maxLength) + '...' : rawBrand
})

const title = computed(() => {
  return schema.value?.meta?.title || ''
})

const truncatedTitle = computed(() => {
  const maxLength = 36
  const rawTitle = title.value || ''

  return rawTitle.length > maxLength ? rawTitle.slice(0, maxLength) + '...' : rawTitle
})

const description = computed(() => {
  return schema.value?.meta?.description || ''
})

const truncatedDescription = computed(() => {
  const maxLength = 90
  const rawDescription = description.value || ''

  return rawDescription.length > maxLength
    ? rawDescription.slice(0, maxLength) + '...'
    : rawDescription
})

const thumbnails = computed(() => {
  return schema.value?.links?.thumbnail || []
})

const thumbnailHref = computed(() => {
  if (thumbnails.value.length > 0) {
    return thumbnails.value[0].href
  }
  return ''
})

const iconHref = computed(() => {
  if (schema.value?.links && schema.value?.links?.icon && schema.value?.links?.icon?.length > 0) {
    return schema.value?.links?.icon[0].href
  }
  // Fallback icon
  return ''
})

const isIconSvg = computed(() => {
  return iconHref.value.endsWith('.svg')
})

const isVideo = computed(() => {
  return (
    (schema.value?.meta?.medium && schema.value?.meta?.medium === 'video') ||
    (schema.value?.links?.player && schema.value?.links?.player?.length > 0)
  )
})

const playerHref = computed(() => {
  if (schema.value?.links?.player && schema.value?.links?.player?.length > 0) {
    // Choose the first player with HTML
    const player = schema.value?.links?.player?.find((p) => p?.html)

    return player ? player?.href : ''
  }
  return ''
})

const formattedPrice = computed(() => {
  const price =
    typeof schema.value?.meta?.price === 'string'
      ? parseFloat(schema.value?.meta?.price)
      : schema.value?.meta?.price

  if (isNaN(price)) {
    return ''
  }

  return price.toFixed(2)
})

const showHeader = computed(() => {
  return schemaHasBrandOrIcon.value
})

const schemaHasBrandOrIcon = computed(() => {
  return schema.value?.meta?.brand || iconHref.value
})

const canonical = computed(() => {
  return schema.value?.meta?.canonical || ''
})

// Functions
const openCanonical = () => {
  if (canonical.value) {
    window.open(canonical.value, '_blank')
  }
}

const prevSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value -= 1
  } else {
    currentSlide.value = thumbnails.value?.length - 1
  }
}

const nextSlide = () => {
  if (currentSlide.value < thumbnails.value?.length - 1) {
    currentSlide.value += 1
  } else {
    currentSlide.value = 0
  }
}

const goToSlide = (index: number) => {
  if (index >= 0 && index < thumbnails.value?.length) {
    currentSlide.value = index
  }
}
</script>

<template>
  <div
    class="columns is-multiline rich-media-card is-flex is-vcentered"
    v-if="schema && Object.keys(schema).length"
    @click="openCanonical"
  >
    <div class="column is-6-mobile">
      <figure class="has-ratio" v-if="isVideo">
        <iframe :src="playerHref" width="100%" height="120px" allowfullscreen></iframe>
      </figure>

      <figure class="has-ratio" v-else-if="thumbnailHref">
        <img
          :src="thumbnailHref"
          :alt="title || 'Product Thumbnail'"
          loading="lazy"
          @click="openCanonical"
          @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '120x120')"
        />
      </figure>

      <figure class="has-ratio" v-else-if="iconHref">
        <object
          v-if="isIconSvg"
          :data="iconHref"
          type="image/svg+xml"
          @click="openCanonical"
          :alt="title || 'Logo'"
          style="cursor: pointer; height: 120px; max-height: 120px; width: auto"
        >
          Your browser does not support SVG
        </object>

        <img
          v-else
          :src="iconHref"
          :alt="title || 'Logo'"
          loading="lazy"
          @click="openCanonical"
          @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '120x120')"
        />
      </figure>
    </div>

    <div class="column is-10 is-6-mobile">
      <div class="columns is-multiline">
        <div class="column is-12 pt-0 p-0" v-if="title || brand" :title="title">
          <div class="title is-5">
            {{ truncatedTitle || truncatedBrand }}
          </div>
        </div>

        <div class="column is-12 pt-3 p-0" v-if="description">
          <p class="description" :title="description">
            {{ truncatedDescription }}
          </p>
        </div>

        <div class="column is-12 pt-3 p-0" v-if="formattedPrice">
          <div class="columns">
            <div class="column is-6 is-12-mobile p-0">
              <div class="pricing">{{ schema.meta.currency || '' }}{{ formattedPrice }}</div>
            </div>

            <div class="column is-6 is-12-mobile has-text-right p-0">
              <VIcon
                @click.stop="openCanonical"
                :is-clickable="true"
                :inline="true"
                class="chat-icons-color chat-radius-rounded"
                icon="material-symbols:add-shopping-cart-rounded"
                aria-label="Buy"
                :style="{
                  'vertical-align': 'middle',
                  'background': assistantMessageStylingSettings?.background,
                  'color': assistantMessageStylingSettings?.color,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rich-media-card {
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 12px;
  width: 100%;
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }

  .column {
    padding: 10px;
  }

  iframe,
  img,
  object {
    margin: 0 auto !important;
    max-width: 100% !important;
    height: auto !important;
    max-height: 120px !important;
    display: block !important;
    border-radius: 12px !important;
  }

  iframe {
    width: 120px !important;
  }

  @media (max-width: 768px) {
    iframe,
    img,
    object {
      max-height: 120px !important;
    }

    .description,
    .title,
    .pricing {
      text-align: center;
    }
  }
}
</style>

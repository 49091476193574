<script setup lang="ts">
import { onMounted } from 'vue'

// Lifecycle hooks
onMounted(async () => {
  setTimeout(() => {
    window.location.href = 'https://bit.ly/insertchat-api'
  }, 1500)
})
</script>

<template>
  <VLoader v-if="true" class="m-auto" :translucent="false" :opaque="false" text="Redirecting..." />
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { computed } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useNotyf } from '/@src/composable/useNotyf'
import { WindowHelper } from '/@src/helpers'
import { IMessage } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useWidget } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'close'])

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  message: {
    type: Object as () => IMessage,
    required: true,
    default: () => {},
  },
})

// Composable
const { toClipboard } = useClipboard()
const widgetStore = useWidget()
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})
const userSession = useUserSession()

// Reactive data
const listeningLimitation = ref()
const modalFormProps: any = ref({ open: false, refresh: false })
const copyIcon = ref(ICONS.actions.copy)
const bubbleSettings: any = ref({})

// Lifecycle hooks
onBeforeMount(() => {
  bubbleSettings.value = widgetStore.getBubbleSettings()
  listeningLimitation.value = widgetStore.getPlanLimitations('text_to_speech')
})

const showTrainingIcon = computed(() => {
  return userSession?.isLoggedIn && window?.location?.pathname?.includes('/chatbots')
})

// Functions
const onCopy = async (text: string) => {
  try {
    WindowHelper.postMessage('click_copy', {
      widget_uid: widgetStore.state.widget?.uid,
      chat_uid: widgetStore.state.chat?.uid,
      account_uid: widgetStore.state.account?.uid,
    })

    await toClipboard(text)

    copyIcon.value = ICONS.actions.check
    setTimeout(() => {
      copyIcon.value = ICONS.actions.copy
    }, 1250)
  } catch (error) {
    console.error(error)

    notyf.error('Error on copy')
  }
}

const onSynthesize = async () => {
  if (props?.message?.audio?.playing) {
    // eslint-disable-next-line vue/no-mutating-props
    props.message.audio.playing = false
    props?.message?.audio?.element?.pause()

    return
  }

  await widgetStore.onSynthesize(props?.message)
}

const openFeedbackModal = async (status: string) => {
  WindowHelper.postMessage('click_feedback', {
    widget_uid: widgetStore.state.widget?.uid,
    chat_uid: widgetStore.state.chat?.uid,
    account_uid: widgetStore.state.account?.uid,
  })

  const messageUid = props.message.uid ?? ''
  const userMessage = widgetStore.findPreviousMessage(props.index) || ''

  await widgetStore.createFeedback({
    messageUid,
    userMessage,
    aiMessage: props.message.output_text ?? '',
    userFeedback: '',
    status,
  })

  if (widgetStore.state.messages[props.index]) {
    // @ts-ignore
    widgetStore.state.messages[props.index].feedback = {
      message_uid: messageUid,
      ai_message: userMessage,
      user_feedback: '',
      status,
    }
  }
}

// Functions
const openTrainingModal = (text: string) => {
  const previousMessage = widgetStore.findPreviousMessage(props.index)

  modalFormProps.value.widgetUid = widgetStore.getWidget()?.uid
  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
  modalFormProps.value.question = previousMessage?.trim()
  modalFormProps.value.answer = text?.trim()
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}
</script>

<template>
  <FormTraining
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
    @close="closeModal"
  />

  <!-- Listen -->
  <VIcon
    v-if="bubbleSettings?.status_listen_button"
    :is-clickable="true"
    :icon="
      props?.message?.audio?.loading
        ? ICONS.actions.magic
        : props?.message?.audio?.playing
          ? ICONS.actions.pause
          : ICONS.actions.play
    "
    class="mr-2 chat-icons-color"
    :style="{
      height: '1rem',
      width: '1rem',
    }"
    @click="onSynthesize"
  />

  <!-- Copy -->
  <VIcon
    v-if="bubbleSettings?.status_copy_button"
    :is-clickable="true"
    :icon="copyIcon"
    class="mr-2 chat-icons-color"
    :style="{
      height: '1rem',
      width: '1rem',
    }"
    @click="onCopy(props?.message?.output_text ?? '')"
    @click.prevent="() => {}"
  />

  <!-- Thumb up -->
  <VIcon
    v-if="bubbleSettings?.status_feedback_button"
    :is-clickable="true"
    :icon="ICONS.actions.thumb_up"
    class="mr-2 chat-icons-color"
    :style="{
      height: '1rem',
      width: '1rem',
    }"
    @click="openFeedbackModal('thumb_up')"
  />

  <!-- Thumb down -->
  <VIcon
    v-if="bubbleSettings?.status_feedback_button"
    :is-clickable="true"
    :icon="ICONS.actions.thumb_down"
    class="mr-2 chat-icons-color"
    :style="{
      height: '1rem',
      width: '1rem',
    }"
    @click="openFeedbackModal('thumb_down')"
  />

  <!-- Train -->
  <VIcon
    v-if="showTrainingIcon"
    :is-clickable="true"
    :icon="ICONS.pages.training"
    class="chat-icons-color"
    color="black"
    :style="{
      height: '1.15rem',
      width: '1.15rem',
    }"
    @click="openTrainingModal(props?.message?.output_text ?? '')"
  />
</template>

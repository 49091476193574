<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { WindowHelper } from '/@src/helpers'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Reactive data
const isLoading = ref(false)
const welcomeScreenSettings: any = ref({})
const welcomeScreenTitleSettings: any = ref({})
const welcomeScreenDescriptionSettings: any = ref({})
const welcomeScreenCardSettings: any = ref([])
const agentsLimitation = ref<string[]>([])

// Computed
const activeCards = computed(() => {
  if (agentsLimitation.value?.includes('cards')) {
    const cards = welcomeScreenCardSettings.value?.items

    return cards
  }

  return []
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true

    welcomeScreenSettings.value = widgetStore.getWelcomeSettings()
    welcomeScreenTitleSettings.value = widgetStore.getWelcomeTitleSettings()
    welcomeScreenDescriptionSettings.value = widgetStore.getWelcomeDescriptionSettings()
    welcomeScreenCardSettings.value = widgetStore.getWelcomeCardSettings()
    agentsLimitation.value = widgetStore.getPlanLimitations('agents')
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Function
const onClickPrompt = async (prompt: string) => {
  WindowHelper.postMessage('click_card', {
    widget_uid: widgetStore.getWidget()?.uid,
    chat_uid: widgetStore.getChat()?.uid,
    account_uid: widgetStore.getAccount()?.uid,
    text: prompt ?? '',
  })

  widgetStore.setWidgetState('overrideContent', '')

  await widgetStore.createMessage({
    role: 'user',
    input: prompt ?? '',
    showTypingIndicator: true,
  })
}
</script>

<template>
  <div v-if="welcomeScreenSettings?.status" class="welcome-screen driverjs-chatbot-welcome">
    <h1
      v-if="welcomeScreenTitleSettings?.status"
      :style="widgetStore.getDynamicStylesByObject(welcomeScreenTitleSettings)"
    >
      {{ welcomeScreenTitleSettings?.text }}
    </h1>

    <div :class="{ 'mb-3': welcomeScreenDescriptionSettings?.status }"></div>

    <div
      v-if="welcomeScreenDescriptionSettings?.status"
      :style="{
        ...widgetStore.getDynamicStylesByObject(welcomeScreenDescriptionSettings),
        lineHeight: `${welcomeScreenDescriptionSettings.size}px !important`,
      }"
      v-html="welcomeScreenDescriptionSettings?.text"
    />

    <div :class="{ 'mb-3': activeCards.length }"></div>

    <div
      v-if="activeCards"
      class="chat-cards columns w-100 is-multiline has-text-centered driverjs-chatbot-cards is-vcentered is-centered"
    >
      <div
        v-for="(card, index) of activeCards"
        :key="index"
        class="column"
        :style="{ margin: '0.75rem' }"
      >
        <div
          :style="widgetStore.getDynamicStylesByObject(welcomeScreenCardSettings?.styling?.title)"
          class="mb-5"
        >
          {{ card?.title }}
        </div>

        <div class="columns is-multiline is-vcentered is-centered">
          <div
            v-for="(prompt, index2) of card?.prompts"
            :key="index2"
            class="column is-12 card is-clickable mb-4"
            :style="
              widgetStore.getDynamicStylesByObject(welcomeScreenCardSettings?.styling?.prompt)
            "
            @click="onClickPrompt(prompt?.prompt)"
          >
            <p>
              {{ prompt?.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

/*
export interface VIconProps {
  icon: string
  inline: boolean
  width: string | number
  height: string | number
  horizontalFlip: boolean
  verticalFlip: boolean
  flip: string
  rotate: number | string
  color: string
}
*/

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  inline: {
    type: Boolean,
    required: false,
    default: true,
  },
  width: {
    type: [String, Number],
    required: false,
    default: '1.25em',
  },
  height: {
    type: [String, Number],
    required: false,
    default: '1.25em',
  },
  horizontalFlip: {
    type: Boolean,
    required: false,
  },
  verticalFlip: {
    type: Boolean,
    required: false,
  },
  color: {
    type: String,
    required: false,
    default: '',
  },
  isClickable: {
    type: Boolean,
    required: false,
  },
})

const isIconify = computed(() => {
  return props.icon && props.icon.indexOf(':') !== -1
})
</script>

<template>
  <Iconify
    v-if="isIconify"
    :icon="props.icon"
    :inline="props.inline"
    :width="props.width"
    :height="props.height"
    :horizontal-flip="props.horizontalFlip"
    :vertical-flip="props.verticalFlip"
    :class="[props.color === '' && 'primary', props.isClickable && 'clickable']"
    class="icon"
    :style="{
      width: props.width && `${props.width}`,
      height: props.height && `${props.height}`,
      color: props.color && `${props.color}`,
    }"
  />
  <i
    v-else
    aria-hidden="true"
    :class="props.icon"
    :style="{
      width: props.width && `${props.width}`,
      height: props.height && `${props.height}`,
      color: props.color && `${props.color}`,
    }"
  >
  </i>
</template>

<style lang="scss">
.primary {
  color: var(--primary);
}

.clickable {
  cursor: pointer;
}
</style>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { ref } from 'vue'
import Topup from './topup.vue'
import Invoices from '/@src/pages/subscriptions/invoices.vue'
import PricingTable from '/@src/pages/subscriptions/pricing-table.vue'
import Subscriptions from '/@src/pages/subscriptions/subscriptions.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Reactive data
const tabs = ref([
  {
    label: 'Subscriptions',
    value: 'subscriptions',
    icon: ICONS.pages.subscriptions,
    component: Subscriptions,
  },

  {
    label: 'Auto Top-Up',
    value: 'Auto Top-Up',
    icon: ICONS.pages.top_up,
    component: Topup,
  },
  {
    label: 'Invoices',
    value: 'invoices',
    icon: ICONS.pages.invoices,
    component: Invoices,
  },
  {
    label: 'Full Pricing',
    value: 'pricing_table',
    icon: ICONS.pages.pricing,
    component: PricingTable,
  },
])
const title = ref('Subscriptions')
const selected = ref(tabs.value[0]?.value)

// Composable
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title: title.value })
</script>

<template>
  <VCard class="h-100 p-0">
    <VTabs v-model:selected="selected" :tabs="tabs" :vertical="true" :data="{}" :alwaysOpen="true">
      <template #title>
        <VBlock>
          <h1 class="title is-5">Menu</h1>
        </VBlock>
      </template>
    </VTabs>
  </VCard>
</template>

<script setup lang="ts"></script>

<template>
  <CustomForm title="JS events we send">
    <template #body>
      <div class="table-container">
        <table class="table w-100">
          <thead>
            <tr>
              <th>Trigger</th>
              <th>Action</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Click</td>
              <td>click_talk_to_human</td>
              <td>“Talk to human” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_lead_form</td>
              <td>“Contact” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_question_answer</td>
              <td>“Q&A” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_button</td>
              <td>“Marketing” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_sequence</td>
              <td>“Sequence” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_mark_as_resolve</td>
              <td>“Mark as resolve” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_transcribe</td>
              <td>“Transcribe” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_card</td>
              <td>“Card” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_copy</td>
              <td>“Copy” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_recording</td>
              <td>“Speak to AI” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_synthesize</td>
              <td>“Listen to AI” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_share</td>
              <td>“Share chat” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_reset</td>
              <td>“Clear chat” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_stop</td>
              <td>“Stop chat” button clicked</td>
            </tr>
            <tr>
              <td>Click</td>
              <td>click_feedback</td>
              <td>“Feedback” button clicked</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_chat_message</td>
              <td>“Chat message” submitted</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_chat_unlock</td>
              <td>“Chat unlock” form submitted</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_contact</td>
              <td>“Lead form” submitted</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_transcribe</td>
              <td>Transcription submitted</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_account_register</td>
              <td>“Account registration” form submitted</td>
            </tr>
            <tr>
              <td>Submit</td>
              <td>submit_account_login</td>
              <td>“Account login” form submitted</td>
            </tr>
            <tr>
              <td>Load</td>
              <td>load_chatbot</td>
              <td>“Chatbot” loaded</td>
            </tr>
            <tr>
              <td>Open</td>
              <td>open_chat_bubble</td>
              <td>“Chat bubble” opened</td>
            </tr>
            <tr>
              <td>Close</td>
              <td>close_chat_bubble</td>
              <td>“Chat bubble” closed</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b>Emit example</b><br /><br />
      <pre style="white-space: normal">
        window.addEventListener('message', (event) => { const action = event.data.action; const data
        = event.data.data; if (event.data === 'click_talk_to_human') { console.log('My user clicked
        the talk to human action', data) } })
      </pre>
    </template>
  </CustomForm>

  <CustomForm title="JS events we catch">
    <template #body>
      <div class="table-container">
        <table class="table w-100">
          <thead>
            <tr>
              <th>Action</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>send_open_chat_bubble</td>
              <td>Open the “Chat bubble”.</td>
            </tr>
            <tr>
              <td>send_close_chat_bubble</td>
              <td>Close the “Chat bubble”.</td>
            </tr>
            <tr>
              <td>send_dynamic_context</td>
              <td>Override the knowledge dataset.</td>
            </tr>
            <tr>
              <td>send_dynamic_question</td>
              <td>Override the user question.</td>
            </tr>
            <tr>
              <td>send_dynamic_system_behavior</td>
              <td>Override the default "AI Persona" in your "Chatbot Settings".</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b>Catch example</b><br /><br />
      <pre style="white-space: normal">
        window.postMessage({ action: 'send_open_chat_bubble', data: {} }, '*');
      </pre>
    </template>
  </CustomForm>
</template>

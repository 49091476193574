<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

onBeforeMount(async () => {
  await router.push({
    path: '/auth/access',
  })
})
</script>

<template>
  <div>Redirecting...</div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getNode } from '@formkit/core'
import { ConfettiHelper, EventHelper, HttpHelper } from '/@src/helpers'
import { PERSONAS, PURPOSES } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalFormProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { open } = props.modalFormProps

// Const data
const formId = 'formChat'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})

// Computed data
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const computedPrompSystem = computed(() => {
  const purpose = formData.value?.purpose as keyof typeof PERSONAS

  return purpose ? PERSONAS[purpose] : ''
})

// Composable
const router = useRouter()
const userSession = useUserSession()

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true

    formData.value = {
      label: null,
      purpose: 'support',
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { label, prompt_system, model_uid, purpose, how_did_you_find_us } = fields

    const response = await HttpHelper.post('/widgets', null, {
      label,
      prompt_system,
      model_uid,
      purpose,
      how_did_you_find_us,
    })

    ConfettiHelper.realistic()
    EventHelper.push('created_chatbot')

    await router.push({
      path: `/chatbots/${response?.uid}`,
    })
  } catch (error) {
    console.error(error)

    emit('submit', error)
  } finally {
    isLoading.value = false
  }
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}
</script>

<template>
  <CustomModal
    size="big"
    :open="open"
    title="Let's start by creating an AI chatbot!"
    :override-modal="false"
    :noscroll="false"
    :noclose="true"
    :remove-close-btn="userSession?.usage?.widgets?.consumed === 0"
    @close="closeModal"
  >
    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

      <FormKit
        type="text"
        label="What is your AI chatbot name?"
        name="label"
        validation="required:trim"
        placeholder="Chatbot #1"
      />

      <FormKit
        type="togglebuttons"
        label="What is the purpose of your chatbot?"
        placeholder="Select a purpose"
        validation="required:trim"
        name="purpose"
        :options="PURPOSES"
        :disabled="isLoading"
        enforced
      />

      <FormKit
        v-model="computedPrompSystem"
        type="textarea"
        label="AI persona"
        name="prompt_system"
        validation="trim"
        :placeholder="`Examples of GOOD AI personas:\n(1) As a representative of InsertChat, your role is to embody the values and strengths of our startup while promoting our product in the most favorable light. Avoid discussing competitors or making negative remarks about our company. Your focus should be on showcasing the excellence of InsertChat and its offerings.\n(2) Act like you are Elon Musk, the visionary entrepreneur and CEO of SpaceX and Tesla. Capture his unique blend of optimism, innovation, and occasional humor. Feel free to include references to current events or developments in the tech and space industries.`"
        :help="`The AI persona define the default behavior of your AI, to provide a more customized experience, allowing your chatbot to act in a manner that aligns with your specific requirements and preferences.`"
        :disabled="isLoading"
        rows="6"
      />

      <FormKit
        v-if="!userSession?.user?.survey?.how_did_you_find_us"
        name="how_did_you_find_us"
        type="textarea"
        label="How did you hear about us?"
        :disabled="isLoading"
      />
    </FormKit>

    <template #action>
      <div class="form-footer">
        <div class="left"></div>

        <div class="right">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Create my AI
          </VButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

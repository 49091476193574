<script setup lang="ts">
import { reactive, ref, watchEffect } from 'vue'

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  help: {
    type: String,
    required: false,
    default: '',
  },
  isRequired: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  digitCount: {
    type: Number,
    required: true,
  },
})

// Reactive data
const digits = reactive(new Array(props.digitCount).fill(''))
const otpContRef = ref(null)
const isRequired = ref(props.isRequired)

// Watch
watchEffect(() => {
  const modelValue = props.modelValue || ''
  digits.forEach((_, index) => {
    digits[index] = modelValue[index] || ''
  })
})

// Functions
const handleKeyDown = (event: KeyboardEvent, index: number) => {
  if (!['Tab', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
    event.preventDefault()
  }

  if (event.key === 'Backspace') {
    digits[index] = ''
    if (index > 0 && otpContRef.value) {
      otpContRef.value.children[index - 1].focus()
    }
  } else if (/^[0-9]$/.test(event.key)) {
    digits[index] = event.key
    if (index < props.digitCount - 1 && otpContRef.value) {
      otpContRef.value.children[index + 1].focus()
    }
  }

  emit('update:modelValue', digits.join(''))
  isRequired.value = digits.join('').length === props.digitCount
}
</script>

<template>
  <div class="formkit-outer">
    <div class="formkit-wrapper">
      <label class="formkit-label">
        {{ props.label }}
        <span v-if="isRequired" style="color: var(--danger)"> *</span>
      </label>
      <div class="formkit-inner" style="box-shadow: none">
        <div ref="otpContRef">
          <input
            v-for="(el, index) in digits"
            :key="index"
            v-model="digits[index]"
            maxlength="1"
            class="digit-box formkit-digit"
            type="text"
            :class="{ bounce: digits[index] !== '' }"
            :required="isRequired"
            @keydown="handleKeyDown($event, index)"
          />
        </div>
      </div>
    </div>
    <div v-if="props.help" class="formkit-help">{{ props.help }}</div>
  </div>
</template>

<style lang="scss">
.formkit-digit {
  color: var(--dark-text);
  appearance: none;
  padding: 0.5em;
  box-sizing: border-box;
  width: 2em;
  margin-right: 0.25em;
  text-align: center;
  border: 1px solid var(--border);
  border-radius: var(--fk-border-radius);
}
</style>

<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref, Ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { SttHelper, UrlHelper, WindowHelper } from '/@src/helpers'
import { IFile, IQueryParameters } from '/@src/interfaces/index'
import { ICONS } from '/@src/resources/files/constant'
import { useWidget } from '/@src/stores'

// Reactive data
const input = ref<string>('')
const contentRef = ref<HTMLElement | null>(null)
const userMessageStylingSettings = ref<Record<string, any>>({})
const isLoading = ref(false)
const stt = new SttHelper()
const recordingPermission: Ref<MediaStream | null> = ref(null)
const isRecording = ref(false)
const isFocused = ref(false)
const permissionLoading = ref(false)

// Composable
const widgetStore = useWidget()
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})

// Computed
const computedFocus = computed(() => {
  const { focus, botAutofocus }: IQueryParameters = UrlHelper.getWidgetQueryParams()

  return focus ?? botAutofocus
})

// Lifecycle hooks
onBeforeMount(() => {
  userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
})

onMounted(async () => {
  try {
    nextTick(() => {
      if (computedFocus.value && contentRef.value) {
        // @ts-ignore
        contentRef.value?.focus()
      }
    })
  } catch (error) {
    console.error(error)
  }
})

// Functions
const askForPermission = async (): Promise<MediaStream | null> => {
  try {
    permissionLoading.value = true
    recordingPermission.value = await stt.askForPermission()

    if (!recordingPermission.value) {
      notyf.info({
        message: 'Microphone permission required!',
        icon: false,
      })
    }

    permissionLoading.value = false

    return recordingPermission.value
  } catch (error: any) {
    permissionLoading.value = false

    console.error('askForPermission Error:', error)
    notyf.error(error?.message || 'Error requesting microphone permission.')

    return null
  }
}

const startRecording = async () => {
  try {
    if (!(await askForPermission())) {
      return
    }

    if (
      isRecording.value ||
      !isFocused.value ||
      permissionLoading.value ||
      !recordingPermission.value
    ) {
      return
    }

    input.value = ''
    isRecording.value = true
    isLoading.value = false

    WindowHelper.postMessage('click_recording', {
      widget_uid: widgetStore.state.widget?.uid,
      chat_uid: widgetStore.state.chat?.uid,
      account_uid: widgetStore.state.account?.uid,
    })

    await stt.startRecording()
  } catch (error: any) {
    console.error('startRecording Error:', error)

    input.value = ''
    isRecording.value = false
    isLoading.value = false

    notyf.error(error?.message || 'Error starting recording.')
  }
}

const stopRecording = async () => {
  if (!isRecording.value || isFocused.value || permissionLoading.value) {
    return
  }

  try {
    isRecording.value = false

    if (widgetStore.getWidget()?.app_uid) {
      const { blob, extension } = await stt.stopRecording()

      isRecording.value = false
      isLoading.value = true
      input.value = ''

      try {
        if (blob && extension) {
          const fileName = `message.${extension}`
          const blobUrl = URL.createObjectURL(blob)

          const filesResponse: IFile[] = await widgetStore.createFiles({
            type: 'audio',
            files: [{ file: new File([blob], fileName), blob_url: blobUrl }],
            transcribe: true,
          })

          await widgetStore.createMessage({
            role: 'user',
            input: filesResponse[0]?.transcription ?? '',
            files: filesResponse,
            showTypingIndicator: true,
          })

          URL.revokeObjectURL(blobUrl)
        }
      } catch (error) {
        console.error('stopRecording - File Creation/Error:', error)
      } finally {
        isRecording.value = false
        isLoading.value = false
        input.value = ''
      }
    }
  } catch (error: any) {
    console.error('stopRecording Error:', error)

    isLoading.value = false
    isRecording.value = false
    input.value = ''

    notyf.error(error?.message || 'Error stopping recording.')
  }
}

// Event Handlers
const handleDown = async () => {
  isFocused.value = true
  await startRecording()
}

const handleUp = async () => {
  isFocused.value = false
  await stopRecording()
}
</script>

<template>
  <div
    v-if="permissionLoading && !recordingPermission"
    class="button"
    :class="{ 'is-vocal-only': widgetStore.getWidget()?.is_vocal_only, 'disabled': true }"
    :style="{
      ...widgetStore.computedIconStyle,
      width: '33px',
      height: '33px',
    }"
  >
    <VIcon :icon="ICONS.actions.waiting" class="chat-icons-color" />
  </div>

  <div
    v-else
    class="button"
    :class="{
      'disabled': isLoading,
      'is-vocal-only': widgetStore.getWidget()?.is_vocal_only,
    }"
    :style="{
      ...widgetStore.computedIconStyle,
      width: '33px',
      height: '33px',
    }"
    @pointerdown="handleDown"
    @pointerup="handleUp"
    @pointerleave="handleUp"
    @pointerout="handleUp"
    @pointermove="handleUp"
    @focusout="handleUp"
  >
    <VIcon
      :icon="ICONS.actions.record"
      class="chat-icons-color"
      :style="{
        color: isRecording ? 'var(--mic-recording) !important' : '',
      }"
    />
  </div>

  <div v-if="widgetStore.getWidget()?.is_vocal_only" class="mt-2">
    <i> Push-to-talk </i>
  </div>
</template>

export default class GtmHelper {
  public static config(user: any) {
    try {
      window?.dataLayer?.push(null, user)
      window?.dataLayer?.push('user', user)
    } catch (error) {
      console.error(error)
    }
  }

  public static push = (event: string, data: object = {}): void => {
    try {
      if (event) {
        window?.dataLayer?.push({ event, ...data })
      } else {
        window?.dataLayer?.push(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
}

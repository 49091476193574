import hljs from 'highlight.js'
import { marked } from 'marked'
// @ts-ignore
import markedBidi from 'marked-bidi'
import { markedHighlight } from 'marked-highlight'
// @ts-ignore
// import extendedTables from 'marked-extended-tables'
// @ts-ignore
import { markedSmartypants } from 'marked-smartypants'
import { v4 as uuid } from 'uuid'

marked.use({
  gfm: true,
  breaks: true,
})
// marked.use(extendedTables())
marked.use(markedSmartypants())
marked.use(markedBidi())
marked.use(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext'

      return hljs.highlight(code, { language }).value
    },
  })
)

const renderer = new marked.Renderer()

// Enhanced link renderer
renderer.link = function ({ href, title, text }) {
  if (!href) {
    return text || ''
  }

  const createLink = (url: string, linkText: string, linkTitle: string, type: string) => {
    const classes = ['styled-link', `link-${type}`]
    const dataAttr = `data-link-type="${type}"`
    const target = type !== 'tel' ? 'target="_blank" rel="noopener noreferrer"' : ''
    const icon = getIconForLinkType(type)

    return `<a href="${url}" title="${linkTitle}" class="${classes.join(
      ' '
    )}" ${dataAttr} ${target}>
      ${icon}<span class="link-text">${linkText}</span>
    </a>`
  }

  const getIconForLinkType = (type: string) => {
    const icons: { [key: string]: string } = {
      email:
        '<svg class="link-icon" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>',
      tel: '<svg class="link-icon" viewBox="0 0 24 24"><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>',
      url: '<svg class="link-icon" viewBox="0 0 24 24"><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>',
    }

    return icons[type] || ''
  }

  if (href.startsWith('mailto:')) {
    const email = href.substring(7).trim()
    return createLink(href, text || email, `Email: ${email}`, 'email')
  }

  if (href.startsWith('tel:')) {
    const phone = href.substring(4).trim()
    const formattedPhone = phone.replace(/[^\d+]/g, '')
    return createLink(
      `tel:${formattedPhone}`,
      text || formatPhoneNumber(phone),
      `Call: ${phone}`,
      'tel'
    )
  }

  try {
    const url = href.startsWith('http') ? href : `https://${href}`
    const parsedUrl = new URL(url)
    const domain = parsedUrl.hostname

    return createLink(url, text || domain, title || url, 'url')
  } catch (error) {
    return text || href
  }
}

function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = phoneNumberString.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return phoneNumberString
}

export default class StringHelper {
  public static isValidUrl(url: string): boolean {
    try {
      const parsedUrl = new URL(url)

      return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
    } catch (error) {
      return false
    }
  }

  public static formatNumberOrPrice(
    value: number | string,
    isPrice: boolean = false
  ): string | undefined {
    if (!value && value !== 0) {
      return // Handle undefined or null values
    }

    let numericValue = typeof value === 'number' ? value : parseFloat(value as string)

    if (isNaN(numericValue)) {
      return 'Invalid input'
    }

    const suffixes = ['', 'K', 'M', 'B', 'T']

    let suffixIndex = 0

    while (numericValue >= 1000 && suffixIndex < suffixes.length - 1) {
      numericValue /= 1000
      suffixIndex++
    }

    const formattedValue = isPrice
      ? numericValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : numericValue % 1 === 0
        ? numericValue.toFixed(0)
        : numericValue.toFixed(1)

    return isPrice ? formattedValue + suffixes[suffixIndex] : formattedValue + suffixes[suffixIndex]
  }

  public static capitalizeFirstLetter(myString: string) {
    return myString?.charAt(0)?.toUpperCase() + myString?.slice(1)
  }

  public static getRandomString(maxCharCount: number = 36) {
    return uuid().substring(0, maxCharCount)
  }

  public static subString(myString: string, maxCharCount: number) {
    if (myString.length > maxCharCount) {
      return myString.substring(0, maxCharCount) + '...'
    }

    return myString
  }

  public static getImageUrl = (url: string) => {
    let image = ''

    if (url) {
      if (url.includes('http')) {
        image = url
      } else {
        image = import.meta.env.VITE_S3_PUBLIC + '/' + url
      }
    }

    return image
  }

  public static getDomainFromUrl(params: {
    url: string
    includeSubdomain: boolean
    includeProtocol: boolean
  }) {
    const { url, includeSubdomain, includeProtocol } = params

    const includePort = true

    let domain = ''
    let protocol = ''

    // Extract the protocol if required
    if (includeProtocol) {
      const matchProtocol = url.match(/^(https?:\/\/)/i)
      protocol = matchProtocol ? matchProtocol[1] : ''
    }

    // Remove the protocol if not required
    if (!includeProtocol) {
      domain = url.replace(/^(https?:\/\/)?/, '')
    }

    // Get the domain and port from the URL
    const matches = url.match(/^(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)(?::(\d+))?/i)
    const port = matches && matches[2] ? `:${matches[2]}` : ''
    domain = matches ? matches[1] : ''

    // Remove subdomain if not required
    if (!includeSubdomain && domain !== null) {
      const domainParts = domain.split('.')
      if (domainParts.length > 2) {
        return protocol + domainParts.slice(1).join('.') + (includePort ? port : '')
      }
    }

    return protocol + domain + (includeProtocol && port ? port : '')
  }

  // Function to wrap a table element with a div and add classes
  public static wrapTableWithDiv(tableElement: HTMLElement) {
    const classes = [
      'table',
      'is-bordered',
      'is-fullwidth',
      'is-hoverable',
      'is-striped',
      'is-narrow',
    ]

    classes.forEach((item) => {
      tableElement.classList.add(item)
    })

    const div = document.createElement('div')
    div.className = 'table-container'

    const parent = tableElement.parentNode

    if (parent) {
      parent.replaceChild(div, tableElement)
      div.appendChild(tableElement)
    }
  }

  public static convertMdToHtml(text: string, settings?: { target: string }) {
    if (!text) {
      return ''
    }

    const tempElement = document.createElement('div')
    tempElement.innerHTML = marked(text, { renderer })

    const tableElements = tempElement.querySelectorAll('table')
    tableElements.forEach((tableElement) => {
      StringHelper.wrapTableWithDiv(tableElement)
    })

    if (settings && settings?.target) {
      const searchValue = settings && settings?.target === '_blank' ? '_parent' : '_blank'
      const regex = new RegExp(searchValue, 'g')
      tempElement.innerHTML = tempElement.innerHTML.replace(regex, settings?.target)
    }

    return tempElement.innerHTML
  }

  public static convertToVariableName(text: string) {
    const accentMap: any = {
      À: 'A',
      Á: 'A',
      Â: 'A',
      Ã: 'A',
      Ä: 'A',
      Å: 'A',
      à: 'a',
      á: 'a',
      â: 'a',
      ã: 'a',
      ä: 'a',
      å: 'a',
      È: 'E',
      É: 'E',
      Ê: 'E',
      Ë: 'E',
      è: 'e',
      é: 'e',
      ê: 'e',
      ë: 'e',
      Ì: 'I',
      Í: 'I',
      Î: 'I',
      Ï: 'I',
      ì: 'i',
      í: 'i',
      î: 'i',
      ï: 'i',
      Ò: 'O',
      Ó: 'O',
      Ô: 'O',
      Õ: 'O',
      Ö: 'O',
      Ø: 'O',
      ò: 'o',
      ó: 'o',
      ô: 'o',
      õ: 'o',
      ö: 'o',
      ø: 'o',
      Ù: 'U',
      Ú: 'U',
      Û: 'U',
      Ü: 'U',
      ù: 'u',
      ú: 'u',
      û: 'u',
      ü: 'u',
      Ý: 'Y',
      ý: 'y',
      ÿ: 'y',
      Ç: 'C',
      ç: 'c',
      Ñ: 'N',
      ñ: 'n',
    }

    let _text = text.trim()

    // Normalize the text to Unicode NFC form
    _text = _text.normalize('NFC')

    // Remove special characters, convert to lowercase, and replace accented characters
    _text = _text
      .replace(/[^\w\s]|./g, function (matched) {
        return accentMap[matched] || matched
      })
      .toLowerCase()

    // Replace non-letter characters with underscores
    _text = _text.replace(/[^a-zA-Z]+/g, '_')

    // Replace consecutive underscores with a single underscore
    _text = _text.replace(/^_+|_+$/g, '').replace(/_+/g, '_')

    return _text
  }
}

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Composable
const viewWrapper = useViewWrapper()

// Reactive data
const isLoading = ref(false)

// Const data
const title = 'Invoices'
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })
/*
const columns = {
  number: {
    label: 'Number',
    sortable: false,
    searchable: false,
    grow: false,
  },
  subtotal_excluding_tax: {
    label: 'Total',
    sortable: false,
    searchable: false,
    grow: false,
  },
  tax: { label: 'Tax', sortable: false, searchable: false, grow: false },
  period: { label: 'Period', sortable: false, searchable: false, grow: false },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const
const url = '/subscriptions/get-invoices'
*/

// Lifecycle hooks
onMounted(async () => {
  try {
    const { url } = await HttpHelper.get(`/subscriptions/create-portal-session`, {
      withoutApp: false,
    })

    if (url) {
      window.location.href = url
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
/*
const openPage = (url: string) => {
  window.open(url, '_blank')
}
*/
</script>

<template>
  <VLoader v-if="true" class="m-auto" :translucent="false" :opaque="false" text="Redirecting..." />

  <!--
  <CustomTable
    v-model:override-loading="isLoading"
    :refresh="false"
    :columns="columns"
    :url="url"
    :enable-wrapper-class="true"
  >
    <template #row="{ row }">
      <VFlexTableCell :column="columns.number">
        <div>
          <VTextEllipsis width="200px" class="item-name dark-text">
            {{ row?.lines?.data[0]?.description ?? '' }}
          </VTextEllipsis>

          <VTextEllipsis class="light-text">
            {{ row?.number }}
          </VTextEllipsis>
        </div>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.subtotal_excluding_tax">
        <VTextEllipsis width="200px">
          ${{ row?.subtotal_excluding_tax / 100 ?? '' }}
        </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.tax">
        <VTextEllipsis width="200px"> ${{ row?.tax / 100 ?? '' }} </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.period">
        <span>
          {{
            DateHelper.formatDate(
              DateHelper.timestampToDate(row?.lines?.data[0]?.period?.start),
              'MM-dd-yyyy'
            )
          }}
          -
          {{
            DateHelper.formatDate(
              DateHelper.timestampToDate(row?.lines?.data[0]?.period?.end),
              'MM-dd-yyyy'
            )
          }}
        </span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton
          :icon="ICONS.tables.open"
          class="pr-3 pl-3 mr-2 no-line-height"
          @click="openPage(row?.hosted_invoice_url)"
        >
          Details
        </VButton>

        <VButton
          :icon="ICONS.actions.download"
          class="pr-3 pl-3 mr-2 no-line-height"
          @click="openPage(row?.invoice_pdf)"
        >
          Download
        </VButton>
      </VFlexTableCell>
    </template>
  </CustomTable>
  -->
</template>

import { DateTime } from 'luxon'
import DateHelper from './date'

export default class StripeHelper {
  public static getDaysUntilTrialExpiration(subscription: any) {
    if (subscription && subscription?.status === 'trialing') {
      const timeDiff = DateTime.fromSeconds(subscription.trial_end)
        .diff(DateHelper.getNow(), 'days')
        .as('days')

      return Math.ceil(timeDiff)
    }

    return -1
  }

  public static isSubscriptionActive(subscription: any) {
    if (!subscription) {
      return false
    }

    const status = subscription?.status
    const pauseCollection = subscription?.pause_collection
    // const currentUnixTime = Math.floor(Date.now() / 1000)

    if (
      (status === 'active' && pauseCollection === null) ||
      // (status === 'active' && subscription?.current_period_end > currentUnixTime) ||
      (status === 'trialing' && StripeHelper.getDaysUntilTrialExpiration(subscription) > 0)
    ) {
      return true
    }

    return false
  }
}

<script setup lang="ts">
import { useUserSession } from '/@src/stores'

// Composable
const userSession = useUserSession()
</script>

<template>
  <div v-if="userSession?.app?.support_id" class="column p-0">
    <VTag color="orange">
      {{ `Support ID: ${userSession?.app?.support_id}` }}
    </VTag>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { EventBusHelper, HttpHelper } from '/@src/helpers'
import { ConfettiHelper } from '/@src/helpers'
import { IOnboardingStep } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'

// Const data
const uid: any = useRoute().params?.uid ?? undefined
const baseUrl = `/chatbots/${uid}`

// Reactive data
const isLoading = ref(false)
const isHidden = ref(false)
const steps = ref<Record<string, IOnboardingStep>>({
  created_message: {
    key: 'created_message',
    text: '🚀 Send Your First Message',
    completed: false,
    path: `${baseUrl}?tab=playground&subtab=preview`,
    description: 'Test your chatbot by sending a message.',
  },
  created_training: {
    key: 'created_training',
    text: '📚 Add Your First Knowledge',
    completed: false,
    path: `${baseUrl}?tab=knowledge&action=open_form`,
    description: 'Upload documents or enter custom information to train your chatbot.',
  },
  change_interface: {
    key: 'change_interface',
    text: '🎨 Customize The Logos',
    completed: false,
    path: `${baseUrl}?tab=interface&subtab=images`,
    description: "Customize your chatbot's appearance with your brand logo.",
  },
  change_settings: {
    key: 'change_settings',
    text: '👋 Add Your Personalized Greetings',
    completed: false,
    path: `${baseUrl}?tab=settings&subtab=greetings`,
    description: 'Personalize your chatbot’s welcome messages for a great first impression.',
  },
})

// Composable
const router = useRouter()

// Events
EventBusHelper.getGlobalEventBus().on((data) => {
  const { event, payload } = data || {}

  if (event && payload) {
    if (steps.value[event] && !steps.value[event].completed) {
      steps.value[event].completed = true
      ConfettiHelper.realistic()
    }
  }
})

// Hooks
onMounted(async () => {
  await loadStepStatus()
})

// Computed
const allStepsCompleted = computed(() => {
  return Object.values(steps.value).every((step) => step.completed)
})

const currentStep = computed(() => {
  const stepArray = Object.values(steps.value)
  const incompleteIndex = stepArray.findIndex((step) => !step.completed)

  return incompleteIndex === -1 ? stepArray.length : incompleteIndex
})

const progress = computed(() => {
  const completedSteps = Object.values(steps.value).filter((step) => step.completed).length

  return (completedSteps / Object.keys(steps.value).length) * 100
})

// Functions
const loadStepStatus = async () => {
  try {
    const onboardingResponse = await HttpHelper.get(`/widgets/onboarding-status/${uid}`)

    const { created_message, created_training, change_interface, change_settings } =
      onboardingResponse || {}

    const data: Record<keyof typeof steps.value, boolean> = {
      created_message,
      created_training,
      change_interface,
      change_settings,
    }

    for (const step of Object.values(steps.value)) {
      step.completed = data[step?.key] || false
    }

    if (allStepsCompleted.value) {
      isHidden.value = true
    }
  } catch (error) {
    console.error(error)
  }
}

const goToStep = (path: string) => {
  window.location.href = path

  return
}
</script>

<template>
  <VCard class="onboarding mb-5" v-if="!isHidden">
    <div v-if="allStepsCompleted">
      <div class="completion-animation">
        <p class="title is-4">Onboarding Complete 🎉</p>
        <VProgress :value="100" color="success" class="mb-5" />

        <p class="subtitle is-5">Your AI Chatbot is all set and ready to go.</p>
        <VButton
          type="button"
          color="primary"
          @keyup.enter="goToStep(`${baseUrl}?tab=playground&subtab=install`)"
          @click="goToStep(`${baseUrl}?tab=playground&subtab=install`)"
          :disabled="isLoading"
        >
          Install Now
        </VButton>
      </div>
    </div>

    <template v-else>
      <p class="title is-4">Welcome to Your AI Chatbot Onboarding Setup!</p>

      <VProgress :value="progress" color="success" class="mb-5" />

      <div
        v-for="(step, key) in steps"
        :key="key"
        class="step-item"
        :class="{
          completed: step.completed,
          current: Object.values(steps).findIndex((s) => s.key === key) === currentStep,
        }"
      >
        <div class="columns is-multiline is-vcentered">
          <div class="column is-narrow pb-0">
            <VIcon
              :inline="true"
              :color="step.completed ? '#05d69e' : '#0e111b'"
              :icon="step.completed ? ICONS.actions.check : ICONS.actions.waiting_2"
            />
          </div>

          <div class="column is-narrow">
            <h3 class="title is-5">{{ step.text }}</h3>
            <p class="subtitle is-6">{{ step.description }}</p>
          </div>

          <div class="column is-12-mobile has-text-right" v-if="!step.completed">
            <VButton
              type="button"
              color="primary"
              @keyup.enter="goToStep(step?.path)"
              @click="goToStep(step?.path)"
              :disabled="isLoading"
            >
              Start
            </VButton>
          </div>
        </div>
      </div>
    </template>
  </VCard>
</template>

<style lang="scss" scoped>
.onboarding {
  .completed {
    background-color: rgba(76, 175, 80, 0.1);
  }

  .current {
    background-color: var(--light-grey);
  }

  .step-item {
    margin-bottom: 12px;
    border-radius: var(--radius-large);
    transition: all 0.3s ease;
  }

  .completion-animation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeInOverlay 0.5s forwards;
    z-index: 1000;
  }

  .completion-animation {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    text-align: center;
    animation: scaleIn 0.5s forwards;
  }

  @keyframes fadeInOverlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>

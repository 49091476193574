<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { CookiesHelper } from '/@src/helpers'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Const data
const formId = 'chatAccountForm'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const step = ref(1)
const emailSent = ref(false)
const accountsAgent = ref()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const enabledInputs = computed(() => {
  const _inputs: any = []

  for (const input of Object.values(accountsAgent.value?.inputs)) {
    // @ts-ignore
    if (input?.status) {
      _inputs.push(input)
    }
  }

  return _inputs
})

// Lifecycle hooks
onBeforeMount(() => {
  accountsAgent.value = widgetStore.getAccountsAgent()
})

onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

  const cookieName = widgetStore.getCookieName('account')

  try {
    isLoading.value = true

    await widgetStore.loginAccount(null)
  } catch (error) {
    CookiesHelper.remove(cookieName)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    if (step.value === 1) {
      await widgetStore.loginAccount(fields?.token)

      // First login
      if (widgetStore.getAccount()?.uid && !Object.keys(widgetStore.getChat()).length) {
        await widgetStore.createChat()
      }
    } else {
      await widgetStore.registerAccount(fields)

      emailSent.value = true
      step.value = 1
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="chat-account-form">
    <!--
      <VLoader v-if="isLoading" />
    -->

    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <!-- Step 1 - Register -->
      <div v-if="step === 2" class="columns is-multiline">
        <div v-for="input of enabledInputs" :key="input.name" class="column is-12 pt-0">
          <FormKit
            :type="input?.type"
            :label="input?.label"
            :name="input?.name"
            :options="input?.options"
            :validation="
              input?.required
                ? `required:trim${input.validation ? `|${input.validation}` : ''}`
                : `trim${input.validation ? `|${input.validation}` : ''}`
            "
            :required="input?.required ? 'required' : ''"
            class="w-100"
          />
        </div>

        <div class="column is-12 has-text-centered">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            :style="widgetStore.getDynamicStylesByObject(accountsAgent)"
            style="border: none"
            type="submit"
            color="primary"
            class="w-100"
            @click="triggerSubmit"
          >
            {{ accountsAgent.text_button_register }}
          </VButton>
        </div>

        <div class="column is-12 has-text-centered">
          <a @click="step = 1"> {{ accountsAgent.text_registered }}</a>
        </div>
      </div>

      <!-- Step 1 - Login -->
      <div v-if="step === 1" class="columns is-multiline">
        <div v-if="emailSent" class="column is-12 mb-2">
          <p>
            {{ accountsAgent.text_email_sent }}
          </p>
        </div>

        <div class="column is-12">
          <FormKit
            type="text"
            :label="accountsAgent.text_security_token"
            name="token"
            validation="required:trim"
            class="w-100"
          />
        </div>

        <div class="column is-12 has-text-centered">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            :style="widgetStore.getDynamicStylesByObject(accountsAgent)"
            style="border: none"
            type="submit"
            color="primary"
            class="w-100"
            @click="triggerSubmit"
          >
            {{ accountsAgent.text_button_login }}
          </VButton>
        </div>

        <div class="column is-12 has-text-centered">
          <a @click="step = 2">
            {{ accountsAgent.text_not_registered }}
          </a>
        </div>
      </div>
    </FormKit>
  </div>
</template>

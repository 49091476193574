<script setup lang="ts">
import QRCode from 'qrcode.vue'
import { computed, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper } from '/@src/helpers'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => ({}),
    required: true,
  },
})

const { toClipboard } = useClipboard()
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})

const domain = ChatbotHelper.getDomain()

const embedUrl = computed(() => {
  return `${domain}/embed/${props?.widget?.uid}`
})

const recommendedViewport = computed(() => {
  return `<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0" />`
})

const taggingScriptBubble = computed(() => {
  return `<script>
ICG_BOT_ID = '${props?.widget?.uid}';
ICG_BOT_TYPE = 'bubble';
ICG_BOT_HEIGHT = 750;
ICG_BOT_BG_COLOR = '#fff';
ICG_BOT_AUTOFOCUS = false;
ICG_BOT_OVERRIDE_OPENER = '';
ICG_USER_ID = '';
ICG_USER_EMAIL = '';
ICG_USER_FIRSTNAME = '';
ICG_USER_LASTNAME = '';
ICG_USER_TAGS = [];
ICG_USER_METADATA = {};
<\/script>`.trim()
})

const taggingScriptWindow = computed(() => {
  return `<script>
ICG_BOT_ID = '${props?.widget?.uid}';
ICG_BOT_TYPE = 'window';
ICG_BOT_HEIGHT = 750;
ICG_BOT_BG_COLOR = '#fff';
ICG_BOT_AUTOFOCUS = false;
ICG_BOT_OVERRIDE_OPENER = '';
ICG_USER_ID = '';
ICG_USER_EMAIL = '';
ICG_USER_FIRSTNAME = '';
ICG_USER_LASTNAME = '';
ICG_USER_TAGS = [];
ICG_USER_METADATA = {};
<\/script>`.trim()
})

const chatbotScriptBubble = computed(() => {
  const script = `<script type="text/javascript" src="${domain}/widgets/chatbot.js" async><\/script>`
  const tags = taggingScriptBubble.value

  return `${script}\n${tags}`
})

const chatbotScriptWindow = computed(() => {
  const script = `<script type="text/javascript" src="${domain}/widgets/chatbot.js" async><\/script>`
  const tags = taggingScriptWindow.value

  return `${script}\n${tags}`
})

const iframeDiv = computed(() => {
  return `<div id="my-ai-chatbot"></div>`
})

const iframeTag = computed(() => {
  return `<iframe
allowfullscreen
title="AI Chatbot"
role="dialog"
id="ICG-iframe"
allow="accelerometer; autoplay; camera; display-capture; encrypted-media; fullscreen; gamepad; geolocation; gyroscope; hid; identity-credentials-get; idle-detection; local-fonts; magnetometer; microphone; midi; otp-credentials; payment; picture-in-picture; publickey-credentials-get; screen-wake-lock; serial; storage-access; usb; window-management; xr-spatial-tracking"
sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
src="${domain}/embed/${props?.widget?.uid}"
style="display: block; border: 0; overflow: hidden; height: 100%; width: 100%; background-color: #fff;">
</iframe>`
})

const selectedOption = ref<'bubble' | 'window' | 'iframe' | 'directUrl' | 'qrCode' | null>('bubble')

const copy = async (text: string) => {
  try {
    await toClipboard(text)
    notyf.success('Copied')
  } catch (error) {
    console.error(error)
    notyf.error('Error on copy')
  }
}

const showCode = (option: 'bubble' | 'window' | 'iframe' | 'directUrl' | 'qrCode') => {
  selectedOption.value = option
}
</script>

<template>
  <!-- Title -->
  <h1 class="title is-5">{{ title }}</h1>

  <div class="button-group is-flex is-justify-content-center my-4">
    <VButton
      icon="mdi:chat"
      variant="outlined"
      class="mx-2"
      @click="showCode('bubble')"
      :active="selectedOption === 'bubble'"
    >
      Chat Bubble
    </VButton>
    <VButton
      icon="mdi:window-restore"
      variant="outlined"
      class="mx-2"
      @click="showCode('window')"
      :active="selectedOption === 'window'"
    >
      Window Frame
    </VButton>
    <VButton
      icon="mdi:iframe"
      variant="outlined"
      class="mx-2"
      @click="showCode('iframe')"
      :active="selectedOption === 'iframe'"
    >
      Iframe
    </VButton>
    <VButton
      icon="mdi:link"
      variant="outlined"
      class="mx-2"
      @click="showCode('directUrl')"
      :active="selectedOption === 'directUrl'"
    >
      Direct URL
    </VButton>
    <VButton
      icon="mdi:qrcode"
      variant="outlined"
      class="mx-2"
      @click="showCode('qrCode')"
      :active="selectedOption === 'qrCode'"
    >
      QR Code
    </VButton>
  </div>

  <!-- Code Display Area -->
  <div class="code-section">
    <!-- Chat Bubble -->
    <CustomForm
      v-if="selectedOption === 'bubble'"
      title="Install Script for Chat Bubble"
      subtitle=""
    >
      <template #body>
        <ul>
          <li>1) Insert the "Script" tag into your page's HTML head section.</li>
          <li>2) Empty your browser cache and refresh the page.</li>
          <li>
            3) To associate metadata with each chat session initiated by your users, you can
            incorporate values into the ICG_USER_ variables.
          </li>
        </ul>
        <br />

        <FormKit
          type="textarea"
          label="Chat Bubble Script"
          v-model="chatbotScriptBubble"
          rows="15"
          :classes="{
            outer: 'mb-3',
          }"
        />

        <VButton icon="ic:round-copy-all" @click="copy(chatbotScriptBubble)" color="primary">
          Copy to Clipboard
        </VButton>
      </template>
    </CustomForm>

    <!-- Window Frame -->
    <CustomForm
      v-else-if="selectedOption === 'window'"
      title="Install Script for Window Frame"
      subtitle=""
    >
      <template #body>
        <ul>
          <li>1) Insert the "Script" tag into your page's HTML head section.</li>
          <li>2) Place the "Div" tag where you want the window to appear.</li>
          <li>3) Empty your browser cache and refresh the page.</li>
          <li>
            4) To associate metadata with each chat session initiated by your users, you can
            incorporate values into the ICG_USER_ variables.
          </li>
        </ul>
        <br />

        <FormKit
          type="textarea"
          label="Div Tag"
          v-model="iframeDiv"
          rows="1"
          :classes="{
            outer: 'mb-3',
          }"
        />

        <VButton icon="ic:round-copy-all" @click="copy(iframeDiv)" color="primary">
          Copy to Clipboard
        </VButton>

        <br /><br />

        <FormKit
          type="textarea"
          label="Window Frame Script"
          v-model="chatbotScriptWindow"
          rows="15"
          :classes="{
            outer: 'mb-3',
          }"
        />

        <VButton icon="ic:round-copy-all" @click="copy(chatbotScriptWindow)" color="primary">
          Copy to Clipboard
        </VButton>
      </template>
    </CustomForm>

    <!-- Iframe -->
    <CustomForm
      v-else-if="selectedOption === 'iframe'"
      title="Install Script for Iframe"
      subtitle=""
    >
      <template #body>
        <ul>
          <li>1) Insert the "Iframe" tag into your page where you want the chatbot to appear.</li>
          <li>2) Empty your browser cache and refresh the page.</li>
        </ul>

        <br />

        <FormKit
          type="textarea"
          label="Iframe Tag"
          v-model="iframeTag"
          rows="12"
          :classes="{
            outer: 'mb-3',
          }"
        />

        <VButton icon="ic:round-copy-all" @click="copy(iframeTag)" color="primary">
          Copy to Clipboard
        </VButton>
      </template>
    </CustomForm>

    <!-- Direct URL -->
    <CustomForm v-else-if="selectedOption === 'directUrl'" title="Direct URL" subtitle="">
      <template #body>
        <FormKit
          type="textarea"
          label="Direct URL"
          v-model="embedUrl"
          rows="1"
          :classes="{
            outer: 'mb-3',
          }"
        />

        <VButton icon="ic:round-copy-all" @click="copy(embedUrl)" color="primary">
          Copy to Clipboard
        </VButton>
      </template>
    </CustomForm>

    <!-- QR Code Section -->
    <CustomForm
      v-else-if="selectedOption === 'qrCode'"
      title="QR Code"
      subtitle="Scan the QR code to access the Direct URL on your mobile device."
    >
      <template #body>
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12">
            <QRCode
              :value="embedUrl"
              :size="200"
              render-as="svg"
              :image-settings="{ excavate: true }"
            />
          </div>
        </div>
      </template>
    </CustomForm>
  </div>

  <hr />

  <!-- Tutorials -->
  <CustomCollapse with-chevron>
    <template #collapse-item-summary>
      <h4 class="is-inline">
        <b>Tutorials & Plugins</b>
      </h4>
    </template>
    <template #collapse-item-content>
      <div class="columns is-multiline">
        <div class="column">
          <VButton
            class="w-100 mb-3"
            href="https://wordpress.org/plugins/insertchat/"
            target="_blank"
            variant="primary"
          >
            WordPress Plugin
          </VButton>
        </div>
        <div class="column">
          <VButton
            class="w-100 mb-3"
            href="https://insertchat.notion.site/Wix-1de4b38220e1430890c46372b76aaaf1"
            target="_blank"
            variant="primary"
          >
            Wix Tutorial
          </VButton>
        </div>
        <div class="column">
          <VButton
            class="w-100 mb-3"
            href="https://insertchat.notion.site/Google-Tag-Manager-6a792d15cf824b49a3574d344b081e02"
            target="_blank"
            variant="primary"
          >
            Google Tag Manager Tutorial
          </VButton>
        </div>
        <div class="column">
          <VButton
            class="w-100 mb-3"
            href="https://insertchat.notion.site/Google-Sites-d5fb1eda0e95497c8049850f1543adf4"
            target="_blank"
            variant="primary"
          >
            Google Sites Tutorial
          </VButton>
        </div>
      </div>
    </template>
  </CustomCollapse>

  <!-- Recommended Viewport Section -->
  <CustomCollapse with-chevron>
    <template #collapse-item-summary>
      <h4 class="is-inline">
        <b>Recommended: Safari viewport tag to avoid zooming on iPhone</b>
      </h4>
    </template>
    <template #collapse-item-content>
      The primary use of the viewport tag is for responsiveness and to ensure that mobile users have
      a great experience on your site.

      <br /><br />

      Paste the viewport meta tag in the HTML head section.

      <br /><br />

      <FormKit
        type="textarea"
        label="A typical mobile-optimized site contains something like the following"
        v-model="recommendedViewport"
        rows="1"
        :classes="{
          outer: 'mb-3',
        }"
      />

      <VButton icon="ic:round-copy-all" @click="copy(recommendedViewport)" color="primary">
        Copy to Clipboard
      </VButton>
    </template>
  </CustomCollapse>
</template>

<style scoped>
.tutorials-section {
  margin-bottom: 2rem;
}

.installation-code-section {
  margin-top: 2rem;
}

.button-group VButton {
  min-width: 150px;
}

.code-section {
  margin-top: 1rem;
}

hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 2rem 0;
}
</style>

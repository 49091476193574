import StringHelper from '/@src/helpers/string'

export default class ChatbotHelper {
  private static mode = import.meta.env.MODE
  private static modeScriptName = ChatbotHelper.mode === 'production' ? '' : '.dev'
  private static domain = StringHelper.getDomainFromUrl({
    url: window.location.origin,
    includeSubdomain: true,
    includeProtocol: true,
  })
  private static bubbleSrc = `${ChatbotHelper.domain}/widgets/chatbot${ChatbotHelper.modeScriptName}.js`
  public static allowedDomains = [
    import.meta.env.VITE_EMBED_URL,
    'https://app.insertchatgpt.com',
    'https://embed.insertchatgpt.com',
    'https://bot.insertchatgpt.com',
    'https://app.insertchat.com',
    'https://embed.insertchat.com',
    'https://bot.insertchat.com',
    'https://development.insertchat.com',
    'https://localhost:4100',
    'https://localhost',
  ]

  public static loadChatbot = (widgetUid: string) => {
    if (!widgetUid) {
      throw new Error('No chatbot ID to load!')
    }

    window.ICG_BOT_ID = widgetUid
    window.ICG_BOT_TYPE = 'bubble'
    window.ICG_BOT_HEIGHT = 750
    window.ICG_BOT_BG_COLOR = '#fff'
    window.ICG_BOT_AUTOFOCUS = false
    window.ICG_BOT_OVERRIDE_OPENER = ''
    window.ICG_USER_ID = ''
    window.ICG_USER_EMAIL = ''
    window.ICG_USER_FIRSTNAME = ''
    window.ICG_USER_LASTNAME = ''
    window.ICG_USER_TAGS = []
    window.ICG_USER_METADATA = {}

    if (ChatbotHelper.mode === 'development') {
      window.ICG_API_URL = 'https://127.0.0.1:4050/v1'
      window.ICG_EMBED_DOMAIN = 'https://localhost:4100'
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = ChatbotHelper.bubbleSrc
    script.async = true
    document.head.appendChild(script)
  }

  public static unLoadChatbot = () => {
    const allScripts = document.getElementsByTagName('script')

    // Clean 1: Iterate through all script elements and check if the src attribute contains the specified part
    for (let i = allScripts.length - 1; i >= 0; i--) {
      const script = allScripts[i]

      if (script.src && script.src.includes(ChatbotHelper.bubbleSrc)) {
        if (script.parentNode) {
          script.parentNode.removeChild(script)
        }
      }
    }

    // Clean 2
    const chatBubble = document.querySelector('.chat-bubble')

    if (chatBubble && chatBubble.parentNode) {
      chatBubble.parentNode.removeChild(chatBubble)
    }

    // Clean 3
    const chatNotifications = document.querySelector('.chat-notifications')

    if (chatNotifications && chatNotifications.parentNode) {
      chatNotifications.parentNode.removeChild(chatNotifications)
    }

    // Clean 4
    if (window) {
      delete (window as any)?.ICG_BOT_ID
      delete (window as any)?.ICG_BOT_TYPE
      delete (window as any)?.ICG_BOT_HEIGHT
      delete (window as any)?.ICG_BOT_BG_COLOR
      delete (window as any)?.ICG_BOT_AUTOFOCUS
      delete (window as any)?.ICG_BOT_OVERRIDE_OPENER
      delete (window as any)?.ICG_USER_ID
      delete (window as any)?.ICG_USER_EMAIL
      delete (window as any)?.ICG_USER_FIRSTNAME
      delete (window as any)?.ICG_USER_LASTNAME
      delete (window as any)?.ICG_USER_TAGS
      delete (window as any)?.ICG_USER_METADATA
      delete (window as any)?.ICG_API_URL
      delete (window as any)?.ICG_EMBED_DOMAIN
    }
  }

  public static refreshChatbot = (widgetUid: string) => {
    ChatbotHelper.unLoadChatbot()
    ChatbotHelper.loadChatbot(widgetUid)
  }

  public static getDomain = () => {
    const allowedDomains = ChatbotHelper.allowedDomains

    return StringHelper.getDomainFromUrl({
      url: allowedDomains ? import.meta.env.VITE_EMBED_URL : window.location.origin,
      includeSubdomain: true,
      includeProtocol: true,
    })
  }

  /*
  public static injectScripts = (params: {
    widgetUid: string
    chatUid?: string
    qsAppend?: string
  }) => {
    const { widgetUid, chatUid, qsAppend } = params

    if (!widgetUid) {
      return
    }

    const stringChatID =
      chatUid && chatUid !== 'null' && chatUid !== 'undefined' && typeof chatUid !== 'undefined'
        ? chatUid
        : ''
    const mode = import.meta.env.MODE
    const domain = ChatbotHelper.getDomain()
    const modeScriptName = mode === 'production' ? '' : '.dev'
    let qs = `widget_id=${widgetUid}&chat_id=${stringChatID}&env=${mode}`

    if (qsAppend) {
      qs += `&${qsAppend}`
    }

    const bubbleSrc = `${domain}/widgets/chatbot${modeScriptName}.js`
    const allScripts = document.getElementsByTagName('script')

    // Clean 1: Iterate through all script elements and check if the src attribute contains the specified part
    for (let i = allScripts.length - 1; i >= 0; i--) {
      const script = allScripts[i]

      if (script.src && script.src.includes(bubbleSrc)) {
        if (script.parentNode) {
          script.parentNode.removeChild(script)
        }
      }
    }

    // Clean 2
    const chatBubble = document.querySelector('.chat-bubble')

    if (chatBubble && chatBubble.parentNode) {
      chatBubble.parentNode.removeChild(chatBubble)
    }

    // Clean 3
    const chatNotifications = document.querySelector('.chat-notifications')

    if (chatNotifications && chatNotifications.parentNode) {
      chatNotifications.parentNode.removeChild(chatNotifications)
    }

    const bubbleScript = document.createElement('script')
    bubbleScript.src = `${bubbleSrc}?${qs}`
    bubbleScript.async = true

    document.head.appendChild(bubbleScript)
  }
  */
}

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { WindowHelper } from '/@src/helpers'
import { IInput, IMessage } from '/@src/interfaces'
import { useWidget } from '/@src/stores'

const props = defineProps({
  message: {
    type: Object as () => IMessage,
    required: true,
    default: () => {},
  },
  formName: {
    type: String,
    required: true,
    default: () => '',
  },
})

// Composable
const widgetStore = useWidget()

// Const data
const formId = 'chatDynamicForm'

// Reactive data
const { formName } = toRefs(props)
const isLoading = ref(false)
const isLocked = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const assistantMessagesStylingSettings: any = ref({})
const agentSettings: any = ref({})

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const enabledInputs = computed(
  () => agentSettings.value?.inputs?.filter((input: IInput) => input?.status) ?? []
)

// Lifecycle hooks
onBeforeMount(() => {
  assistantMessagesStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()

  switch (formName.value) {
    case 'capture_lead':
      agentSettings.value = widgetStore.getCaptureLeadAgent()
      break

    case 'transcribe':
      agentSettings.value = widgetStore.getTranscribeAgent()
      break
  }
})

onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    widgetStore.setWidgetState('isChatBoxDisabled', false)

    WindowHelper.postMessage('submit_agent', {
      ...fields,
      agent_name: formName,
      form_name: formName,
      widget_uid: widgetStore.getWidget()?.uid,
      chat_uid: widgetStore.getChat()?.uid,
      account_uid: widgetStore.getAccount()?.uid,
    })

    const formattedAnswer = enabledInputs.value
      .filter((input: any) => fields[input.name])
      .map((input: any) => `${input.label}: ${fields[input.name]}`)
      .join('\n')

    // message.value.output_form = ''

    isLoading.value = false
    isLocked.value = true

    await widgetStore.createMessage({
      role: 'user',
      input: formattedAnswer,
      showTypingIndicator: true,
      metadata: fields,
    })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit
    :id="formId"
    v-model="formData"
    type="form"
    :disabled="isLoading"
    :actions="false"
    :classes="{
      form: 'w-100',
    }"
    @submit="onSubmit"
  >
    <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

    <div class="columns is-multiline is-mobile">
      <div v-if="agentSettings.text_intro" class="column is-12">{{ agentSettings.text_intro }}</div>

      <div v-for="input of enabledInputs" :key="input.name" class="column is-12">
        <FormKit
          :type="input?.type"
          :label="input?.label"
          :name="input?.name"
          :options="input?.options"
          :validation="
            input?.required
              ? `required:trim${input.validation ? '|' + input.validation : ''}`
              : `trim${input.validation ? '|' + input.validation : ''}`
          "
          :required="input?.required ? 'required' : ''"
          :classes="{
            inner: 'chat-radius-rounded mb-3',
          }"
          rows="1"
        >
          <template #label>
            <label
              class="formkit-label"
              :style="{ color: assistantMessagesStylingSettings?.ai?.color }"
            >
              {{ input?.label }}
              <span v-if="input.required" style="color: var(--danger)">*</span>
            </label>
          </template>
        </FormKit>
      </div>
      <div class="column is-12">
        <VButton
          :disabled="isFormDisabled || isLocked"
          :loading="isLoading"
          :style="widgetStore.getDynamicStylesByObject(agentSettings)"
          type="submit"
          color="primary"
          class="no-border w-100"
          @click="triggerSubmit"
        >
          {{ agentSettings.text_button_submit ?? 'Submit' }}
        </VButton>
      </div>
    </div>
  </FormKit>
</template>

import StringHelper from './string'

export default class CookiesHelper {
  public static set = (
    name: string,
    value: string,
    expiry: number = 365,
    domain?: string
  ): string | undefined => {
    try {
      const _domain = domain
        ? domain
        : StringHelper.getDomainFromUrl({
            url: window.location.origin,
            includeSubdomain: true,
            includeProtocol: false,
          })

      const date = new Date()
      date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000)

      document.cookie = `${name}=${encodeURIComponent(value)}; expires=${date.toUTCString()}; domain=${_domain}; path=/; SameSite=None; Secure;`
    } catch (error) {}

    try {
      localStorage?.setItem(name, value)
    } catch (error) {}

    return value
  }

  public static get = (name: string): string | null | undefined => {
    try {
      const cookieValue = CookiesHelper.getCookie(name)

      if (cookieValue !== null) {
        return decodeURIComponent(cookieValue)
      }
    } catch (error) {}

    try {
      if (window.localStorage) {
        const localValue = window.localStorage.getItem(name)

        if (localValue !== null) {
          return localValue
        }
      }
    } catch (error) {}

    return null
  }

  public static remove = (name: string, domain?: string): void => {
    const _domain = domain
      ? domain
      : StringHelper.getDomainFromUrl({
          url: window.location.origin,
          includeSubdomain: true,
          includeProtocol: false,
        })

    try {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${_domain}; path=/; SameSite=None; Secure;`
    } catch (error) {}

    try {
      if (window.localStorage) {
        window.localStorage.removeItem(name)
      }
    } catch (error) {}
  }

  private static getCookie(name: string): string | null {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }

    return null
  }
}

// @ts-ignore
window.cookie_set = CookiesHelper.set

// @ts-ignore
window.cookie_get = CookiesHelper.get

// @ts-ignore
window.cookie_remove = CookiesHelper.remove

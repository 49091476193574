<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Const data
const defaultUtm = `?utm_source=insertchat.com&utm_medium=copyright&chatbot_uid=${widgetStore.getWidget()?.uid}`
const defaultCopyright = `Powered by InsertChat`

// Reactive data
const copyrightSettings: any = ref({})
const brandingLimitation = ref()
const windowSettings: any = ref({})

// Lifecycle hooks
onBeforeMount(() => {
  windowSettings.value = widgetStore.getWindowStylingSettings()
  copyrightSettings.value = widgetStore.getCopyrightSettings()
  brandingLimitation.value = widgetStore.getPlanLimitations('branding')
})

// Computed
const computedUrl = computed(() => {
  let url

  if (brandingLimitation.value && copyrightSettings.value?.url) {
    url = copyrightSettings.value?.url
  } else {
    url = 'https://insertchat.com' + defaultUtm
  }

  return url
})

const computedCopyright = computed(() => {
  if (brandingLimitation.value) {
    return copyrightSettings.value?.text || ''
  }

  return defaultCopyright
})

const showCopyright = computed(() => {
  if (!brandingLimitation.value) {
    return true
  }

  if (
    brandingLimitation.value &&
    copyrightSettings.value?.text !== '' &&
    copyrightSettings.value?.status
  ) {
    return true
  }

  return false
})
</script>

<template>
  <div v-if="showCopyright" class="mb-0">
    <div class="chat-copyright">
      <div class="columns is-multiline is-centered is-mobile is-vcentered m-0">
        <!-- Copyright -->
        <div class="column is-12 driverjs-chatbot-copyright is-centered">
          <a
            :style="{
              ...widgetStore.getDynamicStylesByObject(windowSettings),
            }"
            class="m-auto"
            :href="computedUrl"
            target="_blank"
          >
            <img
              v-if="computedCopyright?.toLowerCase()?.includes('insertchat')"
              src="https://cdn.insertchat.com/public/favicon_black.svg"
              alt="InsertChat"
            />

            <p
              :style="{
                display: 'inline-block',
                verticalAlign: '-1px',
                ...widgetStore.getDynamicStylesByObject(windowSettings),
              }"
            >
              {{ computedCopyright }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Reactive data
const isLoading = ref(false)

// Composable
const { t } = useI18n()
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(t('page.auth.logout.title'))
viewWrapper.setPageIcon(ICONS.pages.logout)
useHead({ title: t('page.auth.logout.title') })

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    await userSession?.logoutUser()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})
</script>

<script setup lang="ts">
import { computed, onMounted, reactive, Reactive, ref, Ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
  plans: { type: Object, default: () => {}, required: true },
})

const { widgetUid, trainingType, provider } = toRefs(props)

// Const data
const formId = 'trainingCloudStorageForm'

// Composable
const userSession = useUserSession()

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<any> = ref([])
const isLoading = ref(false)
const training = ref({
  label: '',
  fetch_every_hours: 1,
  access_key: '',
  secret_key: '',
  bucket_name: '',
  bucket_region: '',
  bucket_endpoint: '',
})
const autoFetchLimitation = ref()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true
    autoFetchLimitation.value = userSession.getPlanLimitations('auto_fetch')

    if (provider.value && provider.value?.uid) {
      const { access_key, secret_key, bucket_name, bucket_region, bucket_endpoint } =
        provider.value?.cloud_storage_config ?? {}

      training.value = {
        label: provider.value?.label,
        fetch_every_hours: provider.value?.fetch_every_hours,
        access_key,
        secret_key,
        bucket_name,
        bucket_region,
        bucket_endpoint,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit()
})

// Functions
const onInit = () => {
  newMaterial.value = [{}]
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  if (isLoading.value) {
    return
  }

  const { access_key, secret_key, bucket_name, bucket_region, bucket_endpoint } = fields ?? {}

  if (!access_key || !secret_key || !bucket_name || !bucket_region || !bucket_endpoint) {
    isLoading.value = false

    return
  }

  isLoading.value = true

  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
      fetch_every_hours: Number(training.value?.fetch_every_hours) || 1,
      cloud_storage_config: {
        access_key,
        secret_key,
        bucket_name,
        bucket_region,
        bucket_endpoint,
      },
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Training #1"
      validation-visibility="live"
    />

    <CustomForm title="Bucket Configuration" subtitle="" class="mb-4">
      <template #body>
        <div class="columns is-multiline">
          <!-- Auto Refresh Training -->
          <div class="column is-12">
            <FormKit
              v-model.number="training.fetch_every_hours"
              type="range"
              :label="`Auto refresh every ${training.fetch_every_hours} hour(s)`"
              value="1"
              min="1"
              max="168"
              step="1"
              tooltip="true"
              validation="required:trim"
              :disabled="!autoFetchLimitation"
              :classes="{
                outer: 'm-0',
              }"
            />
          </div>

          <div class="column is-6">
            <FormKit
              v-model="training.access_key"
              name="access_key"
              type="text"
              label="Access Key ID"
              validation="required:trim"
              placeholder=""
            />
          </div>

          <div class="column is-6">
            <FormKit
              v-model="training.secret_key"
              name="secret_key"
              type="text"
              label="Secret Key"
              validation="required:trim"
              placeholder=""
            />
          </div>

          <div class="column is-6">
            <FormKit
              v-model="training.bucket_name"
              name="bucket_name"
              type="text"
              label="Bucket Name"
              validation="required:trim"
              placeholder=""
            />
          </div>

          <div class="column is-6">
            <FormKit
              v-model="training.bucket_region"
              name="bucket_region"
              type="text"
              label="Bucket Region"
              validation="required:trim"
              placeholder=""
            />
          </div>

          <div class="column is-12">
            <FormKit
              v-model="training.bucket_endpoint"
              name="bucket_endpoint"
              type="text"
              label="Bucket Endpoint"
              validation="url|required:trim"
              placeholder=""
            />
          </div>
        </div>

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Synch
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>

import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { HttpHelper } from '/@src/helpers'

export const useApp = defineStore('app', () => {
  const plans: any = ref({})
  const addons: any = ref({})

  // Functions
  async function loadPlans(mode: 'all' | 'business' | 'characters') {
    const [plansResponse, addonsResponse] = await Promise.all([
      HttpHelper.get('/webhooks/plans', {
        withoutApp: true,
      }),

      HttpHelper.get('/webhooks/addons', {
        withoutApp: true,
      }),
    ])

    plans.value = {}
    addons.value = addonsResponse

    for (const planResponse of plansResponse) {
      if (mode !== 'all' && planResponse.mode !== mode) {
        continue
      }

      const key = planResponse.title.toLowerCase()

      plans.value[key] = planResponse
    }

    return { plans: plans.value, addons: addons.value }
  }

  return {
    plans,
    addons,
    loadPlans,
  } as const
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApp, import.meta.hot))
}

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Const data
const formId = 'formSuggestions'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const cardOpacity = ref(0.25)
const isFreeLimitation = ref()

// Composable
const notyf = useNotyf()
const userSession = useUserSession()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  isFreeLimitation.value = userSession.getPlanLimitations('is_free')
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const handleFocusIn = (opacity: number) => {
  cardOpacity.value = opacity
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    EventHelper.push('submitted_form_suggestion')

    isLoading.value = true

    const { suggestion } = fields

    await HttpHelper.post('/suggestions', null, {
      type: 'feedback',
      suggestion,
    })

    formData.value.suggestion = ''

    notyf.success("Thanks for your feedback! We'll take a close look and review it thoroughly!")
  } catch (error: any) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <VCard
    v-if="!isFreeLimitation"
    class="mt-3"
    :style="{ opacity: cardOpacity }"
    @mouseenter="handleFocusIn(1)"
    @mouseleave="handleFocusIn(0.25)"
    @focusin="handleFocusIn(1)"
    @focusout="handleFocusIn(0.25)"
  >
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title is-4">How could we improve the app for you?</h1>
      </div>
      <div class="column is-12">
        <VLoader v-if="isLoading" />

        <FormKit
          v-else
          :id="formId"
          v-model="formData"
          type="form"
          :disabled="isLoading"
          :actions="false"
          @submit="onSubmit"
        >
          <VMessage color="info">
            We really care about what you think! We build and improve the product with your help.
          </VMessage>

          <FormKit
            name="suggestion"
            type="textarea"
            placeholder="Please enter your ideas, suggestions or issues... Any feedback is welcome!"
            rows="1"
          />

          <div v-if="formData.suggestion" class="column is-12 has-text-right m-0 p-0">
            <VButton
              :disabled="isFormDisabled"
              :loading="isLoading"
              type="submit"
              color="primary"
              icon="ic:round-check"
              @click="triggerSubmit"
            >
              Save Changes
            </VButton>
          </div>
        </FormKit>
      </div>
    </div>
  </VCard>
</template>

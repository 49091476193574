<script setup lang="ts">
import { ref } from 'vue'

export interface CustomCollapseProps {
  itemOpen?: boolean
  withChevron?: boolean
  withTrash?: boolean
  elevated?: boolean
  color?: string
  iconSize?: string
}

export interface CustomCollapseEmits {
  (e: 'delete', uid: string): void
}

const props = withDefaults(defineProps<CustomCollapseProps>(), {
  itemOpen: false,
  elevated: true,
  color: undefined,
  iconSize: '1.25em',
})

const status = ref(props.itemOpen)

const toggle = (event: any) => {
  if (event.pointerType !== '') {
    status.value = !status.value
  }
}
</script>

<template>
  <details
    :class="[withChevron && 'has-chevron', !withChevron && 'has-plus', elevated && 'is-raised']"
    :open="status"
    class="custom-box collapse"
    @keydown.space.self="() => {}"
  >
    <summary
      class="collapse-header"
      tabindex="0"
      :style="`background-color: ${props.color}`"
      @click.prevent="toggle"
    >
      <slot name="collapse-item-summary"> </slot>
      <div class="is-flex">
        <slot name="collapse-item-actions"> </slot>
        <div class="formkit-controls vertical collapse-icon">
          <VIcon v-if="withChevron" icon="feather:chevron-down" />
          <VIcon v-else-if="!withChevron" icon="feather:plus" />
        </div>
      </div>
    </summary>
    <div v-show="status" class="collapse-content">
      <slot name="collapse-item-content"> </slot>
    </div>
  </details>
</template>

<style lang="scss">
@import '/@src/scss/abstracts/all';

.trash-icon {
  color: var(--danger);
  padding: 0 15px;
}

.collapse {
  @include vuero-s-card;

  outline: none;
  padding: 0;
  margin-bottom: 10.5px;

  &.has-plus {
    &[open] {
      .collapse-header {
        .collapse-icon {
          transform: rotate(45deg);
        }
      }

      .collapse-content {
        display: block;
      }
    }
  }

  &.has-chevron {
    &[open] {
      .collapse-header {
        .collapse-icon {
          transform: rotate(180deg);
        }
      }

      .collapse-content {
        display: block;
      }
    }
  }

  &[open] {
    .collapse-header {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .collapse-icon {
      border-color: var(--dark-sidebar-light-12) !important;
      box-shadow: var(--light-box-shadow);
    }
  }

  .collapse-header {
    border-radius: var(--radius-large);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    cursor: pointer;

    h3 {
      font-family: var(--font-alt);
      font-size: 0.9rem;
      font-weight: 600;
      color: var(--dark-text);
    }

    .collapse-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: var(--fk-repeater-controls-bg);
      border: 1px solid var(--border);
      border-radius: var(--radius-large) !important;
      transition: all 0.3s; // transition-all test
      font-size: 1em;
      // padding: 6px 0;

      > span {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
        color: #000000;
      }
    }
  }

  .collapse-content {
    display: none;
    padding: 15px 20px;
    color: var(--light-text);
    font-family: var(--font);
    border-top: 1px solid var(--border);
    // overflow-x: auto; // Allows for horizontal scrolling if necessary

    > * {
      box-sizing: border-box; // Ensures padding and border are included in the element's total width
    }

    img,
    table,
    video,
    iframe {
      // Target specific elements that should take full width
      max-width: 100%;
      height: auto; // Maintain aspect ratio for images and videos
    }

    p:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.is-dark {
  .collapse {
    @include vuero-card--dark;

    &[open] {
      .collapse-header {
        .collapse-icon {
          background: var(--dark-sidebar-light-2);
          border-color: var(--dark-sidebar-light-12) !important;
        }
      }
    }

    .collapse-header {
      h3 {
        color: var(--dark-dark-text);
      }

      .collapse-icon {
        background: var(--dark-sidebar-light-6);
        border-color: var(--dark-sidebar-light-12);
      }
    }
  }
}
</style>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'
import { ROLES } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalFormProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { updateUid, open } = props.modalFormProps

// Const data
const formId = 'formAccount'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const widgets: any = ref([])

// Composable
const userSession = useUserSession()

// Computed
const title = computed(() => {
  return updateUid ? 'Update member' : 'Add member'
})

const hasAccess = computed(() => {
  return ['manager', 'client'].includes(userSession?.user?.role)
})

const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    formData.value.assigned_widgets = []

    if (updateUid) {
      const [userResponse, widgetsResponse] = await Promise.all([
        HttpHelper.get(`/users/${updateUid}`),
        HttpHelper.get(`/widgets`),
      ])

      widgets.value = widgetsResponse
      formData.value = { assigned_widgets: [], ...userResponse }
    } else {
      widgets.value = await HttpHelper.get(`/widgets`)
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { first_name, last_name, email, role, assigned_widgets } = fields

    const response = await HttpHelper.post('/users', updateUid, {
      uid: updateUid,
      first_name,
      last_name,
      email,
      role,
      assigned_widgets,
    })

    emit('submit', response)

    notyf.success(t('notifications.success'))

    // Close modal with refresh
    closeModal(true)
  } catch (error: any) {
    console.error(error)

    emit('submit', error)
  } finally {
    isLoading.value = false
  }
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}
</script>

<template>
  <CustomModal
    size="big"
    :open="open"
    :title="title"
    :override-modal="false"
    :noscroll="false"
    :noclose="true"
    @close="closeModal"
  >
    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading || hasAccess"
      :actions="false"
      @submit="onSubmit"
    >
      <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

      <div class="columns is-multiline">
        <div class="column is-12">
          <FormKit
            type="dropdown"
            label="Role"
            placeholder="Select a role"
            validation="required:trim"
            name="role"
            :options="ROLES"
            :close-on-select="true"
            :disabled="isLoading || hasAccess"
          >
            <template #option="{ option }">
              <div class="formkit-option">
                <div class="text-container">
                  <div>
                    {{ option.label }}
                  </div>
                  <p class="option-overview">
                    {{ option.help }}
                  </p>
                </div>
              </div>
            </template>
          </FormKit>
        </div>
        <div v-if="formData.role === 'client'" class="column is-12">
          <FormKit
            name="assigned_widgets"
            type="taglist"
            label="Assigned chatbots"
            placeholder="Select a chatbot"
            validation="required:trim"
            :options="widgets?.map((widget: any) => ({ label: widget?.label, value: widget?.uid }))"
            :disabled="isLoading || hasAccess"
            :allow-new-values="false"
            :open-on-click="true"
            min="1"
          >
            <template #option="{ option }">
              <div class="formkit-option">
                <div class="text-container">
                  <div>
                    {{ option.label }}
                  </div>
                  <p class="option-overview">
                    {{ option.computed_settings?.images?.logo?.url }}
                  </p>
                </div>
              </div>
            </template>
          </FormKit>
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="First name"
            name="first_name"
            validation="required:trim"
            placeholder=""
            :disabled="isLoading || hasAccess"
          />
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="Last name"
            name="last_name"
            validation="required:trim"
            placeholder=""
            :disabled="isLoading || hasAccess"
          />
        </div>
        <div class="column is-12">
          <FormKit
            type="text"
            label="E-mail"
            name="email"
            validation="required:email"
            placeholder=""
            :disabled="isLoading || hasAccess"
          />
        </div>
      </div>
    </FormKit>

    <template #action>
      <div class="form-footer">
        <div class="left"></div>

        <div class="right">
          <VButton
            :disabled="isFormDisabled || hasAccess"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save Changes
          </VButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

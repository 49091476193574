<script setup lang="ts">
const emit = defineEmits<{
  (e: 'toggle'): void
}>()
const props = defineProps<{
  isOpen?: boolean
}>()
</script>

<template>
  <div :class="[props.isOpen && 'is-active']" class="mobile-main-sidebar">
    <div class="inner">
      <ul class="icon-side-menu">
        <slot name="links">
          <li>
            <a aria-label="Back to homepage" href="/">
              <i aria-hidden="true" class="iconify" data-icon="feather:activity"></i>
            </a>
          </li>
        </slot>
      </ul>

      <ul class="bottom-icon-side-menu">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>

  <div v-if="props.isOpen" class="mobile-overlay" @click="emit('toggle')"></div>
</template>

<style lang="scss">
@import '/@src/scss/layout/_sidebar';
@import '/@src/scss/layout/_sidebar-mobile';

/* ==========================================================================
1. Mobile Sidebar
========================================================================== */
.mobile-overlay {
  background: rgb(0 0 0 / 30%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  backdrop-filter: blur(1px);
}

.mobile-main-sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  width: 60px;
  background: var(--white);
  box-shadow: 8px 0 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;
  transform: translateX(-100%);
  transition: all 0.3s;

  .label {
    content: attr(data-content);
    position: absolute;
    bottom: -8px;
    inset-inline-start: -31px;
    inset-inline-end: 0;
    margin: 0 auto;
    font-family: var(--font);
    font-size: 0.5rem;
    font-weight: 500;
    color: var(--light-text);
    text-transform: uppercase;
    text-align: center;
    width: 80px;
    transition: opacity 0.3s;
  }

  &.is-active {
    color: var(--primary);
    transform: translateX(0);

    .label {
      color: var(--primary);
    }
  }

  .inner {
    height: 100%;
    width: 100%;
    position: relative;

    .icon-side-menu,
    .bottom-icon-side-menu {
      li {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          display: block;
          position: relative;
          transform: rotate(0);
          opacity: 1;
          transition: all 0.3s;

          &:hover,
          &.is-active {
            > svg {
              color: var(--primary);
            }
          }

          > svg {
            color: var(--title-grey);
            height: 20px;
            width: 20px;
            stroke-width: 1.6px;
          }

          .sidebar-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            transition: all 0.3s;
          }

          &:hover svg,
          &.is-active svg {
            color: var(--primary);
          }

          &.is-opened {
            transform: rotate(360deg);
            opacity: 0;
          }

          &.is-inactive {
            transform: rotate(-360deg);
            opacity: 0;
          }

          &.is-selected,
          &.router-link-exact-active {
            svg {
              color: var(--primary);
            }
          }
        }

        #open-filters {
          svg {
            transform: rotate(0);
            transition: all 0.3s;
          }

          &:hover {
            svg {
              transform: rotate(145deg);
            }
          }
        }

        &.is-active {
          a svg {
            color: var(--primary);
          }
        }
      }
    }

    .bottom-icon-side-menu {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>

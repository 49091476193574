import { dragscroll } from 'vue-dragscroll'
import { definePlugin } from '/@src/app'
import { vBackground } from '/@src/directives/background'
import { vFocus } from '/@src/directives/focus'
import { vTooltip } from '/@src/directives/tooltip'

export default definePlugin(({ app }) => {
  app.directive('tooltip', vTooltip)
  app.directive('background', vBackground)
  app.directive('focus', vFocus)
  app.directive('dragscroll', dragscroll)
})

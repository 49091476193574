<route lang="yaml">
meta:
  requiresAuth: true
</route>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { DateHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID
window.CRISP_RUNTIME_CONFIG = {
  lock_maximized: true,
  lock_full_view: true,
  cross_origin_cookies: true,
}

// Reactive data
const title = ref('Support')

// Composable
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.customer_support)
useHead({ title: title.value })
const userSession = useUserSession()

// Function to load Widgetbot script programmatically
onBeforeMount(() => {
  const script = document.createElement('script')
  script.src = 'https://cdn.jsdelivr.net/npm/@widgetbot/html-embed'
  script.async = true
  document.body.appendChild(script)
})

// Computed
/*
const crispComputedSrc = computed(() => {
  return `https://go.crisp.chat/chat/embed/?website_id=${CRISP_WEBSITE_ID}&user_email=${userSession?.user?.email}&user_nickname=${userSession?.user?.designation}&crisp_sid=${userSession?.user?.uid}&token_id=${userSession?.user?.uid}&session_merge=true`
})
*/
</script>

<template>
  <NavbarLayout>
    <div class="page-content-inner">
      <VCard>
        <!--
        <VMessage color="danger">
          <b>Information</b><br />

          - You'll be talking to a live person, not an AI.<br />
          - We are in the Europe/Paris time zone. If we don't respond right away, don't worry! We
          will get back to you as soon as we can.<br />
          - Support is available from Monday to Friday, except for plans with a dedicated support
          manager (Business & Enterprise).<br />
          - We are currently receiving a lot of requests. Please expect a response time of 72 hours,
          except for plans with a dedicated support manager (Business & Enterprise).<br />

          <br />

          <b>Rules</b><br />

          - Only communicate in English.<br />
          - Start by providing the chatbot's name.<br />
          - Provide the chat ID, you can find it in your inbox.<br />
          - Describe your issue in detail.<br />
          - Include examples, screenshots, or videos if possible.
        </VMessage>
  
        <iframe title="Support" width="100%" height="750" :src="crispComputedSrc"></iframe>
        -->

        <VMessage color="info">
          <b>UPDATE!</b><br />

          - We're trying out a new way to handle customer support. Instead of using a live chatbot,
          we invite you to join our Discord community. There, you can start a thread to share your
          suggestions or talk about any issues you have. Our support team and other community
          members are ready to help.<br />
          - If you are an Enterprise or Business user with a dedicated support manager (on the
          Business & Enterprise plan), you can ask your dedicated support manager for access to a
          private WhatsApp, Telegram, or email for faster support.
        </VMessage>

        <!--
          <widgetbot
          server="1092458448194637875"
          channel="1092458840764727337"
          width="100%"
          height="750"
          />
        -->

        <iframe
          src="https://discord.com/widget?id=1092458448194637875&theme=dark"
          width="100%"
          height="500"
          allowtransparency="true"
          frameborder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </VCard>
    </div>
  </NavbarLayout>
</template>

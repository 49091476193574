<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'
import { useUserSession } from '/@src/stores'

// Composable
const userSession = useUserSession()

// Reactive data
const zapierContainer = ref<HTMLElement | null>(null)
const isLoading = ref(true)
const error = ref<string | null>(null)

onBeforeMount(() => {
  try {
    // Load JS
    const script = document.createElement('script')
    script.type = 'module'
    script.src =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
    script.onload = () => (isLoading.value = false)
    script.onerror = () => {
      isLoading.value = false
      error.value = 'Failed to load Zapier script'
    }
    document.head.appendChild(script)

    // Load CSS
    const stylesheet = document.createElement('link')
    stylesheet.rel = 'stylesheet'
    stylesheet.href =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'
    stylesheet.onload = () => (isLoading.value = false)
    stylesheet.onerror = () => {
      isLoading.value = false
      error.value = 'Failed to load Zapier stylesheet'
    }
    document.head.appendChild(stylesheet)
  } catch (e) {
    isLoading.value = false
    error.value = 'An unexpected error occurred'
  }
})

onMounted(() => {
  if (!error.value) {
    const element: any = document.createElement('zapier-workflow')

    element.signUpEmail = userSession?.user?.email
    element.signUpFirstName = ''
    element.signUpLastName = ''
    element.clientId = 'TzEksOmOnMsmsWTcIw8t9WY7s9dkJid2m5bftwrZ'
    element.theme = 'light'
    element.introCopyDisplay = 'show'
    element.manageZapsDisplay = 'show'
    element.zapCreateFromScratchDisplay = 'hide'

    if (zapierContainer.value) {
      zapierContainer.value.appendChild(element)
    }
  }
})
</script>

<template>
  <div ref="zapierContainer">
    <VLoader v-if="isLoading" />
    <div v-else-if="error">{{ error }}</div>
  </div>
</template>

import { decodeHTML } from 'entities'
import {
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
  createFloatingLabelsPlugin,
  createMultiStepPlugin,
} from '@formkit/addons'
import '@formkit/addons/css/floatingLabels'
import '@formkit/addons/css/floatingLabels'
import '@formkit/addons/css/multistep'
import { en } from '@formkit/i18n'
import { createProPlugin, inputs } from '@formkit/pro'
import { cloneAny } from '@formkit/utils'
import { createInput, defaultConfig, plugin } from '@formkit/vue'
import { definePlugin } from '/@src/app'
// @ts-ignore
import InputGoogleMaps from '/@src/components/custom/inputs/InputGoogleMaps.vue'

const copyPlugin = (node: any) => {
  if (node.props.type !== 'repeater' || !node.context) {
    return
  }

  node.context.fns.createCopy = (index: number) => () => {
    const _value = node._value

    _value.splice(index + 1, 0, cloneAny(_value[index]))

    node.input(_value, false)
  }
}

const addAsteriskPlugin = (node: any) => {
  node.on('created', () => {
    node.addProps(['isRequired'])
    node.props.isRequired = node.props.parsedRules.some((rule: any) => rule.name === 'required')
    const schemaFn = node.props.definition.schema

    node.props.definition.schema = (sectionsSchema = {}) => {
      const section =
        (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options
          ? 'legend'
          : 'label'

      // @ts-ignore
      sectionsSchema[section] = {
        children: [
          {
            children: ['$label'],
          },
          {
            $el: 'span',
            if: '$isRequired',
            attrs: {
              style: { color: 'var(--danger)' },
            },
            children: [' *'],
          },
        ],
      }

      return schemaFn(sectionsSchema)
    }
  })

  node.on('prop:parsedRules', () => {
    node.props.isRequired = node.props.parsedRules.some((rule: any) => rule.name === 'required')
  })
}

/*
const inputUpperCaseFirstLetter = (node: any) => {
  if (node.props.type === 'text') {
    node.hook.input((value: any, next: any) => {
      const newString =
        value && typeof value === 'string' ? value.charAt(0).toUpperCase() + value.slice(1) : value

      return next(newString)
    })
  }
}
*/

const decodeInputs = (node: any) => {
  if (node.props.type === 'text' || node.props.type === 'textarea') {
    node.hook.input((value: any, next: any) => {
      const newString = value && typeof value === 'string' ? decodeHTML(value) : value

      return next(newString)
    })
  }
}

const standardDatePlugin = (node: any) => {
  if (node.props.type === 'date') {
    node.hook.input((value: any, next: any) => {
      const index = typeof value === 'string' ? value.indexOf('T') : -1

      if (index !== -1) {
        value = value.substring(0, index)
      }

      return next(value)
    })
  }
}

const castNumber = (node: any) => {
  if (node.props.type === 'number') {
    node.hook.input((value: any, next: any) => {
      if (!isNaN(Number(value))) {
        return next(Number(value))
      }

      return next(undefined)
    })
  }

  if (node.props.type === 'select' || node.props.type === 'dropdown') {
    node.hook.input((value: any, next: any) => {
      // If null or undefined return the value
      if (!value) {
        return next(value)
      }

      // If string is NaN return the string, else cast to number
      return isNaN(Number(value)) ? next(value) : next(Number(value))
    })
  }

  if (node.props.type === 'text') {
    node.hook.input((value: any, next: any) => {
      if (value && !isNaN(Number(value))) {
        return next(String(value))
      }

      return next(value)
    })
  }
}

const transformOptions = (options: any) => {
  if (options && options.length > 0) {
    return options.map((option: any) => {
      if (typeof option === 'string' || typeof option === 'number') {
        return option
      }

      const { label, value } = option
      // const [label, value] = Object.values(option)

      const ctx = {
        normalize(string: any) {
          const _string = Array.isArray(string) ? string[0] : string
          return _string.normalize()
        },
      }

      return {
        ...option,
        // @ts-ignore
        label: typeof label === 'string' ? label : label(ctx),
        value: value ?? undefined,
      }
    })
  }

  return []
}

const mapOptions = (node: any) => {
  if (['radio', 'select', 'dropdown'].includes(node.props.type)) {
    // First load
    node.props.options = transformOptions(node.props.options)

    // On change
    node.hook.prop(({ prop, value }: any, next: any) => {
      if (prop === 'options') {
        const options = value

        return next({ prop, value: transformOptions(options) })
      }

      return next({ prop, value })
    })
  }
}

/*
const autoSelect = (node: any) => {
  if (node.props.type === 'select' || node.props.type === 'repeater') {
    node.hook.input((value: any, next: any) => {
      if (typeof node.props.disabled === 'undefined') {
        if (node.props?.options) {
          if (node.props.options.length === 1 && node.props.options[0]?.__original !== null) {
            // If only one option on the select and it’s not null, set it as the value
            return next(node.props.options[0].value)
          }

          // If two options on the select and the default option is null, set the second as the value
          if (node.props.options.length === 2 && node.props.options[0]?.__original === null) {
            return next(node.props.options[1].value)
          }
        }
      }

      return next(value)
    })
  }
}
*/

const buttonClass = (node: any) => {
  if (node.props.type !== 'button') {
    return
  }

  node.props.inputClass = 'button button v-button is-primary'
}

const agentName = (node: any) => {
  return !/^[a-z\-_]+$/.test(node.value) ? false : true
}

export default definePlugin((context: any) => {
  context.app.use(
    plugin,
    defaultConfig({
      plugins: [
        createFloatingLabelsPlugin({
          useAsDefault: false,
        }),
        createProPlugin('fk-8491ea8e64', inputs),
        createAutoAnimatePlugin(),
        // inputUpperCaseFirstLetter,
        addAsteriskPlugin,
        standardDatePlugin,
        castNumber,
        mapOptions,
        createAutoHeightTextareaPlugin(),
        decodeInputs,
        copyPlugin,
        createMultiStepPlugin(),
        buttonClass,
        // autoSelect,
      ],
      rules: { agent_name: agentName },
      locales: { en },
      locale: 'en',
      inputs: {
        address: createInput(InputGoogleMaps, {
          props: ['modelValue', 'context', 'placeholder'],
        }),
      },
    })
  )
})

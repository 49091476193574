<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { StringHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Define Types
export type NavbarTheme = 'default' | 'colored' | 'fade'
export type SubnavId = 'closed' | 'home' | 'layouts' | 'elements' | 'components' | 'search'

// Define Props with Defaults
const props = withDefaults(
  defineProps<{
    theme?: NavbarTheme
    nowrap?: boolean
  }>(),
  {
    theme: 'default',
  }
)

// Composables
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
const route = useRoute()

// Reactive Data
const modalFormProps = ref({ open: false })
const isMobileSidebarOpen = ref(false)
const activeSubnav = ref<SubnavId>('closed')

// Computed Properties
const computedRoute = computed(() => route.path)

const navItems = [
  { to: '/chatbots', icon: ICONS.pages.chats, label: 'Chatbots', roles: ['all'] },
  {
    to: '/whitelabel',
    icon: ICONS.pages.whitelabel,
    label: 'Whitelabel',
    roles: ['admin', 'owner', 'manager'],
  },
  {
    to: '/subscriptions',
    icon: ICONS.pages.subscriptions,
    label: 'Subscriptions',
    roles: ['admin', 'owner', 'manager'],
  },
  { to: '/settings', icon: ICONS.pages.account, label: 'Settings', roles: ['all'] },
  {
    to: '/support',
    icon: ICONS.pages.customer_support,
    label: 'Support',
    roles: ['admin', 'owner', 'manager'],
  },
]

// Filter Navigation Items Based on User Role
const filterNavItems = (items: typeof navItems) => {
  return items.filter(
    (item) =>
      item.roles.includes('all') ||
      (item.roles.includes('admin') && !userSession.isClient()) ||
      (item.roles.includes('editor') && !userSession.isClient())
  )
}

const filteredNavItems = computed(() => filterNavItems(navItems))

// Watchers
watch(
  () => route.fullPath,
  () => {
    activeSubnav.value = 'closed'
    isMobileSidebarOpen.value = false
  }
)

// Methods
function toggleSubnav(subnav: SubnavId) {
  activeSubnav.value = activeSubnav.value === subnav ? 'closed' : subnav
}

function inviteAndEarn() {
  modalFormProps.value.open = true
}
</script>

<template>
  <FormInviteEarn v-if="modalFormProps.open" v-model:modalFormProps="modalFormProps" />

  <div class="navbar-layout">
    <div class="app-overlay"></div>

    <!-- Mobile Navbar -->
    <MobileNavbar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #brand>
        <RouterLink to="/chatbots" class="navbar-item is-brand">
          <AnimatedLogo text height="38px" />
        </RouterLink>
        <div class="brand-end"></div>
      </template>
    </MobileNavbar>

    <!-- Mobile Sidebar -->
    <MobileSidebar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #links>
        <li v-for="item in filteredNavItems" :key="item.to">
          <RouterLink :to="item.to" :class="{ 'is-active': computedRoute === item.to }">
            <VIcon :icon="item.icon" />
            <span class="label">{{ item.label }}</span>
          </RouterLink>
        </li>
      </template>
    </MobileSidebar>

    <!-- Desktop Navbar -->
    <Navbar :theme="props.theme">
      <!-- Page Title -->
      <template #title>
        <RouterLink to="/chatbots" class="brand">
          <AnimatedLogo :text="false" width="38px" height="38px" />
        </RouterLink>
        <div class="navbar-separator"></div>
        <h1 class="title is-4">{{ viewWrapper.pageTitle }}</h1>
      </template>

      <!-- Toolbar -->
      <template #toolbar>
        <div
          v-if="
            !userSession.isClient() &&
            !['subscriptions-success', 'subscriptions-cancel'].includes(String(route.name))
          "
          class="columns is-multiline ml-auto is-hidden-mobile driverjs-widgets m-0 has-text-right"
        >
          <WidgetSupportId class="mr-2" />
          <WidgetMessageCredits class="mr-2" />
        </div>

        <VDropdown right spaced class="user-dropdown profile-dropdown">
          <template #button="{ toggle }">
            <a class="is-trigger dropdown-trigger" aria-haspopup="true" @click="toggle">
              <VAvatar :picture="userSession.user?.avatar || '/images/avatars/svg/user-1.svg'" />
            </a>
          </template>

          <template #content>
            <div class="dropdown-head">
              <VAvatar
                size="medium"
                :picture="userSession.user?.avatar || '/images/avatars/svg/user-1.svg'"
                alt="Avatar"
              />
              <div class="meta">
                <span>{{ userSession.user?.designation }}</span>
                <span>{{ StringHelper.capitalizeFirstLetter(userSession.user?.role) }}</span>
              </div>
            </div>

            <RouterLink
              v-for="item in navItems"
              :key="item.to"
              :to="item.to"
              role="menuitem"
              class="dropdown-item is-media"
            >
              <VIcon :icon="item.icon" class="is-inline mr-3" />
              <span class="is-inline label">{{ item.label }}</span>
            </RouterLink>

            <hr class="dropdown-divider" />

            <div class="dropdown-item is-button">
              <VButton
                to="/auth/logout"
                class="logout-button"
                icon="feather:log-out"
                color="primary"
                role="menuitem"
                raised
                fullwidth
              >
                Logout
              </VButton>
            </div>
          </template>
        </VDropdown>
      </template>

      <!-- Navigation Links -->
      <template #links>
        <div class="centered-links">
          <RouterLink
            v-for="item in filteredNavItems"
            :key="item.to"
            :to="item.to"
            :class="[
              'centered-link centered-link-toggle',
              { 'is-active': computedRoute === item.to },
            ]"
          >
            <VIcon :icon="item.icon" />
            <span>{{ item.label }}</span>
          </RouterLink>
        </div>
      </template>
    </Navbar>

    <!-- Page Content -->
    <div class="view-wrapper has-top-nav">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="is-navbar-md">
            <div class="page-title has-text-centered">
              <div class="title-wrap">
                <VIcon
                  v-if="viewWrapper.pageIcon"
                  :icon="viewWrapper.pageIcon"
                  color="#121933"
                  class="mr-3"
                />
                <h1 class="title is-4 is-inline">{{ viewWrapper.pageTitle }}</h1>
              </div>
            </div>

            <div class="page-content-inner">
              <VMessage color="info" v-if="userSession?.app?.license && !userSession.isClient()">
                Please don't forget to leave a review. Even a short one can be helpful. It's needed
                to keep the momentum going, and we will truly show our gratitude once the chaos
                settles -
                <a href="https://appsumo.com/products/insertchat/" target="_blank">
                  https://appsumo.com/products/insertchat/
                </a>
              </VMessage>

              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '/@src/scss/main.scss';

// Additional styles can be added here if necessary
</style>

import { Crisp } from 'crisp-sdk-web'
import CookiesHelper from './cookies'

const MODE = import.meta.env.MODE

export default class CrispHelper {
  public static config(user: any) {
    try {
      if (MODE !== 'production') {
        return
      }

      // @ts-ignore
      window.Crisp = Crisp

      const _user = Object.assign({}, user)

      delete _user?.metadata
      delete _user?.apps
      delete _user?.address
      delete _user?.gtmFormattedUser

      Crisp?.setTokenId(_user?.uid)
      // Crisp?.user?.setPhone(_user?.mobile_phone ?? _user?.landline_phone)
      Crisp?.user?.setEmail(_user?.email)
      Crisp?.user?.setNickname(_user?.designation)
      /*
      Crisp?.session?.setData(
        ObjectHelper.removeNullKeys({
          ..._user,
          app_uid: app?.uid,
          subscription_id: subscription?.id,
          subscription_customer: subscription?.customer,
          subscription_status: subscription?.status,
          subscription_product: subscription?.plan?.product,
          plan_name: plan?.title,
          plan_id: subscription?.plan?.id,
          plan_amount: subscription?.plan?.amount,
          plan_interval: subscription?.plan?.interval,
          plan_currency: subscription?.plan?.currency,
        })
      )
      */
    } catch (error) {
      console.error(error)
    }
  }

  public static push(event: string, data?: object | undefined) {
    try {
      if (MODE !== 'production') {
        return
      }

      Crisp?.session?.pushEvent(event, data)
    } catch (error) {
      console.error(error)
    }
  }

  public static sessionReset() {
    try {
      if (MODE !== 'production') {
        return
      }

      Crisp?.session?.reset()

      // Delete Crisp cookies
      CookiesHelper.remove('crisp')

      // @ts-ignore
      // delete window?.$CRISP_WEBSITE_ID

      // @ts-ignore
      delete window?.$__CRISP_INCLUDED

      // @ts-ignore
      delete window?.$__CRISP_INSTANCE

      // @ts-ignore
      delete window?.CRISP_TOKEN_ID

      // @ts-ignore
      delete window?.Crisp

      // @ts-ignore
      delete window?.$crisp
    } catch (error) {
      // console.error(error)
    }
  }
}

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { onMounted, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DateHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'close'])
const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
})

// Composable
const userSession = useUserSession()
const route = useRoute()

// Const data
const columns = {
  designation: {
    label: 'Designation',
    media: true,
    sortable: false,
    searchable: false,
    grow: false,
  },
  coordinate: {
    label: 'Coordinate',
    sortable: false,
    searchable: false,
    grow: false,
  },
  created_at: {
    label: 'Creation date',
    sortable: false,
    searchable: false,
    grow: true,
  },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const
const url = '/contacts'
const eventBus = useEventBus(url)
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid

// Reactive data
const isLoading = ref(false)
const modalFormProps: any = ref({ open: false, refresh: false })
const contacts = ref([])
const exportsLimitation = ref()

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true
    exportsLimitation.value = userSession.getPlanLimitations('exports')

    contacts.value = await HttpHelper.get(url)
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  }

  if (widgetUid) {
    modalFormProps.value.widgetUid = widgetUid
  }

  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}

const onDownload = (selectedContact?: any) => {
  try {
    if (!exportsLimitation.value) {
      alert(
        `Your current plan does not include the option to export leads. To access this feature, consider upgrading your plan.`
      )

      return
    }

    isLoading.value = true

    const element = document.createElement('a')
    element.href = URL.createObjectURL(
      new Blob([JSON.stringify(selectedContact ?? contacts.value)], {
        type: 'text/plain',
      })
    )
    element.download = selectedContact?.uid ? `lead-${selectedContact?.uid}.json` : 'leads.json'
    // Required for this to work in FireFox
    document.body.appendChild(element)
    element.click()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const refreshTable = () => {
  eventBus.emit(url)
}
</script>

<template>
  <FormContact
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
    @close="closeModal"
  />

  <CustomTable
    v-model:refresh="modalFormProps.refresh"
    v-model:override-loading="isLoading"
    :title="props.title"
    :columns="columns"
    :filters="props.filters"
    :url="url"
    @row-click="openFormModal"
  >
    <template #buttons>
      <VButtons align="right">
        <VButton color="primary" icon="ic:round-plus" @click.capture="openFormModal">
          Add contact
        </VButton>

        <VButton
          :loading="isLoading"
          type="submit"
          color="primary"
          :icon="ICONS.actions.download"
          @click="onDownload(null)"
        >
          Export all
        </VButton>
      </VButtons>
    </template>

    <template #row="{ row }">
      <VFlexTableCell :column="columns.designation">
        <VAvatar :initials="row?.initials" size="small" class="mr-2" />

        <VTextEllipsis width="200px" class="item-name dark-text">
          {{ row?.designation }}
          <div class="light-text">
            {{ row?.company ?? row?.website ?? row?.job_title ?? row?.job_role }}
          </div>
        </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.coordinate">
        <span> {{ row?.email ?? row?.phone }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.created_at">
        <span> {{ DateHelper.formatDate(row?.created_at, 'MM-dd-yyyy') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton :icon="ICONS.tables.open" class="pr-3 pl-3 mr-2"> Open </VButton>

        <CustomDropdown
          v-model:refresh="modalFormProps.refresh"
          v-model:isLoading="isLoading"
          title="Actions"
          :actions="['edit', 'delete']"
          :reload-on-delete="false"
          :uid="row?.uid"
          :url="url"
          @edit="openFormModal"
          @refresh="refreshTable"
        >
          <VButton
            class="dropdown-item"
            :icon="ICONS.actions.download"
            @click.prevent="
              () => {
                onDownload(row)
              }
            "
          >
            Export
          </VButton>
        </CustomDropdown>
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>

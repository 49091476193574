<script setup lang="ts">
const props = defineProps({
  isDisabled: {
    type: Boolean,
    default: true,
    required: true,
  },
})
</script>

<template>
  <div class="column is-4">
    <FormKit
      type="colorpicker"
      name="background"
      label="Background Color"
      validation="required:trim"
      eye-dropper="true"
      :disabled="props.isDisabled"
      :classes="{ inner: 'w-100' }"
      value="#000000"
    />
  </div>
  <div class="column is-4">
    <FormKit
      type="colorpicker"
      name="color"
      label="Text Color"
      validation="required:trim"
      eye-dropper="true"
      :disabled="props.isDisabled"
      :classes="{ inner: 'w-100' }"
      value="#FFFFFF"
    />
  </div>
  <div class="column is-4">
    <FormKit
      name="size"
      type="number"
      label="Text Size (pixel)"
      min="0.1"
      step="0.05"
      validation="required:trim"
      :disabled="props.isDisabled"
      value="12.25"
    />
  </div>
</template>

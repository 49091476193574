import { useHead } from '@vueuse/head'
import Rollbar from 'rollbar'
import { ref } from 'vue'
import { definePlugin } from '/@src/app'
import { ChatbotHelper, DateHelper, EventHelper, HttpHelper, StripeHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

export default definePlugin(({ router, pinia }) => {
  const isUserDataFetched = ref(false)
  const userSession = useUserSession(pinia)
  const appTitle = 'The Best AI Chatbots Creator With Your Data & Branding - InsertChat'
  const appFavicon = 'https://cdn.insertchat.com/public/favicon_black.svg'
  const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
  let errNetworkCount = 0

  router.beforeEach(async (to, from, next) => {
    try {
      const needsAuth = to.meta.requiresAuth
      const isLoggedInOrToken =
        userSession?.isLoggedIn || new URLSearchParams(window.location.search).get('token')

      userSession?.clearAllIntervals()
      ChatbotHelper.unLoadChatbot()

      if (isMaintenanceMode && to.name !== 'maintenance' && to.name !== 'embed-:uid') {
        return next({ path: '/maintenance' })
      }

      if (needsAuth && !isLoggedInOrToken) {
        return next({ path: '/auth/access', query: { redirect: to.fullPath } })
      }

      if (needsAuth && !isUserDataFetched.value) {
        try {
          isUserDataFetched.value = true

          const { user, app, subscription, plan, usage, settings, addons } = await HttpHelper.get(
            '/auth/me',
            { withoutApp: true }
          )
          const { logo, favicon, page_title } = settings

          // Batch DOM updates
          Promise.all([
            useHead({ title: plan?.limitations?.domain && page_title ? page_title : appTitle }),
            userSession?.setFavicon(plan?.limitations?.domain && favicon ? favicon : appFavicon),
            plan?.limitations?.domain && logo && userSession?.setLogo(logo),
            userSession.setApp(app),
            userSession.setSubscription(subscription),
            userSession.setAddons(addons),
            userSession.setPlan(plan),
            userSession.setUsage(usage),
            userSession.setUser(user),
          ])

          // Move analytics setup to a separate function
          setupAnalytics(userSession, user, app, subscription, plan, usage, to, from)

          // Check subscription status
          if (
            !StripeHelper.isSubscriptionActive(subscription) &&
            ![
              'subscriptions',
              'landings-pricing',
              'auth-logout',
              'subscriptions-success',
              'subscriptions-cancel',
              'settings',
            ].includes(to.name)
          ) {
            return next({ path: '/settings', query: { page: 'subscriptions' } })
          }

          next()
        } catch (error: any) {
          console.error(error)

          if (
            error?.status === 502 ||
            error?.code1 === 'base/uid-not-found' ||
            error?.code2 === 'ERR_NETWORK'
          ) {
            // Increment the counter if it's an ERR_NETWORK error
            if (error?.code2 === 'ERR_NETWORK') {
              errNetworkCount++
            }

            // Logout if ERR_NETWORK count is more than 3
            if (
              errNetworkCount > 3 ||
              error?.status === 502 ||
              error?.code1 === 'base/uid-not-found'
            ) {
              await userSession?.logoutUser()
              errNetworkCount = 0 // Reset the counter after logout
            }
          }

          next(error)
        } finally {
          isUserDataFetched.value = false
        }
      } else {
        next()
      }
    } catch (error) {
      console.error(error)

      next(error)
    }
  })
})

function setupAnalytics(userSession, user, app, subscription, plan, usage, to, from) {
  if (!userSession?.analytics) {
    try {
      EventHelper.config({ user, app, subscription, plan, usage })

      userSession?.setAnalytics(true)

      EventHelper.push('user_active', {
        uid: user?.uid,
        email: user?.email,
        timestamp: DateHelper.getNow().toMillis(),
      })
    } catch (error) {
      console.error(error)
    }
  }
}

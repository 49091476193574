<template>
  <span class="loader is-loading is-inline-block"></span>
</template>

<style lang="scss">
$grey-lighter: hsl(0deg 0% 86%) !default;
$radius-rounded: 290486px !default;

@keyframes spinAroundLoader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@mixin loader {
  animation: spinAroundLoader 500ms infinite linear;
  border: 2px solid $grey-lighter;
  border-radius: var(--radius-rounded);
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  height: 1em;
  position: relative;
  width: 1em;
  display: inline-block;
}

%loader {
  @include loader;
}
</style>

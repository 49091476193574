<script setup lang="ts">
import { PropType } from 'vue'

const props = defineProps({
  isDisabled: {
    type: Boolean,
    default: true,
    required: true,
  },
  statusEnabled: {
    type: Array as PropType<String[]>,
    default: () => ['status'],
    required: false,
  },
  columnSize: {
    type: Number,
    default: 4,
    required: true,
  },
})
</script>

<template>
  <div v-if="statusEnabled.includes('status')" :class="`column is-${props.columnSize}`">
    <FormKit
      label="Status"
      name="status"
      type="toggle"
      :off-value="false"
      :on-value="true"
      alt-label-position
      label-position="secondary"
      value-label-display="inner"
      off-value-label="OFF"
      on-value-label="ON"
      validation="required:trim"
      :disabled="props.isDisabled"
      :value="true"
    />
  </div>
  <!--
  <div v-if="statusEnabled.includes('is_agent')" :class="`column is-${props.columnSize}`">
    <FormKit
      name="is_agent"
      type="toggle"
      label="Autonomous agent"
      off-value-label="Disabled"
      on-value-label="Enabled"
      validation="required:trim"
      :value="false"
      :disabled="props.isDisabled"
    />
  </div>
  -->
  <div v-if="statusEnabled.includes('is_button')" :class="`column is-${props.columnSize}`">
    <FormKit
      label="Display in the agent bar"
      name="is_button"
      type="toggle"
      :off-value="false"
      :on-value="true"
      alt-label-position
      label-position="secondary"
      value-label-display="inner"
      off-value-label="OFF"
      on-value-label="ON"
      validation="required:trim"
      :disabled="props.isDisabled"
      :value="false"
    />
  </div>
  <div v-if="statusEnabled.includes('is_open')" :class="`column is-${props.columnSize}`">
    <FormKit
      label="Start before chat"
      name="is_open"
      type="toggle"
      :off-value="false"
      :on-value="true"
      alt-label-position
      label-position="secondary"
      value-label-display="inner"
      off-value-label="OFF"
      on-value-label="ON"
      validation="required:trim"
      :disabled="props.isDisabled"
      :value="false"
    />
  </div>
</template>

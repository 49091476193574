<script setup lang="ts">
import { ref } from 'vue'
import { ITab } from '/@src/interfaces'
import API from '/@src/pages/chatbots/integrations/api.vue'
import Events from '/@src/pages/chatbots/integrations/events.vue'
import MobileApp from '/@src/pages/chatbots/integrations/mobile_app.vue'
import Shopify from '/@src/pages/chatbots/integrations/shopify.vue'
import Webhooks from '/@src/pages/chatbots/integrations/webhooks.vue'
import Whatsapp from '/@src/pages/chatbots/integrations/whatsapp.vue'
import Zapier from '/@src/pages/chatbots/integrations/zapier.vue'
import { ICONS } from '/@src/resources/files/constant'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  plans: {
    type: Object,
    default: () => {},
    required: true,
  },
  addons: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Reactive data
const tabs = ref<ITab[]>([
  {
    label: 'JS Events',
    value: 'events',
    icon: 'ic:round-multiple-stop',
  },
  {
    label: 'Webhooks',
    value: 'webhooks',
    icon: ICONS.pages.wehhooks,
  },
  {
    label: 'API',
    value: 'api',
    icon: 'ant-design:api-outlined',
    href: 'https://bit.ly/insertchat-api',
    target: '_blank',
  },
  {
    label: 'Shopify',
    value: 'shopify',
    icon: 'ic:round-shopify',
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp',
    icon: 'ic:round-whatsapp',
  },
  {
    label: 'Zapier',
    value: 'zapier',
    icon: 'tabler:brand-zapier',
  },
  { label: 'Mobile App', value: 'mobile_app', icon: 'carbon:application-mobile' },
])
const selected = ref(tabs.value[0]?.value)
</script>

<template>
  <VTabs
    v-model:selected="selected"
    :tabs="tabs"
    :vertical="true"
    :alwaysOpen="true"
    :data="{
      widget,
      addons,
    }"
  >
    <template #tab>
      <!-- JS Events -->
      <div v-if="selected === 'events'">
        <Events :widget="props.widget" />
      </div>

      <!-- API -->
      <div v-if="selected === 'api'">
        <API :widget="props.widget" />
      </div>

      <!-- Webhooks -->
      <div v-if="selected === 'webhooks'">
        <Webhooks :widget="props.widget" :plans="props.plans" />
      </div>

      <!-- Shopify -->
      <div v-if="selected === 'shopify'">
        <Shopify :widget="props.widget" />
      </div>

      <!-- WhatsApp -->
      <div v-if="selected === 'whatsapp'">
        <Whatsapp :widget="props.widget" />
      </div>

      <!-- Zapier -->
      <div v-if="selected === 'zapier'">
        <Zapier />
      </div>

      <!-- Mobile App -->
      <div v-if="selected === 'mobile_app'">
        <MobileApp :widget="props.widget" :addons="props.addons" />
      </div>
    </template>
  </VTabs>
</template>

import { RecordRTCPromisesHandler } from 'recordrtc'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'

interface IStatus {
  isRecording: boolean
  isPaused: boolean
  isStopped: boolean
  blob?: Blob
  extension?: string
}

const notyf = useNotyf()
const constraints = {
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
    sampleRate: 96000,
    // channelCount: 2,
    latency: 0.2,
    echoCancellationType: 'system',
  },
}

export default class SttHelper {
  private recorder: RecordRTCPromisesHandler | null
  private stream: MediaStream | null
  private mimeType: string
  public isRecording: boolean
  public isStopped: boolean
  public isPaused: boolean
  private audioContext: AudioContext | null

  constructor() {
    this.recorder = null
    this.stream = null
    this.isRecording = false
    this.isStopped = true
    this.isPaused = false
    this.mimeType = 'audio/wav'
    this.audioContext = null
  }

  private returnStatus(): IStatus {
    return { isRecording: this.isRecording, isPaused: this.isPaused, isStopped: this.isStopped }
  }

  private getExtensionFromMimeType(mimeType: string): string {
    const mimeMap: { [key: string]: string } = {
      'audio/webm': 'webm',
      'audio/mp4': 'mp4',
      'audio/ogg': 'ogg',
      'audio/wav': 'wav',
      'audio/mpeg': 'mp3',
    }

    return mimeMap[mimeType] || 'webm'
  }

  private determineMimeType(): string {
    if (MediaRecorder.isTypeSupported('audio/wav')) {
      return 'audio/wav'
    } else if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
      return 'audio/webm;codecs=opus'
    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
      return 'audio/webm'
    } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
      return 'audio/ogg'
    }

    return 'audio/webm'
  }

  private setupAudioNodes(): void {
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
      sampleRate: constraints.audio.sampleRate,
    })
    const source = this.audioContext.createMediaStreamSource(this.stream!)
    const gainNode = this.audioContext.createGain()

    // Adjust gain for better audio levels
    gainNode.gain.value = 1.5

    source.connect(gainNode)
  }

  private getBrowser(): string {
    const userAgent = navigator.userAgent.toLowerCase()

    if (/chrome|crios|crmo/.test(userAgent)) return 'chrome'
    if (/firefox|fxios/.test(userAgent)) return 'firefox'
    if (/safari/.test(userAgent) && !/chrome|crios|crmo/.test(userAgent)) return 'safari'
    if (/msie|trident/.test(userAgent)) return 'ie'

    return 'other'
  }

  public pauseRecording = async (): Promise<IStatus> => {
    if (!this.recorder) {
      console.error('pauseRecording - Cannot pause recording: no recorder')

      this.isRecording = false
      this.isPaused = false
      this.isStopped = true

      return this.returnStatus()
    }

    try {
      await this.recorder.pauseRecording()

      this.isRecording = false
      this.isPaused = true
      this.isStopped = false
    } catch (error) {
      console.error('pauseRecording - Error:', error)

      this.isPaused = false
      this.isRecording = false
      this.isStopped = true
    } finally {
      return this.returnStatus()
    }
  }

  public resumeRecording = async (): Promise<IStatus> => {
    if (!this.recorder) {
      console.error('resumeRecording - Cannot resume recording: no recorder')

      this.isRecording = false
      this.isPaused = false
      this.isStopped = true

      return this.returnStatus()
    }

    try {
      this.isRecording = true
      this.isPaused = false
      this.isStopped = false

      await this.recorder.resumeRecording()
    } catch (error) {
      console.error('resumeRecording - Error:', error)

      this.isRecording = false
      this.isPaused = false
      this.isStopped = true
    } finally {
      return this.returnStatus()
    }
  }

  public askForPermission = async (): Promise<MediaStream | null> => {
    try {
      if (this.stream) {
        return this.stream
      }

      this.stream = await navigator.mediaDevices.getUserMedia(constraints)

      return this.stream
    } catch (error) {
      console.error('askForPermission - Error:', error)

      return null
    }
  }

  public startRecording = async (): Promise<IStatus> => {
    try {
      if (this.isRecording) {
        this.isRecording = true
        this.isPaused = false
        this.isStopped = false

        return this.returnStatus()
      }

      this.mimeType = this.determineMimeType()
      this.stream = await this.askForPermission()

      if (!this.stream) {
        this.isRecording = false
        this.isPaused = false
        this.isStopped = true

        return this.returnStatus()
      }

      // Setup audio processing nodes
      this.setupAudioNodes()

      this.recorder = new RecordRTCPromisesHandler(this.stream!, {
        type: 'audio',
        mimeType: this.mimeType as any,
        disableLogs: true,
        sampleRate: constraints.audio.sampleRate,
        bufferSize: 8192, // Increased buffer size
        numberOfAudioChannels: constraints.audio.channelCount,
      })

      if (!this.recorder) {
        this.isRecording = false
        this.isPaused = false
        this.isStopped = true

        return this.returnStatus()
      }

      this.isRecording = true
      this.isPaused = false
      this.isStopped = false

      await this.recorder.startRecording()
    } catch (error: any) {
      console.error('startRecording - Error:', error)

      this.isRecording = false
      this.isPaused = false
      this.isStopped = true
    } finally {
      return this.returnStatus()
    }
  }

  public stopRecording = async (): Promise<
    IStatus | { blob?: Blob | null; extension: string | null }
  > => {
    if (!this.isRecording || !this.recorder || !this.stream) {
      this.isRecording = false
      this.isPaused = false
      this.isStopped = true

      return this.returnStatus()
    }

    try {
      await this.recorder.stopRecording()
      const blob = await this.recorder.getBlob()

      if (!blob || blob.size <= 10000) {
        console.error(`stopRecording - Error - Blob size: ${blob.size}`)

        notyf.info({
          message: 'Push-to-talk - Continue holding as you speak!',
          icon: false,
        })

        this.isRecording = false
        this.isPaused = false
        this.isStopped = true

        return { ...this.returnStatus(), blob: null, extension: null }
      }

      this.stream?.getTracks().forEach((track) => {
        track.stop()
      })

      const extension = this.getExtensionFromMimeType(this.mimeType)

      this.stream = null
      this.recorder = null
      this.isRecording = false
      this.isPaused = false
      this.isStopped = true

      return { ...this.returnStatus(), blob, extension }
    } catch (error: any) {
      console.error(`stopRecording - Error: ${error?.message ?? error}`)

      this.isRecording = false
      this.isPaused = false
      this.isStopped = true

      if (error && error.includes('Empty blob')) {
        notyf.info({
          message: 'Push-to-talk - Continue holding as you speak!',
          icon: false,
        })
      }

      return { ...this.returnStatus(), blob: null, extension: null }
    } finally {
      this.isRecording = false
      this.isPaused = false
      this.isStopped = true
    }
  }

  public static transcribe = async (params: {
    widgetUid: string
    blob: Blob
    extension: string
  }): Promise<string> => {
    const { widgetUid, blob, extension } = params
    const formData = new FormData()
    formData.append('file', blob, `recording.${extension}`) // Use the correct extension here

    try {
      const response = await HttpHelper.post(
        `/embeds/audio/transcribe/${widgetUid}`,
        null,
        formData,
        {
          withoutApp: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      return response?.text || ''
    } catch (error: any) {
      console.error('transcribe - Error:', error)
      throw new Error(`Error transcribing audio: ${error?.message ?? error}`)
    }
  }
}

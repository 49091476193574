import posthog from 'posthog-js'
import { definePlugin } from '/@src/app'

export default definePlugin(({ app }) => {
  app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOG_ID, {
    api_host: 'https://app.posthog.com',
    autocapture: {
      url_allowlist: [
        'insertchatgpt.com/*',
        'app.insertchatgpt.com/*',
        'bot.insertchatgpt.com/*',
        'insertchat.com/*',
        'app.insertchat.com/*',
        'bot.insertchat.com/*',
        'development.insertchat.com/*',
      ],
    },
    rageclick: true,
    store_google: true,
    save_referrer: true,
    capture_pageview: false,
    capture_pageleave: false,
    custom_campaign_params: [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_referrer',
    ],
  })
})

<script setup lang="ts">
import { ref } from 'vue'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

// Composable
const userSession = useUserSession()

// Reactive data
const modalFormProps: any = ref({ open: false })

// Functions
const inviteAndEarn = () => {
  modalFormProps.value.open = true
}
</script>

<template>
  <FormInviteEarn
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
  />

  <li>
    <RouterLink :to="{ path: '/chatbots' }" class="single-link">
      <VIcon :icon="ICONS.pages.chats" color="#a2a5b9" />

      AI Chatbots
    </RouterLink>
  </li>
  <template v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)">
    <li>
      <RouterLink :to="{ path: '/users' }" class="single-link">
        <VIcon :icon="ICONS.pages.users" color="#a2a5b9" />

        Users
      </RouterLink>
    </li>
    <li v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)">
      <RouterLink :to="{ path: '/whitelabel' }" class="single-link">
        <VIcon :icon="ICONS.pages.whitelabel" color="#a2a5b9" />

        White-label
      </RouterLink>
    </li>
    <li v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)" class="divider"></li>
    <li v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)">
      <RouterLink :to="{ path: '/subscriptions' }" class="single-link">
        <VIcon :icon="ICONS.pages.subscriptions" color="#a2a5b9" />

        Subscriptions
      </RouterLink>
    </li>
    <!--
      <li v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)">
        <a class="single-link" @click="inviteAndEarn">
          <VIcon icon="ic:round-monetization-on" color="#a2a5b9" />

        Invite & Earn
      </a>
    </li>
    -->
    <li>
      <RouterLink :to="{ path: '/account' }" class="single-link">
        <VIcon :icon="ICONS.pages.account" color="#a2a5b9" />

        Account
      </RouterLink>
    </li>
    <li class="divider"></li>
    <li>
      <a href="https://bit.ly/insertchat-api" target="_blank" class="single-link">
        <VIcon icon="ic:round-api" color="#a2a5b9" />

        API
      </a>
    </li>
    <li>
      <a
        href="https://zapier.com/apps/ai-chatbots-by-insertchat-ca2078/integrations"
        target="_blank"
        class="single-link"
      >
        <VIcon icon="ic:round-auto-awesome" color="#a2a5b9" />

        Zapier
      </a>
    </li>
    <li>
      <a href="https://insertchat.com/demo" target="_blank" class="single-link">
        <VIcon icon="ic:round-try" color="#a2a5b9" />

        Live Demo
      </a>
    </li>
    <li>
      <a href="https://discord.com/invite/ThnrstYEcK" target="_blank" class="single-link">
        <VIcon icon="ic:round-discord" color="#a2a5b9" />

        Join our Discord
      </a>
    </li>
    <!--
    <li>
      <a href="https://insertchat.getrewardful.com/signup" target="_blank" class="single-link">
        <VIcon icon="ic:round-monetization-on" color="#a2a5b9" />

        Affiliate Program
      </a>
    </li>
    -->
  </template>
  <li class="divider"></li>
  <li>
    <RouterLink :to="{ path: '/auth/logout' }" class="single-link">
      <VIcon :icon="ICONS.pages.logout" color="#a2a5b9" />

      Logout
    </RouterLink>
  </li>
</template>

<route lang="yaml">
meta:
  requiresAuth: false
</route>

<template>
  <LandingLayout :iframe="true">
    <div class="confirm-account-wrapper">
      <div class="wrapper-inner">
        <div class="action-box">
          <div class="box-content">
            <img src="https://cdn.insertchat.com/illustrations/wizard/finish.svg" alt="" />
            <div class="title is-4 dark-inverted">Scheduled maintenance</div>
            <hr />
            Dear User,<br /><br />

            Thank you for using our app! We want to inform you that we are currently undergoing
            scheduled maintenance to enhance your user experience.<br /><br />

            During this time, the app may not be accessible, and some features may be temporarily
            unavailable. We apologize for any inconvenience caused.<br /><br />

            Rest assured that our team is working diligently to complete the maintenance as quickly
            as possible. We estimate that it will take less then 1 hour to finalize the updates and
            improvements.<br /><br />

            We appreciate your patience and understanding during this brief period. Once the
            maintenance is complete, you can look forward to an even better app with improved
            performance and new features.<br /><br />

            If you have any urgent concerns or questions, please feel free to reach out to our
            support team, who will be happy to assist you. We value your feedback and are committed
            to providing you with the best possible user experience.<br /><br />

            Thank you for your cooperation, and we apologize for any inconvenience caused. We will
            notify you as soon as the maintenance is complete, and the app is fully operational
            again.<br /><br />

            Best regards,<br />
            Zak, Founder & CEO
          </div>
        </div>
      </div>
    </div>
  </LandingLayout>
</template>

<style lang="scss">
@import '/@src/scss/abstracts/all';

.confirm-account-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  min-height: 560px;
  margin: 0 auto;

  .wrapper-inner {
    .action-box {
      @include vuero-s-card;

      padding: 40px;
      border: none !important;

      .box-content {
        font-family: var(--font);
        text-align: center;

        img {
          display: block;
          width: 100%;
          max-width: 220px;
          margin: 0 auto 8px;

          &.is-larger {
            max-width: 300px;
          }
        }

        h3 {
          font-size: 1.1rem;
          font-family: var(--font-alt);
          font-weight: 600;
          max-width: 320px;
          margin: 0 auto 8px;

          span {
            color: var(--primary);
          }
        }

        .buttons {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          padding-top: 30px;

          .button {
            margin: 0 4px;
            min-width: 180px;
          }
        }
      }
    }
  }
}

.is-dark {
  .confirm-account-wrapper {
    .wrapper-inner {
      .action-box {
        @include vuero-card--dark;
      }
    }

    .wrapper-outer {
      @include vuero-card--dark;
    }
  }
}

@media only screen and (max-width: 767px) {
  .confirm-account-wrapper {
    .wrapper-inner {
      .action-box {
        padding: 20px;

        .box-content {
          .buttons {
            .button {
              min-width: 130px;
            }
          }
        }
      }
    }
  }
}
</style>

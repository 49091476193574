import posthog from 'posthog-js'
import { IUser } from '/@src/interfaces'

export default class PostHogHelper {
  public static config(user: IUser) {
    try {
      posthog.identify(user.uid, user)
    } catch (error) {
      console.error(error)
    }
  }

  public static push(event: string, data?: object | undefined) {
    try {
      posthog.capture(event, data)
    } catch (error) {
      console.error(error)
    }
  }
}

<script setup lang="ts">
import messages from '@intlify/unplugin-vue-i18n/messages'
import { computed, onMounted, PropType, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, CookiesHelper, EventHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import Embed from '/@src/pages/embed/:uid.vue'
import { ICONS, MESSAGE_WIDTH_OPTIONS } from '/@src/resources/files/constant'
import fonts from '/@src/resources/files/fonts.json'
import { useUserSession } from '/@src/stores'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
  plans: {
    type: Object,
    default: () => {},
    required: true,
  },
  addons: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
const route = useRoute()

// Const data
const formId = 'formBranding'
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid
const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.ico', '.webp']
const allowedExtensionsString = allowedExtensions.join(', ')
const maxFileSize = 200
const tabs = ref([
  { label: 'Chatbot', value: 'chatbot', icon: ICONS.pages.chat_bubble },
  { label: 'Images', value: 'images', icon: ICONS.pages.images },
  { label: 'Styling', value: 'styling', icon: ICONS.pages.styling },
  { label: 'Welcome', value: 'welcome_screen', icon: ICONS.pages.welcome },
])

// Reactive data
const isLoading = ref(true)
const formData: any = ref({})
const formState: any = reactive({})
const selected = ref(tabs.value[0]?.value)
const embedKey = ref(0)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true

    if (widgetUid) {
      const { computed_settings } = await HttpHelper.get(`/widgets/${widgetUid}`)

      const { bubble, welcome_screen, styling, images } = computed_settings

      formData.value = { bubble, welcome_screen, styling, images }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { bubble, welcome_screen, styling, images } = fields

    const imageFields = ['logo', 'user_avatar', 'assistant_avatar', 'open_icon', 'close_icon']
    const newImages: { [key: string]: any } = {}

    // Delete images keys
    imageFields.forEach((field) => {
      const key = `new_${field}`
      const newField = fields?.images?.[field]?.[key]

      if (newField) {
        newImages[key] = newField

        delete fields?.images?.[field]?.[key]
      }
    })

    await HttpHelper.post('/widgets', widgetUid, {
      bubble,
      welcome_screen,
      styling,
      images,
    })

    const { new_logo, new_user_avatar, new_assistant_avatar, new_open_icon, new_close_icon } =
      newImages

    if (new_logo || new_user_avatar || new_assistant_avatar || new_open_icon || new_close_icon) {
      const data = new FormData()

      new_logo?.[0]?.file && data.append('new_logo', new_logo?.[0]?.file)
      new_user_avatar?.[0]?.file && data.append('new_user_avatar', new_user_avatar?.[0]?.file)
      new_assistant_avatar?.[0]?.file &&
        data.append('new_assistant_avatar', new_assistant_avatar?.[0]?.file)
      new_open_icon?.[0]?.file && data.append('new_open_icon', new_open_icon?.[0]?.file)
      new_close_icon?.[0]?.file && data.append('new_close_icon', new_close_icon?.[0]?.file)

      const response = await HttpHelper.post('/widgets/images', widgetUid, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      formData.value = { ...response?.computed_settings }

      if (new_logo) {
        EventHelper.push('change_interface')
      }
    }

    CookiesHelper.remove(`chat_${widgetUid}`)
    ChatbotHelper.refreshChatbot(widgetUid)

    embedKey.value++

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit
    :id="formId"
    v-model="formData"
    type="form"
    :config="{ validationVisibility: 'live' }"
    :disabled="isLoading"
    :actions="false"
    :classes="{
      form: 'h-100',
    }"
    :preserve="true"
    @submit="onSubmit"
  >
    <VTabs v-model:selected="selected" :tabs="tabs" :vertical="true" :alwaysOpen="true">
      <template #tab>
        <VLoader v-if="isLoading" class="h-100" :translucent="true" :opaque="true" />

        <template v-if="selected === 'images'">
          <!-- Images -->
          <CustomForm title="Images" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit name="images" type="group" :disabled="isLoading">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <VMessage color="info">
                      Your images shouldn't have any prominent edges, whether transparent or white.
                    </VMessage>
                  </div>

                  <div class="column is-12">
                    <FormKit name="logo" type="group" :disabled="isLoading">
                      <CustomForm title="Logo">
                        <template #buttons>
                          <FormKit
                            label=""
                            name="status"
                            type="toggle"
                            :off-value="false"
                            :on-value="true"
                            label-position="secondary"
                            value-label-display="inner"
                            off-value-label="OFF"
                            on-value-label="ON"
                            validation="required:trim"
                            :disabled="isLoading"
                            :classes="{ outer: 'm-0 mr-4' }"
                          />
                        </template>
                        <template #body>
                          <div v-if="formData?.images?.logo?.url" class="columns is-vcentered">
                            <div class="column is-align-self-center is-flex">
                              <img
                                :src="formData?.images?.logo?.url"
                                alt="Logo"
                                style="height: 40px"
                              />
                            </div>

                            <div class="column is-narrow is-flex">
                              <FormKitControls
                                :is-loading="false"
                                :is-vertical="true"
                                :disable-insert="true"
                                :disable-refresh="true"
                                @delete="formData.images.logo.url = ''"
                              />
                            </div>
                          </div>

                          <FormKit
                            v-else
                            type="file"
                            name="new_logo"
                            label="Logo"
                            :accept="allowedExtensionsString"
                            multiple="false"
                            :disabled="isLoading"
                            :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB`"
                          />
                        </template>
                      </CustomForm>
                    </FormKit>
                  </div>

                  <!-- User Avatar -->
                  <div class="column is-6">
                    <FormKit name="user_avatar" type="group" :disabled="isLoading">
                      <CustomForm title="User Avatar">
                        <template #buttons>
                          <FormKit
                            label=""
                            name="status"
                            type="toggle"
                            :off-value="false"
                            :on-value="true"
                            label-position="secondary"
                            value-label-display="inner"
                            off-value-label="OFF"
                            on-value-label="ON"
                            validation="required:trim"
                            :disabled="isLoading"
                            :classes="{ outer: 'm-0 mr-4' }"
                          />
                        </template>

                        <template #body>
                          <div
                            v-if="formData?.images?.user_avatar?.url"
                            class="columns is-vcentered"
                          >
                            <div class="column is-align-self-center is-flex">
                              <img
                                :src="formData?.images?.user_avatar?.url"
                                alt=""
                                style="height: 40px"
                              />
                            </div>

                            <div class="column is-narrow is-flex">
                              <FormKitControls
                                :is-loading="false"
                                :is-vertical="true"
                                :disable-insert="true"
                                :disable-refresh="true"
                                @delete="formData.images.user_avatar.url = ''"
                              />
                            </div>
                          </div>

                          <FormKit
                            v-else
                            type="file"
                            name="new_user_avatar"
                            label="User avatar (40x40)"
                            :accept="allowedExtensionsString"
                            multiple="false"
                            :disabled="isLoading"
                            :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB`"
                          />
                        </template>
                      </CustomForm>
                    </FormKit>
                  </div>

                  <!-- Assistant Avatar -->
                  <div class="column is-6">
                    <FormKit name="assistant_avatar" type="group" :disabled="isLoading">
                      <CustomForm title="Assistant Avatar">
                        <template #buttons>
                          <FormKit
                            label=""
                            name="status"
                            type="toggle"
                            :off-value="false"
                            :on-value="true"
                            label-position="secondary"
                            value-label-display="inner"
                            off-value-label="OFF"
                            on-value-label="ON"
                            validation="required:trim"
                            :disabled="isLoading"
                            :classes="{ outer: 'm-0 mr-4' }"
                          />
                        </template>

                        <template #body>
                          <div
                            v-if="formData?.images?.assistant_avatar?.url"
                            class="columns is-vcentered"
                          >
                            <div class="column is-align-self-center is-flex">
                              <img
                                :src="formData?.images?.assistant_avatar?.url"
                                alt=""
                                style="height: 40px"
                              />
                            </div>

                            <div class="column is-narrow is-flex">
                              <FormKitControls
                                :is-loading="false"
                                :is-vertical="true"
                                :disable-insert="true"
                                :disable-refresh="true"
                                @delete="formData.images.assistant_avatar.url = ''"
                              />
                            </div>
                          </div>

                          <FormKit
                            v-else
                            type="file"
                            name="new_assistant_avatar"
                            label="AI avatar (40x40)"
                            :accept="allowedExtensionsString"
                            multiple="false"
                            :disabled="isLoading"
                            :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB`"
                          />
                        </template>
                      </CustomForm>
                    </FormKit>
                  </div>

                  <!-- Open icon -->
                  <FormKit name="open_icon" type="group" :disabled="isLoading">
                    <div class="column is-6">
                      <CustomForm title="Open Icon">
                        <template #body>
                          <div v-if="formData?.images?.open_icon?.url" class="columns is-vcentered">
                            <div class="column is-align-self-center is-flex">
                              <img
                                :src="formData?.images?.open_icon?.url"
                                alt=""
                                :style="{
                                  height: '40px',
                                  backgroundColor: formData?.bubble?.background,
                                  borderRadius: '100%',
                                  padding: '10px',
                                }"
                              />
                            </div>

                            <div class="column is-narrow is-flex">
                              <FormKitControls
                                v-if="userSession.getPlanLimitations('branding')"
                                :is-loading="false"
                                :is-vertical="true"
                                :disable-insert="true"
                                :disable-refresh="true"
                                @delete="formData.images.open_icon.url = ''"
                              />
                            </div>
                          </div>

                          <FormKit
                            v-else
                            type="file"
                            name="new_open_icon"
                            label="Open icon (40x40)"
                            :accept="allowedExtensionsString"
                            multiple="false"
                            :disabled="isLoading || !userSession.getPlanLimitations('branding')"
                            :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB`"
                          />
                        </template>
                      </CustomForm>
                    </div>
                  </FormKit>

                  <!-- Close icon -->
                  <FormKit name="close_icon" type="group" :disabled="isLoading">
                    <div class="column is-6">
                      <CustomForm title="Close Icon">
                        <template #body>
                          <div
                            v-if="formData?.images?.close_icon?.url"
                            class="columns is-vcentered"
                          >
                            <div class="column is-align-self-center is-flex">
                              <img
                                :src="formData?.images?.close_icon?.url"
                                alt=""
                                :style="{
                                  height: '40px',
                                  backgroundColor: formData?.bubble?.background,
                                  borderRadius: '100%',
                                  padding: '10px',
                                }"
                              />
                            </div>

                            <div class="column is-narrow is-flex">
                              <FormKitControls
                                v-if="userSession.getPlanLimitations('branding')"
                                :is-loading="false"
                                :is-vertical="true"
                                :disable-insert="true"
                                :disable-refresh="true"
                                @delete="formData.images.close_icon.url = ''"
                              />
                            </div>
                          </div>

                          <FormKit
                            v-else
                            type="file"
                            name="new_close_icon"
                            label="Close icon (40x40)"
                            :accept="allowedExtensionsString"
                            multiple="false"
                            :disabled="isLoading || !userSession.getPlanLimitations('branding')"
                            :help="`Accepted formats: ${allowedExtensionsString} - Max size: ${maxFileSize}KB`"
                          />
                        </template>
                      </CustomForm>
                    </div>
                  </FormKit>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <template v-if="selected === 'styling'">
          <!-- Window & Messages -->
          <CustomForm title="Styling" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit name="styling" type="group" :disabled="isLoading">
                <!-- window -->
                <FormKit name="window" type="group" :disabled="isLoading">
                  <CustomForm title="Window">
                    <template #body>
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <FormKit
                            name="font"
                            type="dropdown"
                            label="Fonts"
                            :options="fonts"
                            :disabled="isLoading"
                            placeholder="Default"
                            :close-on-select="true"
                          >
                            <template #option="{ option }">
                              <div class="formkit-option">
                                <div class="text-container">
                                  <div>
                                    {{ option.label }}
                                  </div>
                                  <p class="option-overview">
                                    {{ option.help }}
                                  </p>
                                </div>
                              </div>
                            </template>
                          </FormKit>
                        </div>
                        <div class="column is-6">
                          <FormKit
                            name="size"
                            type="number"
                            label="Text Size (pixel)"
                            min="0.1"
                            step="0.05"
                            validation="required:trim"
                            :disabled="isLoading"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            type="colorpicker"
                            name="background"
                            label="Background Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            type="colorpicker"
                            name="color"
                            label="Text Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            type="colorpicker"
                            name="icons"
                            label="Icon Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            type="colorpicker"
                            name="border"
                            label="Border Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                      </div>
                    </template>
                  </CustomForm>
                </FormKit>

                <!-- user_message -->
                <FormKit name="user_message" type="group" :disabled="isLoading">
                  <CustomForm title="User Message">
                    <template #body>
                      <div class="columns is-multiline">
                        <div class="column is-4">
                          <FormKit
                            type="colorpicker"
                            name="background"
                            label="Background Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-4">
                          <FormKit
                            type="colorpicker"
                            name="color"
                            label="Text Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-4">
                          <FormKit
                            name="width"
                            type="select"
                            label="Width"
                            :options="MESSAGE_WIDTH_OPTIONS"
                            validation="required:trim"
                            :disabled="isLoading"
                          />
                        </div>
                      </div>
                    </template>
                  </CustomForm>
                </FormKit>

                <!-- assistant_message -->
                <FormKit name="assistant_message" type="group" :disabled="isLoading">
                  <CustomForm title="AI Message">
                    <template #body>
                      <div class="columns is-multiline">
                        <div class="column is-4">
                          <FormKit
                            type="colorpicker"
                            name="background"
                            label="Background Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-4">
                          <FormKit
                            type="colorpicker"
                            name="color"
                            label="Text Color"
                            validation="required:trim"
                            eye-dropper="true"
                            :disabled="isLoading"
                            :classes="{ inner: 'w-100' }"
                          />
                        </div>
                        <div class="column is-4">
                          <FormKit
                            name="width"
                            type="select"
                            label="Width"
                            :options="MESSAGE_WIDTH_OPTIONS"
                            validation="required:trim"
                            :disabled="isLoading"
                          />
                        </div>
                      </div>
                    </template>
                  </CustomForm>
                </FormKit>
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <template v-if="selected === 'chatbot'">
          <!-- Chatbot -->
          <CustomForm title="Chatbot" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit v-slot="{ value }" name="bubble" type="group" :disabled="isLoading">
                <div class="columns is-multiline">
                  <div class="column is-2">
                    <FormKit
                      label="Auto open on load"
                      name="is_open"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-2">
                    <FormKit
                      label="Share button"
                      name="status_share_button"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-2">
                    <FormKit
                      label="Feedback buttons"
                      name="status_feedback_button"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-2">
                    <FormKit
                      label="Copy button"
                      name="status_copy_button"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-2">
                    <FormKit
                      label="Listen button"
                      name="status_listen_button"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-2">
                    <FormKit
                      label="Microphone button"
                      name="status_microphone_button"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      label-position="secondary"
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-12">
                    <FormKit
                      type="colorpicker"
                      name="background"
                      label="Background Color"
                      validation="required:trim"
                      eye-dropper="true"
                      :disabled="isLoading"
                      :classes="{ inner: 'w-100' }"
                    />
                  </div>

                  <div class="column is-6">
                    <FormKit
                      name="position"
                      type="select"
                      label="Position"
                      :options="messages.en.forms.branding.positions"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-6">
                    <FormKit
                      name="size"
                      type="number"
                      label="Bubble size (pixel)"
                      min="0.1"
                      step="0.05"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-12">
                    <FormKit
                      name="placeholder"
                      type="text"
                      label="Placeholder"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-6">
                    <FormKit
                      name="bottom"
                      type="number"
                      label="Elevation from the bottom"
                      min="0.1"
                      step="0.05"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>

                  <div class="column is-6">
                    <FormKit
                      name="alignment"
                      type="number"
                      :label="`Offset with your live chatbot (Intercom, Crisp, ...)`"
                      min="0.1"
                      step="0.05"
                      validation="required:trim"
                      :disabled="isLoading"
                    />
                  </div>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <template v-if="selected === 'welcome_screen'">
          <CustomForm title="Welcome Screen" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit name="welcome_screen" type="group" :disabled="isLoading">
                <div class="columns is-multiline">
                  <!-- status -->
                  <div class="column is-12">
                    <FormKit
                      label="Status"
                      name="status"
                      type="toggle"
                      :off-value="false"
                      :on-value="true"
                      alt-label-position
                      value-label-display="inner"
                      off-value-label="OFF"
                      on-value-label="ON"
                      validation="required:trim"
                      :disabled="isLoading"
                      :classes="{ outer: 'm-0 mr-4' }"
                    />
                  </div>

                  <!-- title -->
                  <FormKit name="title" type="group" :disabled="isLoading">
                    <div class="column is-6">
                      <CustomForm title="Title">
                        <template #buttons>
                          <FormKit
                            label=""
                            name="status"
                            type="toggle"
                            :off-value="false"
                            :on-value="true"
                            label-position="secondary"
                            value-label-display="inner"
                            off-value-label="OFF"
                            on-value-label="ON"
                            validation="required:trim"
                            :disabled="isLoading"
                            :classes="{ outer: 'm-0 mr-4' }"
                          />
                        </template>

                        <template #body>
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <FormKit
                                name="text"
                                type="textarea"
                                label="Text"
                                validation="required:trim"
                                :disabled="isLoading"
                              />
                            </div>
                            <div class="column">
                              <FormKit
                                type="colorpicker"
                                name="color"
                                label="Text Color"
                                validation="required:trim"
                                eye-dropper="true"
                                :disabled="isLoading"
                                :classes="{ inner: 'w-100' }"
                              />
                            </div>
                            <div class="column">
                              <FormKit
                                name="size"
                                type="number"
                                label="Text Size (pixel)"
                                min="0.1"
                                step="0.05"
                                validation="required:trim"
                              />
                            </div>
                          </div>
                        </template>
                      </CustomForm>
                    </div>
                  </FormKit>

                  <!-- description -->
                  <FormKit name="description" type="group" :disabled="isLoading">
                    <div class="column is-6">
                      <CustomForm title="Description">
                        <template #buttons>
                          <FormKit
                            label=""
                            name="status"
                            type="toggle"
                            :off-value="false"
                            :on-value="true"
                            label-position="secondary"
                            value-label-display="inner"
                            off-value-label="OFF"
                            on-value-label="ON"
                            validation="required:trim"
                            :disabled="isLoading"
                            :classes="{ outer: 'm-0 mr-4' }"
                          />
                        </template>

                        <template #body>
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <FormKit
                                name="text"
                                type="textarea"
                                label="Text"
                                validation="required:trim"
                                :disabled="isLoading"
                              />
                            </div>
                            <div class="column">
                              <FormKit
                                type="colorpicker"
                                name="color"
                                label="Text Color"
                                validation="required:trim"
                                eye-dropper="true"
                                :disabled="isLoading"
                                :classes="{ inner: 'w-100' }"
                              />
                            </div>
                            <div class="column">
                              <FormKit
                                name="size"
                                type="number"
                                label="Text Size (pixel)"
                                min="0.1"
                                step="0.05"
                                validation="required:trim"
                              />
                            </div>
                          </div>
                        </template>
                      </CustomForm>
                    </div>
                  </FormKit>

                  <!-- cards -->
                  <FormKit name="cards" type="group" :disabled="isLoading">
                    <div class="column is-12">
                      <CustomForm title="Cards">
                        <template #body>
                          <!-- styling -->
                          <FormKit name="styling" type="group" :disabled="isLoading">
                            <!-- title -->
                            <CustomForm title="Titles Styling">
                              <template #body>
                                <FormKit name="title" type="group" :disabled="isLoading">
                                  <div class="columns is-multiline">
                                    <div class="column is-6">
                                      <FormKit
                                        name="size"
                                        type="number"
                                        label="Text Size (pixel)"
                                        min="0.1"
                                        step="0.05"
                                        validation="required:trim"
                                      />
                                    </div>
                                    <div class="column is-6">
                                      <FormKit
                                        type="colorpicker"
                                        name="color"
                                        label="Text Color"
                                        validation="required:trim"
                                        eye-dropper="true"
                                        :disabled="isLoading"
                                        :classes="{ inner: 'w-100' }"
                                      />
                                    </div>
                                  </div>
                                </FormKit>
                              </template>
                            </CustomForm>

                            <!-- prompt -->
                            <CustomForm title="Prompt Cards Styling">
                              <template #body>
                                <FormKit name="prompt" type="group" :disabled="isLoading">
                                  <div class="columns">
                                    <div class="column is-4">
                                      <FormKit
                                        name="size"
                                        type="number"
                                        label="Text Size (pixel)"
                                        min="0.1"
                                        step="0.05"
                                        validation="required:trim"
                                      />
                                    </div>
                                    <div class="column is-4">
                                      <FormKit
                                        type="colorpicker"
                                        name="color"
                                        label="Text Color"
                                        validation="required:trim"
                                        eye-dropper="true"
                                        :disabled="isLoading"
                                        :classes="{ inner: 'w-100' }"
                                      />
                                    </div>
                                    <div class="column is-4">
                                      <FormKit
                                        type="colorpicker"
                                        name="background"
                                        label="Background Color"
                                        validation="required:trim"
                                        eye-dropper="true"
                                        :disabled="isLoading"
                                        :classes="{ inner: 'w-100' }"
                                      />
                                    </div>
                                  </div>
                                </FormKit>
                              </template>
                            </CustomForm>
                          </FormKit>

                          <!-- items -->
                          <CustomForm title="Cards">
                            <template #body>
                              <FormKit
                                name="items"
                                type="repeater"
                                :preserve="false"
                                :disabled="isLoading"
                                :up-control="false"
                                :down-control="true"
                                :add-button="true"
                                :insert-control="false"
                                :remove-control="true"
                                min="0"
                                max="3"
                              >
                                <div
                                  class="columns is-multiline"
                                  style="
                                    border: 1px solid var(--border);
                                    border-radius: 16px;
                                    margin: 0px;
                                  "
                                >
                                  <div class="column is-12">
                                    <FormKit
                                      name="title"
                                      type="text"
                                      label="Title"
                                      validation="required:trim"
                                      :disabled="isLoading"
                                    />
                                  </div>

                                  <div class="column is-12">
                                    <FormKit
                                      v-slot="{ index }"
                                      name="prompts"
                                      type="repeater"
                                      :disabled="isLoading"
                                      min="1"
                                      :up-control="false"
                                      :down-control="true"
                                      :add-button="false"
                                      :insert-control="true"
                                      :remove-control="true"
                                    >
                                      <div class="columns is-multiline">
                                        <div class="column is-6">
                                          <FormKit
                                            name="text"
                                            type="text"
                                            :label="`Text(Button) ${index + 1}`"
                                            validation="required:trim"
                                            :disabled="isLoading"
                                          />
                                        </div>
                                        <div class="column is-6">
                                          <FormKit
                                            name="prompt"
                                            type="text"
                                            :label="`User Prompt ${index + 1}`"
                                            validation="required:trim"
                                            :disabled="isLoading"
                                          />
                                        </div>
                                      </div>
                                    </FormKit>
                                  </div>
                                </div>
                              </FormKit>
                            </template>
                          </CustomForm>
                        </template>
                      </CustomForm>
                    </div>
                  </FormKit>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </template>
      </template>
    </VTabs>
  </FormKit>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { getNode } from '@formkit/core'
import { HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

interface IProps {
  open: boolean
  url?: string
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: IProps): void
  (e: 'close', value: string[]): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  modelValue: {
    type: Object as PropType<IProps>,
    required: true,
  },
})

// Const data
const formId = 'formCrawler'

// Reactive data
const { open, url } = toRefs(props?.modelValue)
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})

// Composable
const userSession = useUserSession()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Watch
watch(open, () => {
  emit('update:modelValue', {
    open: open.value,
  })
})

// Lifecycle hooks
onBeforeMount(() => {
  if (url?.value) {
    formData.value.url = url.value
  }
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const { url, deep_mode } = fields

    const urls = await HttpHelper.post('/providers/deep-crawler', null, {
      widget_uid: props.widgetUid,
      url,
      deep_mode,
    })

    formData.value.url = ''

    // Close modal
    close(urls)
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const close = (urls?: string[]) => {
  if (urls) {
    emit('close', urls)
  }

  emit('update:modelValue', {
    open: false,
  })
}
</script>

<template>
  <CustomModal
    size="medium"
    :open="open"
    title="Extract URLs (Support Sitemaps)"
    :override-modal="false"
    :narrow="true"
    :noscroll="false"
    :noclose="false"
    z-index="2147483647"
    @close="close"
  >
    <VMessage color="info" class="w-100">
      <p>- Submit a sitemap.xml to extract all URLs.</p>
      <p>- Each crawled URL costs 1 credit.</p>
      <p>- The crawler respects your plan's URL limit.</p>
      <p v-if="userSession?.plan?.limitations?.byok">
        - Use your OpenAI keys to avoid credit limits.
      </p>
    </VMessage>

    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :config="{ validationVisibility: 'live' }"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <FormKit
        type="url"
        name="url"
        label="URL"
        validation="required:trim|url"
        placeholder="https://example.com"
      />

      <FormKit
        :label="
          formData.deep_mode
            ? 'Extract all the URLs of this domain name. (Deep Mode: ON)'
            : 'Extract only the URLs of this webpage. (Deep Mode: OFF)'
        "
        name="deep_mode"
        type="toggle"
        :off-value="false"
        :on-value="true"
        label-position="secondary"
        value-label-display="inner"
        off-value-label="OFF"
        on-value-label="ON"
        validation="required:trim"
        :disabled="isLoading"
        :value="false"
      />

      <!--
        <FormKit
          type="select"
          name="strategy"
          label="Strategy"
          validation="required:trim"
          :options="[
          {
            label: 'Same domain (Ex : example.com)',
            value: 'domain',
          },
          {
            label: 'Same hostname (Ex : www.example.com)',
            value: 'hostname',
          },
          {
            label: 'Same origin (Ex : https://foo.example.com)',
            value: 'origin',
          },
          ]"
        />
      -->
    </FormKit>

    <template #action>
      <div class="form-footer">
        <div class="left"></div>

        <div class="right">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Confirm
          </VButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Composable
const viewWrapper = useViewWrapper()

// Const data
const formId = 'formAutoTopup'
const title = 'Auto Top-up'
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.top_up)
useHead({ title })

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true

    const settingsResponse = await HttpHelper.get(`/apps/settings`)

    if (settingsResponse?.auto_topup && Object.keys(settingsResponse?.auto_topup).length) {
      formData.value = {
        ...settingsResponse?.auto_topup,
      }
    } else {
      formData.value.queries_auto_topup = false
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    const { queries_auto_topup, queries_below, queries_purchase } = fields

    if (!queries_auto_topup) {
      return
    }

    if (queries_below < 5000) {
      notyf.info(`The fall below threshold needs to be equal to or greater than 5000 credits.`)

      return
    }

    if (queries_purchase < 10000) {
      notyf.info(`The minimum purchase needs to be equal to or greater then 10000 credits.`)

      return
    }

    await HttpHelper.post('/apps/settings', null, { auto_topup: fields })

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <h1 class="title is-5">{{ title }}</h1>

  <CustomForm title="Configuration" subtitle="">
    <template #buttons>
      <VButton
        :disabled="isFormDisabled"
        :loading="isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit"
      >
        Save Changes
      </VButton>
    </template>

    <template #body>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :config="{ validationVisibility: 'live' }"
        :disabled="isLoading"
        :actions="false"
        @submit="onSubmit"
      >
        <div class="columns is-multiline">
          <VMessage class="column is-12 p-0 mt-3 mb-3" color="info">
            Automatically purchase credits when your balance is below a certain threshold. Your most
            recent payment method will be used.
          </VMessage>

          <div class="column is-12">
            <FormKit
              label="Enable auto top-up"
              name="queries_auto_topup"
              type="toggle"
              :off-value="false"
              :on-value="true"
              alt-label-position
              value-label-display="inner"
              off-value-label="OFF"
              on-value-label="ON"
              validation="required:trim"
              :disabled="isLoading"
            />

            <template v-if="formData.queries_auto_topup">
              <FormKit
                type="number"
                label="When credits are below this threshold (Min: 5000)"
                name="queries_below"
                validation="required:trim"
                :disabled="isLoading"
                min="5000"
                step="5000"
                number="integer"
              />

              <FormKit
                type="number"
                label="Purchase this amount of credits (Min: 10000)"
                name="queries_purchase"
                validation="required:trim"
                :disabled="isLoading"
                prefix-icon="dollar"
                :help="`Cost : $${formData.queries_purchase ? formData.queries_purchase * 0.01 : 0}`"
                min="10000"
                step="5000"
                number="integer"
              />
            </template>
          </div>
        </div>
      </FormKit>
    </template>
  </CustomForm>
</template>

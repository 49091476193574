<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Reactive data
const isLoading = ref<boolean>(false)
const sessionData = ref<{ phone_number_id?: string; waba_id?: string }>({})

// Const data
const WHATSAPP_APP_ID = import.meta.env.VITE_WHATSAPP_APP_ID
const WHATSAPP_CONFIG_ID = import.meta.env.VITE_WHATSAPP_CONFIG_ID
const isWhatsAppSetup = ref(false)

// Composable
const notyf = useNotyf()

// Lifecycle hooks
onBeforeMount(() => {
  try {
    // Facebook SDK
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

    // Facebook init
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: WHATSAPP_APP_ID,
        autoLogAppEvents: true,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      })

      // @ts-ignore
      FB.AppEvents.logPageView()
    }
  } catch (error) {
    console.error(error)
  }
})

onMounted(async () => {
  try {
    isLoading.value = true

    const response = await HttpHelper.get(`/widgets/${props?.widget?.uid}`)

    isWhatsAppSetup.value = response?.is_whatsapp_setup
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Function
const launchWhatsAppSignup = async (widgetUid: string) => {
  // https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow

  try {
    // Conversion tracking code
    window.fbq &&
      window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
        appId: WHATSAPP_APP_ID,
        feature: 'whatsapp_embedded_signup',
      })

    // @ts-ignore
    FB.getLoginStatus((response1: any) => {
      console.log('getLoginStatus - response 1', response1)

      if (response1.status === 'connected') {
        // @ts-ignore
        FB.logout((response2: any) => {
          console.log('logout - response 1', response2)
        })
      }
    })

    // @ts-ignore
    FB.login(
      (response: any) => {
        console.log('login - response', response)
        console.log('sessionData.value', sessionData.value)

        if (response.authResponse) {
          console.log('response.authResponse', response.authResponse)

          isLoading.value = true

          // const accessToken = response.authResponse.accessToken
          const phoneNumberId = sessionData.value?.phone_number_id
          const wabaId = sessionData.value?.waba_id

          if (!phoneNumberId || !wabaId) {
            console.error('Missing required session data')

            notyf.error('Unable to complete setup. Missing required information.')

            isLoading.value = false

            return
          }

          HttpHelper.post(
            'whatsapp/setup',
            widgetUid,
            {
              code: response?.authResponse?.code,
              phone_number_id: sessionData.value?.phone_number_id,
              waba_id: sessionData.value?.waba_id,
            },
            { withoutApp: false },
            () => {
              isWhatsAppSetup.value = true
              isLoading.value = false
            }
          ).catch((error) => {
            console.error(error)

            isWhatsAppSetup.value = false
            isLoading.value = false
          })
        } else {
          console.log('User cancelled login or did not fully authorize.')
          isLoading.value = false
        }
      },
      {
        config_id: WHATSAPP_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        scope: 'whatsapp_business_management, whatsapp_business_messaging',
        extras: {
          featureType: '',
          version: 2,
          sessionInfoVersion: 3,
        },
      }
    )
  } catch (error) {
    isLoading.value = false

    console.error(error)
  }
}

const deregister = async (widgetUid: string) => {
  try {
    isLoading.value = true

    await HttpHelper.post('whatsapp/deregister', widgetUid, {}, { withoutApp: false }, () => {
      isWhatsAppSetup.value = false
    })

    notyf.success(
      `Your phone number has been successfully deregistered. Please note that you still need to delete it from the Meta Business Platform if you intend to reuse it.<br /><br />To do so, got to the following page <a target="_blank" href="https://business.facebook.com/wa/manage/phone-numbers/">Phone numbers</a> then navigate to Settings > Business Settings > WhatsApp Accounts > WhatsApp Manager > Phone Numbers.`
    )
  } catch (error) {
    console.error(error)

    notyf.error('Unable to complete the action!')
  } finally {
    isLoading.value = false
  }
}

// Attach the event listener
window.addEventListener('message', (event: any) => {
  console.log('sessionInfoListener', event)
  console.log('data', event?.data)

  if (!event.origin) {
    console.warn('Received event with no origin:', event)
    return
  }

  if (!event.origin.endsWith('facebook.com')) {
    console.warn(`Ignored event from unauthorized origin: ${event.origin}`)
    return
  }

  try {
    let data

    try {
      data = JSON.parse(event.data)
    } catch (error) {
      console.error('Error parsing event data:', error)
      isLoading.value = false
      return
    }

    console.log('data', data)

    if (data) {
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data

          console.log(
            'Phone number ID ',
            phone_number_id,
            ' WhatsApp business account ID ',
            waba_id
          )

          if (phone_number_id && waba_id) {
            sessionData.value = { phone_number_id, waba_id }
          }
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === 'ERROR') {
          const { error_message } = data.data
          console.error('error ', error_message)
          isLoading.value = false
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data
          console.warn('Cancel at ', current_step)
          isLoading.value = false
        }
      }
    }
  } catch (error) {
    isLoading.value = false

    console.error(error)
  }
})
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <VMessage color="info">
        <ul class="ul-list-style">
          <li>
            Your InsertChat AI Assistant will respond to messages sent to a specific WhatsApp
            number.
          </li>
          <li>
            You can't use a phone number that's already linked to WhatsApp or WhatsApp Business with
            InsertChat AI.
          </li>
          <li>
            During Meta onboarding, please provide all necessary business details like its name,
            website, category, and description.
          </li>
          <li>
            To access your WhatsApp accounts in your Facebook Business portfolio,
            <a href="https://business.facebook.com/latest/settings/whatsapp_account">click here</a>.
          </li>
          <li>
            To access your registered phone numbers,
            <a href="https://business.facebook.com/wa/manage/phone-numbers/">click here</a>.
          </li>
        </ul>
      </VMessage>
    </div>

    <div class="column is-12">
      <VButton
        v-if="isWhatsAppSetup"
        :loading="isLoading"
        color="danger"
        class="w-100"
        @click="deregister(props?.widget?.uid)"
      >
        Deregister InsertChat AI to Whatsapp
      </VButton>

      <VButton
        v-else
        :loading="isLoading"
        color="primary"
        class="w-100"
        @click="launchWhatsAppSignup(props?.widget?.uid)"
      >
        Connect InsertChat AI to WhatsApp
      </VButton>
    </div>
  </div>
</template>

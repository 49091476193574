<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  reactive,
  Reactive,
  ref,
  Ref,
  toRefs,
} from 'vue'
import { getNode } from '@formkit/core'
import { EventHelper, HttpHelper } from '/@src/helpers'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
})

const { widgetUid, trainingType, provider } = toRefs(props)

// Const data
const formId = 'trainingTextsForm'

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<any> = ref([])
const isLoading = ref(false)
const training: Ref<any> = ref({
  label: '',
})
const loaderProgress = ref(-1)
const loaderText = ref('')

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true

    if (provider.value && provider.value?.uid) {
      training.value = {
        label: provider.value?.label,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit()
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const onInit = () => {
  newMaterial.value = ['']
}

const onInsert = (value: string) => {
  newMaterial.value.push(value)
}

const onDelete = async (item: string[], index: number) => {
  try {
    if (Object.keys(newMaterial.value)?.length === 1) {
      return
    }

    if (index > -1) {
      item.splice(index, 1)
      await nextTick()
    }
  } catch (error) {
    console.error(error)
  }
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async () => {
  if (isLoading.value) {
    return
  }

  const data = newMaterial.value?.filter((text: string) => text)

  if (!data || (data && !data.length)) {
    isLoading.value = false

    return
  }

  isLoading.value = true
  loaderProgress.value = 0

  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    if (provider.value?.uid && Object.keys(newMaterial?.value)?.length) {
      await HttpHelper.batch(
        '/providers/synch',
        provider.value?.uid,
        { training: data },
        {
          batchSize: 50,
          batchKey: '',
        },
        (numBatches: number, i: number) => {
          loaderText.value = `Processing batch ${i + 1}/${numBatches}`
          loaderProgress.value = Math.round((i * 100) / numBatches)
        }
      )

      emit('synch')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Training #1"
      validation-visibility="live"
    />

    <CustomForm title="Add Knowledge" subtitle="" class="mb-4">
      <template #body>
        <template v-for="(_, index) in newMaterial" :key="index">
          <div class="columns is-multiline is-vcentered">
            <div class="column">
              <FormKit
                v-model="newMaterial[index]"
                type="textarea"
                :label="`Text ${index + 1}`"
                validation="length:0,10000|trim"
                :help="`${newMaterial[index] ? newMaterial[index]?.length : 0} / 10000`"
                :validation-messages="{
                  length: 'Your text cannot be more than 10000 characters.',
                }"
                placeholder="Paste your text here !"
                rows="1"
                :disabled="isLoading"
              />
            </div>
            <div class="column is-narrow p-0">
              <FormKitControls
                :delete-confirmation="false"
                @delete="onDelete(newMaterial, index)"
                @insert="onInsert('')"
              />
            </div>
          </div>
        </template>

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Train
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>

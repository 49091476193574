import { DateTime, DurationUnit, Settings } from 'luxon'

Settings.defaultLocale = 'en-US'
Settings.defaultZone = 'America/New_York'

interface IToday {
  day: number
  month: number
  year: number
  daysInMonth: number
  monthLong: string
  formattedDay: string
  formattedMonth: string
}

export default class DateHelper {
  public static getTimeInParis(): string {
    return new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Paris',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  public static getGreeting(): string {
    const { hour } = DateTime.local()

    if (hour < 12) {
      return 'Good morning'
    } else if (hour >= 12 && hour < 18) {
      return 'Good afternoon'
    } else {
      return 'Good evening'
    }
  }

  public static timestampToDate(timestamp: number): DateTime {
    return DateTime.fromSeconds(timestamp).setZone('America/New_York').setLocale('en-US')
  }

  public static getNow(): DateTime {
    return DateTime.local().setZone('America/New_York').setLocale('en-US')
  }

  public static getFormattedDate(date?: DateTime): IToday {
    const { day, month, year, daysInMonth } = date
      ? date.setZone('America/New_York').setLocale('en-US')
      : DateHelper.getNow()

    const formattedDay = `${day}`.padStart(2, '0')
    const formattedMonth = `${month}`.padStart(2, '0')

    return {
      day,
      month,
      year,
      daysInMonth,
      monthLong: DateHelper.monthNumberToMonthName(month - 1),
      formattedDay,
      formattedMonth,
    }
  }

  public static convertToDateTime(value: string | DateTime) {
    return typeof value === 'string'
      ? DateTime.fromISO(value).setZone('America/New_York').setLocale('en-US')
      : value
  }

  public static formatDate(value: string | DateTime | undefined, format: string = 'MM/dd/yyyy') {
    if (!value) {
      return 'N/A'
    }

    let dateString = ''
    const _value = DateHelper.convertToDateTime(value)

    if (_value) {
      dateString = _value.setZone('America/New_York').setLocale('en-US').toFormat(format)

      if (dateString.includes('Invalid')) {
        return ''
      }
    }

    if (format.includes('hh')) {
      if (_value.hour < 12) {
        dateString += ' AM'
      } else {
        dateString += ' PM'
      }
    }

    return dateString
  }

  public static dateToDuration = (startAt: string, endAt: string) => {
    const _endAt = DateTime.fromISO(endAt)
      .setZone('America/New_York')
      .setLocale('en-US')
      .plus({ hours: 1 })
    const _startAt = DateTime.fromISO(startAt).setZone('America/New_York').setLocale('en-US')

    const { years, months, days } = _endAt.diff(_startAt, ['years', 'months', 'days']).toObject()
    const durationArray = []

    if (years) {
      const _years = Math.round(years)
      _years >= 1 && durationArray.push(_years > 1 ? `${_years} ans` : `${_years} an`)
    }

    if (months) {
      const _months = Math.round(months)
      months >= 1 && durationArray.push(`${_months} mois`)
    }

    if (days) {
      const _days = Math.round(days)
      _days >= 1 && durationArray.push(_days > 1 ? `${_days} jours` : `${_days} jour`)
    }

    return durationArray.join(', ')
  }

  public static dateDiff = (startAt: string, endAt: string, unit: DurationUnit) => {
    const _endAt = DateTime.fromISO(endAt).setZone('America/New_York').setLocale('en-US')
    const _startAt = DateTime.fromISO(startAt).setZone('America/New_York').setLocale('en-US')
    const duration = _startAt.diff(_endAt, [unit]).toObject()

    // @ts-ignore
    return Math.round(duration?.[unit])
  }

  public static durationToString = (duration: {
    years?: number
    months?: number
    days?: number
  }) => {
    const { years, months, days } = duration ?? {}
    const durationArray = []

    if (years && years >= 1) {
      durationArray.push(years > 1 ? `${years} ans` : `${years} an`)
    }

    if (months && months >= 1) {
      durationArray.push(`${months} mois`)
    }

    if (days && days >= 1) {
      durationArray.push(days > 1 ? `${days} jours` : `${days} jour`)
    }

    return durationArray.join(', ')
  }

  public static monthNumberToMonthName(index: number) {
    const months = [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ]

    return months[index]
  }
}

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { useHead } from '@vueuse/head'
import { ref } from 'vue'
import { DateHelper, StringHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'close'])

// Const data
const title = 'Team'
const columns = {
  designation: {
    label: 'Designation',
    sortable: false,
    searchable: false,
    grow: false,
  },
  email: {
    label: 'Email',
    sortable: false,
    searchable: false,
    grow: false,
  },
  created_at: {
    label: 'Creation date',
    sortable: false,
    searchable: false,
    grow: false,
  },
  last_activity_at: {
    label: 'Last activity',
    sortable: false,
    searchable: false,
    grow: false,
  },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const
const url = '/users'
const eventBus = useEventBus(url)

// Reactive data
const isLoading = ref(false)
const modalFormProps: any = ref({ open: false, refresh: false })

// Composable
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.users)
useHead({ title })
const userSession = useUserSession()

// Functions
const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  }

  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}

const refreshTable = () => {
  eventBus.emit(url)
}
</script>

<template>
  <FormMember
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
    @close="closeModal"
  />

  <CustomTable
    v-model:refresh="modalFormProps.refresh"
    v-model:override-loading="isLoading"
    title="Team"
    :columns="columns"
    :url="url"
    @row-click="openFormModal"
  >
    <template #buttons>
      <VButtons v-if="['admin', 'owner'].includes(userSession?.user?.role)" align="right">
        <VButton
          class="driverjs-add-chatbot"
          color="primary"
          icon="ic:round-plus"
          @click.capture="openFormModal"
        >
          Add member
        </VButton>
      </VButtons>
    </template>

    <template #row="{ row }">
      <VFlexTableCell :column="columns.designation">
        <VAvatar :initials="row?.initials" size="small" class="mr-2" />

        <div>
          <VTextEllipsis width="200px" class="item-name dark-text">
            {{ row?.designation }}
          </VTextEllipsis>

          <VTextEllipsis class="light-text">
            {{ StringHelper.capitalizeFirstLetter(row?.role) }}
          </VTextEllipsis>
        </div>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.email">
        <span> {{ row?.email }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.created_at">
        <span> {{ DateHelper.formatDate(row?.created_at, 'MM-dd-yyyy') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.last_activity_at">
        <span> {{ DateHelper.formatDate(row?.last_activity_at, 'MM-dd-yyyy') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton
          :icon="ICONS.tables.open"
          class="pr-3 pl-3"
          :class="['admin', 'owner', 'manager'].includes(userSession?.user?.role) && 'mr-2'"
        >
          Open
        </VButton>

        <CustomDropdown
          v-if="['admin', 'owner'].includes(userSession?.user?.role)"
          v-model:refresh="modalFormProps.refresh"
          v-model:isLoading="isLoading"
          title="Actions"
          :actions="['edit', 'delete']"
          :reload-on-delete="false"
          :uid="row?.uid"
          :url="url"
          @edit="openFormModal"
          @refresh="refreshTable"
        >
        </CustomDropdown>
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>

/**
 * messages are generated using vite-plugin-i18n
 * each .json files located in the ./src/locales are registered in messages
 * @see https://github.com/intlify/bundle-tools/tree/main/packages/vite-plugin-vue-i18n
 */
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { definePlugin } from '/@src/app'

export default definePlugin(({ app }) => {
  // const defaultLocale = useStorage('locale', navigator?.language || 'en')
  const i18n = createI18n({
    // locale: defaultLocale.value,
    locale: 'en',
    messages,
    missing(locale, key) {
      console.error('Missing key', { locale, key })

      return ''
    },
  })

  app.use(i18n)
})

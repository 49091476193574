<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onBeforeMount, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import { ICONS } from '/@src/resources/files/constant'
import { useWidget } from '/@src/stores'

const emit = defineEmits(['update:modalShareProps', 'close'])

// Reactive data
const container = ref<HTMLElement | null>(null)
const isLoading = ref(true)
const logoSettings: any = ref({})
const copyIcon = ref(ICONS.actions.copy)

// Composable
const widgetStore = useWidget()
const { toClipboard } = useClipboard()

// Computed
const shareUrl = widgetStore?.getShareUrl()

// Lifecycle hooks
onBeforeMount(() => {
  logoSettings.value = widgetStore.getLogoSettings()

  useHead({
    meta: [
      { property: 'og:url', content: shareUrl },
      { property: 'og:image', content: logoSettings.value?.url || '' },
    ],
  })

  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.src =
    'https://platform-api.sharethis.com/js/sharethis.js#property=66ac7768e1507500197b04d7&product=inline-share-buttons&source=platform'
  script.async = true

  document.body.appendChild(script)
})

// Functions
const closeModal = (refresh = false) => {
  emit('update:modalShareProps', { open: false })

  if (!refresh) {
    emit('close')
  }
}

const copy = async (text: string) => {
  try {
    await toClipboard(text)

    copyIcon.value = ICONS.actions.check

    setTimeout(() => {
      copyIcon.value = ICONS.actions.copy
    }, 1250)
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <CustomModal
    teleport-to="#teleport-modals"
    size="small"
    :disable-header="false"
    :disable-footer="true"
    :open="true"
    :override-modal="false"
    :noscroll="true"
    :noclose="true"
    :narrow="true"
    @close="closeModal"
    :icon="ICONS.actions.share"
  >
    <div class="columns is-vcentered">
      <div class="column">
        <p>{{ shareUrl }}</p>
      </div>

      <div class="column is-narrow">
        <VIcon
          :is-clickable="true"
          :icon="copyIcon"
          class="chat-icons-color"
          @click="copy(shareUrl)"
          @click.prevent="() => {}"
        />
      </div>
    </div>

    <div class="separator-horizontal mb-5" />

    <div ref="container" class="sharethis-inline-share-buttons">
      <VLoader v-if="isLoading" size="small" />
    </div>
  </CustomModal>
</template>

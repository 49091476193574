import { useStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import posthog from 'posthog-js'
import { computed, ref, Ref } from 'vue'
import { googleLogout } from 'vue3-google-login'
import { CookiesHelper, EventHelper, PageHelper } from '/@src/helpers'
import { IPlan, IUsage } from '/@src/interfaces'

export type UserData = Record<string, any> | null
export type UserApp = Record<string, any> | null
export type UserSubscription = Record<string, any> | null

export const useUserSession = defineStore('userSession', () => {
  const token = useStorage('token', CookiesHelper.get('token') || '')
  const analytics = ref(false)
  const user = ref<Partial<UserData>>()
  const logo = ref('')
  const favicon = ref('')
  const pageTitle = ref('')
  const app = ref<Partial<UserApp>>()
  const subscription = ref<Partial<UserSubscription>>()
  const addons = ref<Partial<UserSubscription>>()
  const plan = ref<Partial<IPlan>>()
  const usage = ref<Partial<IUsage>>()
  const loading = ref(true)
  const isAdmin = ref(false)
  const isLoggedIn = computed(() => token.value !== undefined && token.value !== '')
  // const router = useRouter()
  const intervals: Ref<Record<string, number[]>> = ref({})
  const isModeBusiness = computed(() => app?.value?.mode === 'business')
  const isModeCharacters = computed(() => app?.value?.mode === 'characters')

  // Setters
  function setIntervals(key: string, intervalId: number) {
    if (!intervals.value[key]) {
      intervals.value[key] = [intervalId]
    } else {
      intervals.value[key].push(intervalId)
    }
  }

  function clearAllIntervals(key?: string): void {
    if (key) {
      const keyIntervals = intervals.value[key]

      if (keyIntervals) {
        for (const id of keyIntervals) {
          if (id) {
            clearInterval(id)
          }
        }

        intervals.value[key] = []
      }
    } else {
      for (const key in intervals.value) {
        for (const id of intervals.value[key]) {
          if (id) {
            clearInterval(id)
          }
        }

        intervals.value[key] = []
      }
    }
  }

  function setLogo(newLogo: string) {
    logo.value = newLogo
  }

  function setFavicon(newFavicon: string) {
    favicon.value = newFavicon

    PageHelper.changeFavicon(newFavicon)
  }

  function setAnalytics(status: boolean) {
    analytics.value = status
  }

  function setToken(newToken: string) {
    token.value = newToken
  }

  function setUser(newUser: Partial<UserData>) {
    user.value = newUser

    // Cleanup
    delete newUser?.meta
  }

  function setApp(newApp: Partial<UserApp>) {
    app.value = newApp
  }

  function setSubscription(newSubscription: Partial<UserSubscription>) {
    subscription.value = newSubscription
  }

  function setAddons(newAddons: Partial<UserSubscription>) {
    addons.value = newAddons
  }

  function setPlan(newPlan: Partial<IPlan>) {
    plan.value = newPlan
  }

  function setUsage(newUsage: Partial<IUsage>) {
    usage.value = newUsage
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  function setIsAdmin(value: boolean) {
    isAdmin.value = value
  }

  async function logoutUser() {
    setToken('')
    setAnalytics(false)
    posthog.reset()
    window.dataLayer = []

    user.value = undefined
    app.value = undefined
    subscription.value = undefined
    plan.value = undefined
    usage.value = undefined
    isAdmin.value = false

    googleLogout()

    try {
      EventHelper.sessionReset()
    } catch (error) {
      console.error(error)
    }

    window.location.href = '/auth/access'

    return
  }

  // Getters
  function getPlanLimitations(limitationKey: keyof IPlan['limitations']) {
    if (plan.value && plan.value.limitations && limitationKey in plan.value.limitations) {
      return plan.value.limitations[limitationKey]
    }

    console.error(`Undefined limitation key: ${limitationKey}`)

    return undefined
  }

  function isEmployee() {
    return ['admin', 'owner', 'manager'].includes(user.value?.role)
  }

  function isOwner() {
    return ['admin', 'owner'].includes(user.value?.role)
  }

  function isManager() {
    return ['admin', 'manager'].includes(user.value?.role)
  }

  function isClient() {
    return ['client'].includes(user.value?.role)
  }

  return {
    isModeBusiness,
    isModeCharacters,
    isAdmin,
    token,
    analytics,
    user,
    app,
    subscription,
    addons,
    loading,
    isLoggedIn,
    usage,
    plan,
    logo,
    favicon,
    pageTitle,
    isEmployee,
    isOwner,
    isManager,
    isClient,
    setAnalytics,
    setIsAdmin,
    setIntervals,
    clearAllIntervals,
    setLogo,
    setFavicon,
    setToken,
    setUser,
    setApp,
    setSubscription,
    setAddons,
    setPlan,
    setUsage,
    setLoading,
    logoutUser,
    getPlanLimitations,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}

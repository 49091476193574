<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onBeforeMount, ref } from 'vue'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Composable
const viewWrapper = useViewWrapper()

// Reactive data
const isLoading = ref(false)

// Const data
const title = 'Full Pricing Table'
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })

// Lifecycle hooks
onBeforeMount(() => {
  try {
    window.location.href = 'https://insertchat.com/pricing/'
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})
</script>

<template>
  <VLoader v-if="true" class="m-auto" :translucent="false" :opaque="false" text="Redirecting..." />
</template>

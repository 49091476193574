<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { FileHelper, HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'

const emit = defineEmits<{
  (e: 'update:refresh', value: boolean): void
  (e: 'update:isLoading', value: boolean): void
  (e: 'edit', value: object): void
  (e: 'refresh'): void
}>()

const props = defineProps({
  uid: {
    type: String,
    default: undefined,
    required: true,
  },
  url: {
    type: String,
    default: undefined,
    required: true,
  },
  title: {
    type: String,
    default: undefined,
    required: true,
  },
  reloadOnDelete: {
    type: Boolean,
    default: false,
    required: false,
  },
  document: {
    type: Object,
    default: () => {},
    required: false,
  },
  emails: {
    type: Array<string>,
    default: () => [],
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
    required: false,
  },
  actions: {
    type: Array,
    default: () => [],
    required: true,
  },
})

// Reactive data
const modalEmailProps = ref()

// Composable
const { t } = useI18n()
const router = useRouter()

// Functions
const onDuplicate = async () => {
  try {
    emit('update:isLoading', true)

    await HttpHelper.get(`${props.url}/duplicate/${props.uid}`)

    emit('refresh')
  } catch (error) {
    console.error(error)
  } finally {
    emit('update:isLoading', false)
  }
}

const onDelete = async () => {
  const result = confirm('Are you sure you want to delete this item?')

  if (result) {
    try {
      emit('update:isLoading', true)

      await HttpHelper.destroy(props.url, props.uid)

      emit('refresh')

      if (props.reloadOnDelete) {
        router.go(0)
      }
    } catch (error) {
      console.error(error)
    } finally {
      emit('update:isLoading', false)
    }
  }
}

/*
  const onArchive = async () => {
  try {
    emit('update:isLoading', true)

    await HttpHelper.get(`${props.url}/archive/${props.uid}`)

    emit('update:refresh', true)
  } catch (error) {
    console.error(error)
  } finally {
    emit('update:isLoading', false)
  }
}
*/

const onDownload = async () => {
  if (props.document) {
    const { label, extname, meta } = props.document
    const { signed_url } = meta

    FileHelper.download(signed_url, label, extname)
  }
}

const openEmailFormModal = () => {
  modalEmailProps.value = {
    open: true,
    propertyUid: props.document?.property_uid,
    documents: [props.document],
    subject: props.document?.label,
    to: props.emails,
    cc: props.emails,
    cci: null,
  }
}
</script>

<template>
  <VDropdown
    :title="props.title"
    icon="ic:round-more-vert"
    class="is-pushed-mobile"
    up
    right
    spaced
    modern
    @click.stop="() => {}"
  >
    <template #content="{ close }">
      <VLoader v-if="props.isLoading" class="h-100" />

      <div v-else>
        <VButton
          v-if="actions.includes('edit')"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.update"
          @click.prevent="
            () => {
              emit('edit', { uid: props.uid })
              close()
            }
          "
        >
          {{ t('actions.edit') }}
        </VButton>

        <VButton
          v-if="actions.includes('duplicate')"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.duplicate"
          @click.prevent="
            () => {
              onDuplicate()
              close()
            }
          "
        >
          {{ t('actions.duplicate') }}
        </VButton>

        <VButton
          v-if="actions.includes('delete')"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.delete"
          @click.prevent="
            () => {
              onDelete()
              close()
            }
          "
        >
          {{ t('actions.delete') }}
        </VButton>

        <!--
				<VButton
					v-if="actions.includes('archive')"


					class="dropdown-item no-border"
					:disabled="props.isLoading"
          :icon="ICONS.actions.archive"
					@click.prevent="
						() => {
							onArchive()
							close()
						}
					"
				>
          {{ t('actions.archive') }}
				 </VButton>
      -->

        <!-- With document separator -->
        <hr v-if="props.document" class="dropdown-divider" />

        <VButton
          v-if="actions.includes('download') && props.document"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.download"
          @click.prevent="
            () => {
              onDownload()
              close()
            }
          "
        >
          {{ t('actions.download') }}
        </VButton>

        <VButton
          v-if="actions.includes('email')"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.email"
          @click.prevent="
            () => {
              openEmailFormModal()
              close()
            }
          "
        >
          {{ t('actions.email') }}
        </VButton>

        <VButton
          v-if="actions.includes('contact')"
          class="dropdown-item no-border"
          :disabled="props.isLoading"
          :icon="ICONS.actions.email"
          @click.prevent="
            () => {
              openEmailFormModal()
              close()
            }
          "
        >
          {{ t('actions.contact') }}
        </VButton>

        <slot> </slot>
      </div>
    </template>
  </VDropdown>
</template>

<style lang="scss">
.dropdown-item {
  border: none;
}

.dropdown-item:hover {
  border-radius: 7px;
}
</style>

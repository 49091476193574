<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useApp, useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const formId = 'formWhiteLabel'
const title = 'White-Label'

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
const app = useApp()
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.whitelabel)
useHead({ title })

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const logoSignedUrl = ref('')
const faviconSignedUrl = ref('')
const addons: any = ref({})
const domainLimitation = ref()
const isTesting = ref(false)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    await init()
  } catch (error) {
    console.error(error)
  }
})

// Functions
const init = async () => {
  try {
    isLoading.value = true

    domainLimitation.value = userSession.getPlanLimitations('domain')

    const [plansResponse, settingsResponse] = await Promise.all([
      app.loadPlans('all'),
      HttpHelper.get(`/apps/settings`),
    ])

    addons.value = plansResponse?.addons
    logoSignedUrl.value = settingsResponse?.logo_signed_url
    faviconSignedUrl.value = settingsResponse?.favicon_signed_url
    formData.value.page_title = settingsResponse?.page_title
    formData.value.company_name = settingsResponse?.company_name
    formData.value.sender_name = settingsResponse?.sender_name
    formData.value.sender_email = settingsResponse?.sender_email
    formData.value.sender_signature = settingsResponse?.sender_signature
    formData.value.smtp_host = settingsResponse?.smtp_host
    formData.value.smtp_port = settingsResponse?.smtp_port
    formData.value.smtp_username = settingsResponse?.smtp_username
    formData.value.smtp_password = settingsResponse?.smtp_password

    if (settingsResponse?.logo_signed_url) {
      userSession.setLogo(settingsResponse?.logo_signed_url)
    }

    if (settingsResponse?.favicon_signed_url) {
      userSession.setFavicon(settingsResponse?.favicon_signed_url)
    }

    if (settingsResponse?.page_title) {
      useHead({
        title: settingsResponse?.page_title,
      })
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const testSMTP = async () => {
  isTesting.value = true

  try {
    await HttpHelper.post(
      '/tools/test-smtp',
      null,
      {
        smtp_host: formData.value.smtp_host,
        smtp_port: formData.value.smtp_port,
        smtp_username: formData.value.smtp_username,
        smtp_password: formData.value.smtp_password,
      },
      { withoutApp: true }
    )

    notyf.success('SMTP connection successful!')

    return true
  } catch (error) {
    return false
  } finally {
    isTesting.value = false
  }
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    EventHelper.push('changed_account_logo')

    isLoading.value = true

    const {
      logo,
      favicon,
      company_name,
      page_title,
      sender_name,
      sender_email,
      sender_signature,
      smtp_host,
      smtp_port,
      smtp_username,
      smtp_password,
      custom_domains,
    } = fields

    // If user uploaded a logo or favicon
    if (
      (logo && logo?.length && logo[0]?.file) ||
      (favicon && favicon?.length && favicon[0]?.file)
    ) {
      const data = new FormData()

      data.append('logo', logo[0]?.file)
      data.append('favicon', favicon[0]?.file)
      data.append('page_title', page_title)
      data.append('company_name', company_name)
      data.append('sender_name', sender_name)
      data.append('sender_email', sender_email)
      data.append('sender_signature', sender_signature)
      data.append('smtp_host', smtp_host)
      data.append('smtp_port', smtp_port)
      data.append('smtp_username', smtp_username)
      data.append('smtp_password', smtp_password)
      data.append('custom_domains', custom_domains)

      await HttpHelper.post('/apps/settings', null, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      await HttpHelper.post('/apps/settings', null, {
        company_name,
        page_title,
        sender_name,
        sender_email,
        sender_signature,
        smtp_host,
        smtp_port,
        smtp_username,
        smtp_password,
        custom_domains,
      })
    }

    await init()

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <VCard class="h-100">
    <h1 class="title is-5">{{ title }}</h1>

    <CustomForm title="Configuration" subtitle="">
      <template #buttons>
        <VButton
          :disabled="isFormDisabled || !domainLimitation"
          :loading="isLoading"
          type="submit"
          color="primary"
          icon="ic:round-check"
          @click="triggerSubmit"
        >
          Save Changes
        </VButton>
      </template>

      <template #body>
        <FormKit
          :id="formId"
          v-model="formData"
          type="form"
          :config="{ validationVisibility: 'live' }"
          :disabled="isLoading || !domainLimitation"
          :actions="false"
          @submit="onSubmit"
        >
          <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

          <MarketingMessage v-if="!domainLimitation" :plan="addons.branding" cta="Purchase Now">
            Purchase the “White-Label” addon to unlock this feature!
          </MarketingMessage>

          <!-- App -->
          <CustomForm title="App">
            <template #body>
              <div class="columns is-multiline">
                <!-- Company name -->
                <div class="column is-6">
                  <FormKit
                    type="text"
                    label="Company"
                    name="company_name"
                    validation="trim"
                    placeholder="Company name"
                    :disable="isLoading || !domainLimitation"
                  />
                </div>

                <!-- Page title -->
                <div class="column is-6">
                  <FormKit
                    type="text"
                    label="Page title"
                    name="page_title"
                    validation="trim"
                    placeholder="Page title"
                    :disable="isLoading || !domainLimitation"
                  />
                </div>
              </div>
            </template>
          </CustomForm>

          <!-- Images -->
          <CustomForm title="Images">
            <template #body>
              <div class="columns">
                <!-- Logo -->
                <div class="column is-6">
                  <FormKit
                    type="file"
                    name="logo"
                    label="Logo"
                    accept=".jpg,.jpeg,.png,.gif"
                    multiple="false"
                    :disable="isLoading || !domainLimitation"
                  />

                  <CustomEmbed
                    v-if="logoSignedUrl && logoSignedUrl !== ''"
                    :url="logoSignedUrl"
                    :is-image="true"
                    :hide-actions="true"
                    content-height="200px"
                  />
                </div>
                <!-- Favicon -->
                <div class="column is-6">
                  <FormKit
                    type="file"
                    name="favicon"
                    label="Favicon"
                    accept=".jpg,.jpeg,.png,.gif,.ico"
                    multiple="false"
                    :disable="isLoading || !domainLimitation"
                  />

                  <CustomEmbed
                    v-if="faviconSignedUrl && faviconSignedUrl !== ''"
                    :url="faviconSignedUrl"
                    :is-image="true"
                    :hide-actions="true"
                    content-height="200px"
                  />
                </div>
              </div>
            </template>
          </CustomForm>

          <!-- Custom Domain -->
          <CustomForm title="Custom Domain" subtitle="">
            <template #body>
              <div class="columns is-multiline">
                <div class="column is-12">
                  {{ formData.custom_domains }}
                  <FormKit
                    v-slot="{ index }"
                    type="repeater"
                    name="custom_domains"
                    :up-control="false"
                    :down-control="false"
                    :add-button="false"
                    :insert-control="false"
                    :disabled="isLoading"
                    min="1"
                    max="1"
                  >
                    <FormKit
                      name="domain"
                      type="text"
                      label="Domain"
                      validation="trim"
                      :placeholder="`domain.com OR sub.domain.com`"
                      :disabled="isLoading"
                    />
                  </FormKit>
                </div>
              </div>
            </template>
          </CustomForm>

          <!-- Emailing -->
          <CustomForm title="Sender Configuration" subtitle="">
            <template #body>
              <FormKit
                type="text"
                label="Sender name"
                name="sender_name"
                validation="trim"
                :disabled="isLoading || !domainLimitation"
                placeholder="InsertChat"
              />

              <FormKit
                type="email"
                label="Sender e-mail"
                name="sender_email"
                validation="trim|email"
                :disabled="isLoading || !domainLimitation"
                placeholder="admin@insertchat.com"
              />

              <FormKit
                type="textarea"
                label="Sender signature"
                name="sender_signature"
                validation="trim"
                :disabled="isLoading || !domainLimitation"
                placeholder="Sent by InsertChat"
              />
            </template>
          </CustomForm>

          <!-- SMTP -->
          <CustomForm title="SMTP Configuration" subtitle="">
            <template #body>
              <FormKit
                type="text"
                label="SMTP Host"
                name="smtp_host"
                validation="trim"
                placeholder="Enter your SMTP host"
                :disabled="isLoading || !domainLimitation"
              />
              <FormKit
                type="number"
                number="integer"
                label="SMTP Port"
                name="smtp_port"
                validation="trim|number"
                placeholder="587"
                :disabled="isLoading || !domainLimitation"
              />
              <FormKit
                type="text"
                label="SMTP Username"
                name="smtp_username"
                validation="trim"
                placeholder="Enter your SMTP username"
                :disabled="isLoading || !domainLimitation"
              />
              <FormKit
                type="password"
                label="SMTP Password"
                name="smtp_password"
                validation="trim"
                placeholder="Enter your SMTP password"
                :disabled="isLoading || !domainLimitation"
              />
              <VButton
                :disabled="isLoading || !domainLimitation"
                :loading="isTesting"
                type="submit"
                color="info"
                icon="ic:round-check"
                @click.prevent="testSMTP"
              >
                Test SMTP Before Saving
              </VButton>
            </template>
          </CustomForm>
        </FormKit>
      </template>
    </CustomForm>
  </VCard>
</template>

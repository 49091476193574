<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { useHead } from '@vueuse/head'
import { decodeHTML } from 'entities'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { DateHelper, HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const title = 'Chatbots'
const url = '/widgets'
const eventBus = useEventBus(url)
const expand = ['model']
const columns = {
  label: {
    label: 'Label',
    sortable: false,
    searchable: false,
    grow: false,
  },
  context_behavior: { label: 'Boundary', sortable: false, searchable: false, grow: false },
  prompt_system: { label: 'AI Persona', sortable: false, searchable: false, grow: false },
  updated_at: {
    label: 'Last update date',
    sortable: false,
    searchable: false,
    grow: true,
  },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const

// Reactive data
const modalFormProps: any = ref({ open: false, refresh: false })
const modalMarketingProps: any = ref({
  open: false,
  title: 'Upgrade for increased chatbot capacity',
  translationKey: 'errors.subscription/widgets-limit-reached',
})
const isLoading = ref(false)

// Composable
const router = useRouter()
const { t } = useI18n()
const viewWrapper = useViewWrapper()
const userSession = useUserSession()
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title })

// Lifecycle hooks
onMounted(async () => {
  try {
    if (userSession?.usage?.widgets?.consumed === 0) {
      modalFormProps.value.open = true

      return
    }
  } catch (error) {
    console.error(error)
  }
})

// Functions
const onRowClick = async (item: any) => {
  const { uid } = item

  await router.push({
    path: `/chatbots/${uid}`,
  })
}

const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  } else {
    // Open marketing modal if no chatbot is available
    if (!userSession?.usage?.widgets?.leftover || userSession?.usage?.widgets?.leftover <= 0) {
      modalMarketingProps.value.open = true
      return
    }
  }

  modalFormProps.value.redirect = true
  modalFormProps.value.refresh = false
  modalFormProps.value.open = true
}

const onDuplicate = async (uid: string, action: string) => {
  if (action === 'with-training') {
    try {
      isLoading.value = true

      const { credits } = await HttpHelper.get(`widgets/estimate-retraining-cost/${uid}`)

      const result = confirm(
        `Are you certain about duplicating this chatbot with knowledge? This process involves retraining your chatbot. Retraining the new chatbot will take a few minutes and will cost you ${credits} credits!`
      )

      if (!result) {
        isLoading.value = false

        return
      }
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }

  try {
    if (!isLoading.value) {
      isLoading.value = true

      await HttpHelper.get(`widgets/duplicate-${action}/${uid}`)

      // Refresh table
      modalFormProps.value.refresh = true
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const refreshTable = () => {
  eventBus.emit(url)
}
</script>

<template>
  <MarketingModal v-model="modalMarketingProps" />

  <FormChat
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
  />

  <CustomTable
    v-model:refresh="modalFormProps.refresh"
    v-model:override-loading="isLoading"
    class="driverjs-chatbots-table"
    :columns="columns"
    :url="url"
    :expand="expand"
    :enable-wrapper-class="true"
    @row-click="onRowClick"
    @empty-table="openFormModal"
  >
    <template v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)" #buttons>
      <VButtons align="right">
        <VButton
          class="driverjs-add-chatbot"
          color="primary"
          icon="ic:round-plus"
          @click.capture="openFormModal"
        >
          Add chatbot
        </VButton>
      </VButtons>
    </template>

    <template #message> </template>

    <template #row="{ row }">
      <VFlexTableCell :column="columns.label">
        <div>
          <VTextEllipsis width="200px" class="item-name dark-text">
            {{ decodeHTML(row?.label ?? '') }}
          </VTextEllipsis>

          <VTextEllipsis class="light-text">
            {{ row?.model?.label }}
          </VTextEllipsis>
        </div>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.context_behavior">
        {{
          row?.context_behavior ? t('forms.chatbot.context_behavior.' + row?.context_behavior) : ''
        }}
      </VFlexTableCell>

      <VFlexTableCell :column="columns.prompt_system">
        <VTextEllipsis width="200px"> {{ decodeHTML(row?.prompt_system ?? '') }} </VTextEllipsis>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.updated_at">
        {{ DateHelper.formatDate(row?.updated_at, 'MM-dd-yyyy') }}
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton
          :icon="ICONS.tables.open"
          class="pr-3 pl-3 driverjs-button-open"
          :class="['admin', 'owner', 'manager'].includes(userSession?.user?.role) && 'mr-2'"
        >
          Open
        </VButton>

        <CustomDropdown
          v-if="['admin', 'owner', 'manager'].includes(userSession?.user?.role)"
          v-model:refresh="modalFormProps.refresh"
          v-model:isLoading="isLoading"
          title="Actions"
          :actions="['edit', 'delete']"
          :reload-on-delete="true"
          :uid="row?.uid"
          :url="url"
          class="driverjs-button-actions"
          @edit="onRowClick"
          @refresh="refreshTable"
        >
          <VButton
            class="dropdown-item no-border"
            :disabled="isLoading"
            :icon="ICONS.actions.duplicate"
            @click.prevent="onDuplicate(row?.uid, 'without-training')"
          >
            Duplicate
          </VButton>

          <VButton
            class="dropdown-item no-border"
            :disabled="isLoading"
            :icon="ICONS.actions.duplicate"
            @click.prevent="onDuplicate(row?.uid, 'with-training')"
          >
            Duplicate with knowledge
          </VButton>
        </CustomDropdown>
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>

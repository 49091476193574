<script setup lang="ts">
export type VLoaderSize = 'small' | 'large' | 'xl'
export type VLoaderWrapperRadius = 'regular' | 'smooth' | 'rounded'
export interface VLoaderProps {
  size?: VLoaderSize
  card?: VLoaderWrapperRadius
  active?: boolean
  grey?: boolean
  translucent?: boolean
  opaque?: boolean
  text?: string
  progress?: number
  color?: string
}

// Props
const props = withDefaults(defineProps<VLoaderProps>(), {
  size: 'large',
  card: undefined,
  active: true,
  text: '',
  progress: -1,
})
</script>

<template>
  <div
    class="is-flex has-loader"
    :class="[props.active && 'has-loader-active', props.translucent && 'is-translucent']"
    v-bind="$attrs"
  >
    <div
      v-if="props.active"
      class="v-loader-wrapper is-active has-text-centered"
      :class="[
        grey && 'is-grey',
        translucent && 'is-translucent',
        opaque && 'is-opaque',
        card === 'regular' && 's-card is-raised',
        card === 'smooth' && 'r-card is-raised',
        card === 'rounded' && 'l-card is-raised',
      ]"
    >
      <div class="v-loader-bg">
        <div class="w-100">
          <div class="mx-auto loader is-loading" :class="[props.size && `is-${props.size}`]"></div>

          <p v-if="props.text" class="mt-5 rem-130 has-dark-text">{{ props.text }}</p>

          <div v-if="props.progress >= 0 && props.progress < 100" class="mt-5">
            <progress class="progress-bar" :value="props.progress" max="100"></progress>
            <span class="progress-bar-text">{{ props.progress }}%</span>
          </div>
        </div>
      </div>

      <slot> </slot>
    </div>
  </div>
</template>

<style lang="scss">
$grey-lighter: hsl(0deg 0% 86%) !default;
$radius-rounded: 290486px !default;

.is-translucent {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  width: 300px;
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.progress-bar::-webkit-progress-value {
  background-color: var(--primary);
  border-radius: 10px;
}

.progress-bar::-moz-progress-bar {
  background-color: var(--primary);
  border-radius: 10px;
}

.progress-bar-text {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: var(--primary);
}

.has-loader {
  // position: relative;

  &.has-loader-active {
    // overflow: hidden;
  }

  .v-loader-wrapper {
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    // background: var(--white);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    z-index: 5;

    &.is-active {
      opacity: 1;
      pointer-events: all;

      &.is-opaque {
        opacity: 0.5;
        background: var(--white);
      }
    }

    &.is-grey {
      background: var(--background-grey);
    }

    .loader {
      height: 3rem;
      width: 3rem;
      animation: spinAroundLoader 500ms infinite linear;
      border: 2px solid $grey-lighter;
      border-radius: $radius-rounded;
      border-right-color: transparent;
      border-top-color: transparent;
      border-left-color: $grey-lighter;
      border-bottom-color: $grey-lighter;

      &.is-small {
        height: 2rem;
        width: 2rem;
      }

      &.is-large {
        height: 5rem;
        width: 5rem;
      }

      &.is-xl {
        height: 7rem;
        width: 7rem;
      }
    }

    .v-loader-bg {
      opacity: 1 !important;
      // border: 1px solid black;
      // background: rgba(255, 255, 255, 1);
      padding: 20px;
      border-radius: 24px;
    }
  }
}

.is-dark {
  .has-loader {
    .v-loader-wrapper {
      // background: var(--dark-sidebar-light-6);

      &.is-grey {
        background: var(--dark-sidebar-light-10);
      }
    }
  }
}

@keyframes spinAroundLoader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@mixin loader {
  animation: spinAroundLoader 500ms infinite linear;
  border: 2px solid $grey-lighter;
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  border-left-color: $grey-lighter;
  border-bottom-color: $grey-lighter;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

%loader {
  @include loader;
}
</style>

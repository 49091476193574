<script setup lang="ts">
import { RegexHelper } from '/@src/helpers'

export interface VTextEllipsisProps {
  width?: string
  mobileWidth?: string
  fontSize?: string
}

const props = withDefaults(defineProps<VTextEllipsisProps>(), {
  width: '200px',
  mobileWidth: '200px',
  fontSize: '0.875em',
})
const mobileWidthValue = props.mobileWidth ?? props.width

if (props.width.match(RegexHelper.cssUnitRe) === null) {
  console.warn(`VTextEllipsis: invalid "${props.width}" width. Should be a valid css unit value.`)
}
if (mobileWidthValue.match(RegexHelper.cssUnitRe) === null) {
  console.warn(
    `VTextEllipsis: invalid "${mobileWidthValue}" mobileWidth. Should be a valid css unit value.`
  )
}
</script>

<template>
  <small class="text-ellipsis">
    <slot> </slot>
  </small>
</template>

<style lang="scss" scoped>
.text-ellipsis {
  /*
	display: block;
	max-width: v-bind('props.width');
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  */

  // display: block;
  display: -webkit-box;
  font-size: v-bind('props.fontSize');
  max-width: v-bind('props.width');
  // line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .text-ellipsis {
    max-width: v-bind('mobileWidthValue');
    text-align: right;
  }
}
</style>

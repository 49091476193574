import { Notyf, INotyfNotificationOptions, NotyfNotification } from 'notyf'
import { createSharedComposable } from '@vueuse/core'

export const useNotyf = createSharedComposable((config?: object) => {
  const notyf = new Notyf({
    duration: 8000,
    position: {
      x: 'center',
      y: 'bottom',
    },
    types: [
      {
        type: 'warning',
        background: '#faad42',
        icon: {
          className: 'fas fa-hand-paper',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'info',
        background: '#0398e2',
        icon: {
          className: 'ic:round-info',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'primary',
        background: '#0e111b',
        icon: {
          className: 'fas fa-car-crash',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'accent',
        background: '#797bf2',
        icon: {
          className: 'fas fa-car-crash',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'purple',
        background: '#8168b1',
        icon: {
          className: 'ic:round-check',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'blue',
        background: '#38c3ff',
        icon: {
          className: 'ic:round-check',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'green',
        background: '#94e189',
        icon: {
          className: 'ic:round-check',
          tagName: 'i',
          text: '',
        },
      },
      {
        type: 'orange',
        background: '#ffa880',
        icon: {
          className: 'ic:round-check',
          tagName: 'i',
          text: '',
        },
      },
    ],
    ...config,
  })

  const notifications: NotyfNotification[] = []

  const dismissAllNotifications = () => {
    notifications.forEach(notification => notyf.dismiss(notification))
    notifications.length = 0
  }

  return {
    dismiss: (notification: NotyfNotification) => {
      notyf.dismiss(notification)
      const index = notifications.findIndex(n => n === notification)
      if (index !== -1) {
        notifications.splice(index, 1)
      }
    },
    dismissAll: dismissAllNotifications,
    success: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const notification = notyf.success(payload)
      notifications.push(notification)
      return notification
    },
    error: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const notification = notyf.error(payload)
      notifications.push(notification)
      return notification
    },
    info: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'info',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    warning: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'warning',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    primary: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'primary',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    purple: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'purple',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    blue: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'blue',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    green: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'green',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
    orange: (payload: string | Partial<INotyfNotificationOptions>) => {
      dismissAllNotifications()
      const options: Partial<INotyfNotificationOptions> = {
        type: 'orange',
      }

      if (typeof payload === 'string') {
        options.message = payload
      } else {
        Object.assign(options, payload)
      }

      const notification = notyf.open(options)
      notifications.push(notification)
      return notification
    },
  }
})

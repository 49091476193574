<script setup lang="ts">
import { ref, watch } from 'vue'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Reactive data
const isLoading = ref(false)
const password = ref('')
const status = ref('')

// Watch
watch(password, async () => {
  if (password.value) {
    try {
      status.value = ''
      isLoading.value = true

      await widgetStore.unlockWidget(password.value)

      status.value = 'success'
    } catch (error) {
      console.error(error)

      status.value = 'failure'
      password.value = ''
    } finally {
      isLoading.value = false
    }
  }
})
</script>

<template>
  <InputPinCode v-model="password" style="width: 300px" :status="status" />
</template>

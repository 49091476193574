<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Composable
const { t } = useI18n()
const userSession = useUserSession()
const viewWrapper = useViewWrapper()

// Const data
const title = t('page.subscription.cancel.title')
const appName = import.meta.env.VITE_APP_NAME
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.subscriptions)
useHead({ title })
</script>

<template>
  <div class="confirm-account-wrapper">
    <div class="wrapper-inner">
      <div class="action-box">
        <div class="box-content">
          <img
            class="light-image"
            src="https://cdn.insertchat.com/illustrations/placeholders/error-5.svg"
            alt=""
          />
          <img
            class="dark-image"
            src="https://cdn.insertchat.com/illustrations/placeholders/error-5.svg"
            alt=""
          />
          <h3 class="dark-inverted">{{ t('page.subscription.cancel.h3') }}</h3>
          <hr />
          Hey
          <strong> {{ userSession?.user?.first_name }} </strong>, <br />
          <br />
          {{ t('page.subscription.cancel.message') }}<br />
          <br />
          <strong> {{ appName }} </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '/@src/scss/abstracts/all';

.confirm-account-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  min-height: 560px;
  margin: 0 auto;

  .wrapper-inner {
    .action-box {
      @include vuero-s-card;

      padding: 40px;

      .box-content {
        font-family: var(--font);
        text-align: center;

        img {
          display: block;
          width: 100%;
          max-width: 220px;
          margin: 0 auto 8px;

          &.is-larger {
            max-width: 300px;
          }
        }

        h3 {
          font-size: 1.1rem;
          font-family: var(--font-alt);
          font-weight: 600;
          max-width: 320px;
          margin: 0 auto 8px;

          span {
            color: var(--primary);
          }
        }

        .buttons {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          padding-top: 30px;

          .button {
            margin: 0 4px;
            min-width: 180px;
          }
        }
      }
    }
  }
}

.is-dark {
  .confirm-account-wrapper {
    .wrapper-inner {
      .action-box {
        @include vuero-card--dark;
      }
    }

    .wrapper-outer {
      @include vuero-card--dark;
    }
  }
}

@media only screen and (max-width: 767px) {
  .confirm-account-wrapper {
    .wrapper-inner {
      .action-box {
        padding: 20px;

        .box-content {
          .buttons {
            .button {
              min-width: 130px;
            }
          }
        }
      }
    }
  }
}
</style>
